import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";

const AdminExpiringSubscriptions = () => {
  const dispatch = useDispatch();
  const coachSales = useSelector((state) => state.adminReducer.adminExpiringSubscriptions);
  const coachSalesLoaded = useSelector((state) => state.adminReducer.adminExpiringSubscriptionsLoaded);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Expiring Subscriptions" } })
    );
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Salesperson":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        coaches: coaches_arr,
        page_name: "Expiring Subscriptions"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminExpiringSubscriptions({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminExpiringSubscriptions({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminExpiringSubscriptions({ filters: filters }));
  }, [applyClicked]);

  const exportData = Array.isArray(coachSales) ? coachSales.map((v) => v.breakdown).flat() : [];

  return (
    <AccordionContainer
      values={coachSales}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="EXPIRING PAID IN FULLS"
      type="EXPIRING SUBSCRIPTIONS"
      headers={["client_name", "expiration", "assigned_coach_name"]}
      row_id={"sale_id"}
      pathName={"sale_detail"}
      admin={true}
      handleClick={handleClick}
      exportButton={true}
      loaded={coachSalesLoaded}
      exportData={exportData}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Salesperson"
        handleChange={handleChange}
      />
    </AccordionContainer>
  );
};

export default AdminExpiringSubscriptions;
