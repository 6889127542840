import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const SalesHoursCommissionDetailForm = ({ salesHoursCommissionDetail, clicked, setClicked, refresh }) => {
    const [coachName, setCoachName] = useState(
      {
        value: salesHoursCommissionDetail.full_name + " (" + salesHoursCommissionDetail.coach_id + ")",
        label: salesHoursCommissionDetail.full_name + " (" + salesHoursCommissionDetail.coach_id + ")"
      }
    );
    const [rate, setRate] = useState(salesHoursCommissionDetail.rate);
    const [salesHoursCommissionStart, setSalesHoursCommissionStart] = useState(salesHoursCommissionDetail.commission_rate_start_date);
    const [salesHoursCommissionEnd, setSalesHoursCommissionEnd] = useState(salesHoursCommissionDetail.commission_rate_end_date);
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const onClick = (e) => {
    const req_obj = {
        coach_name: coachName,
        sales_hours_commission_id: salesHoursCommissionDetail.id,
        rate: rate,
        commission_rate_start_date: salesHoursCommissionStart,
        commission_rate_end_date: salesHoursCommissionEnd
      };
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_SALES_HOURS_COMMISSION, data: req_obj })
    ).then((res) => {
      if (res) {
        refresh();
        setClicked(!clicked);
      } else {
        toastId.current = toast.error(
          "There was an error updating the sales hours commission rate",
          { position: "top-center" }
        );
      }
    }).catch((err) => {
        toastId.current = toast.error("There was an error updating the sales hours commission rate", {
          position: "top-center"
        });
      });
  };

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Rate":
            setRate(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleStartChange = (e) => {
    const selectedDate = new Date(e);
    setSalesHoursCommissionStart(selectedDate.toLocaleDateString());
  };
  const handleEndChange = (e) => {
    const selectedDate = new Date(e);
    setSalesHoursCommissionEnd(selectedDate.toLocaleDateString());
  };

  return (
      <>
        <Input
            value={coachName.value}
            label="Sales Rep"
            id={"Sales Rep"}
            onChange={(e) => handleChange("Sales Rep", e)}
            disabled={true}
        />
        <Input
            value={rate}
            label="Rate"
            id={"Rate"}
            onChange={(e) => handleChange("Rate", e)}
        />
        <DatePicker
          date={salesHoursCommissionStart}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Sales Hours Commission Start Date"}
          title={"COMMISSION START"}>
          <Calendar showPreview={false} onChange={(e) => handleStartChange(e)} />
        </DatePicker>
        <DatePicker
          date={salesHoursCommissionEnd}
          showToggle={showToggle2}
          setShowToggle={setShowToggle2}
          label={"Sales Hours Commission End Date"}
          title={"COMMISSION END"}>
          <Calendar showPreview={false} onChange={(e) => handleEndChange(e)} />
        </DatePicker>
      <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
        SAVE
      </Button>
      </>
  );
};

export default SalesHoursCommissionDetailForm;
