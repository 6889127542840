import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { PlusCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./AdminClientDetail.scss";
import { adminAction, clientAction } from "../../Redux/actions";
import AdminAthleteDetailForm from "./AdminAthleteDetailForm";
import AdminTeamDetailForm from "./AdminTeamDetailForm";
import AdminCorporateDetailForm from "./AdminCorporateDetailForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

library.add(faRunning);
library.add(faBed);

function initializeFaithBased(faith_based) {
  if (faith_based === 1) {
   return  "Yes"
  } else if (faith_based === 2) {
    return "Yes but not a high priority"
  } else {
    return "No"
  }
}

const AdminClientDetail = (props) => {
  const dispatch = useDispatch();
  const clientDetail = useSelector((state) => state.adminReducer.adminClientDetail);
  const clientSessions = useSelector((state) => state.adminReducer.adminSessionsByClient);
  const clientSessionsLoaded = useSelector((state) => state.adminReducer.adminSessionsByClientLoaded);
  const clientSales = useSelector((state) => state.adminReducer.adminSalesByClient);
  const clientSalesLoaded = useSelector((state) => state.adminReducer.adminSalesByClientLoaded);
  const clientAssignments = useSelector((state) => state.adminReducer.allClientAssignmentsGrouped);
  const clientAssignmentsLoaded = useSelector((state) => state.adminReducer.allClientAssignmentsGroupedLoaded);
  const clientNotes = useSelector((state) => state.clientReducer.clientNotesList);
  const clientNotesLoaded = useSelector((state) => state.clientReducer.clientNotesListLoaded);
  const [clicked, setClicked] = useState(false);
  const toastId = useRef(null);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const refresh = () => {
    toastId.current = toast.success(
      "You’ve successfully updated the client profile for: " + clientDetail.client_name,
      { position: "top-center" }
    );
    dispatch(
      adminAction.loadAdminClientDetail({ filters: { client_id: props.location.state.id } })
    );
  };

  useEffect(() => {
    if (props.location.state) {
      dispatch(
        adminAction.loadAdminClientDetail({ filters: { client_id: props.location.state.id } })
      );
      dispatch(
        adminAction.loadAdminSessionsByClient({ filters: { client_id: props.location.state.id } })
      );
      dispatch(
        adminAction.loadAdminSalesByClient({ filters: { client_id: props.location.state.id } })
      );
      dispatch(
        adminAction.loadAllClientAssignmentsGrouped({
          filters: { client_id: props.location.state.id }
        })
      );
      dispatch(
        clientAction.loadClientNotesListData({ filters: { client_id: props.location.state.id } })
      );
    }
  }, [dispatch]);

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <Container className="admin-client-detail-container">
        <Row className="initials-container mt-4">
          <Container>
            <div className="initials">{clientDetail && clientDetail.initials}</div>
          </Container>
        </Row>
        <div className="status">
          {clientDetail.is_active === 1 ? (
            <p>
              Active <FontAwesomeIcon icon="running" className="yellow" />
            </p>
          ) : (
            <p>
              Inactive <FontAwesomeIcon icon="bed" className="yellow" />
            </p>
          )}
        </div>
        <div className="client-name mb-2">
          <p>{clientDetail && clientDetail.client_name}</p>
        </div>
        <div className="client-name mb-2">
          <p>{clientDetail && clientDetail.client_type}</p>
        </div>
        <div className="program-name mb-2">
          <p>{clientDetail && clientDetail.program_name}</p>
          <div>
            {clientDetail && clientDetail.rp_contact_made == 1 && (
              <div style={{ display: "inline-flex" }}>
                <div className="labels" style={{ marginRight: "0.25rem" }}>
                  CONTACTED
                </div>
                <FontAwesomeIcon
                  style={{ marginBottom: "0.20rem" }}
                  icon="check-circle"
                  className="green"
                />
              </div>
            )}
            {clientDetail && clientDetail.rp_contact_made == 0 && (
              <div style={{ display: "inline-flex" }}>
                <div className="labels" style={{ marginRight: "0.25rem" }}>
                  NOT CONTACTED
                </div>
                <FontAwesomeIcon
                  style={{ marginBottom: "0.20rem" }}
                  icon="info-circle"
                  className="red"
                />
              </div>
            )}
          </div>
        </div>
        <AccordionContainer
          values={clientSessions}
          title="SESSION ARCHIVE"
          type="CLIENTS"
          handleClick={handleClick}
          source="AdminClientDetail"
          headers={["topic", "worksheet", "workshop_number", "session_date", "coach_name"]}
          row_id={"session_id"}
          pathName={"session_detail"}
          admin={true}
          loaded={clientSessionsLoaded}
          linkName="session"
          buttonTitle="LOG A SESSION"
          prefill={clientDetail}></AccordionContainer>
        <AccordionContainer
          values={clientAssignments}
          title="ASSIGNMENT HISTORY"
          type="ASSIGNMENTS BY CLIENT"
          handleClick={handleClick}
          source="AdminClientDetail"
          headers={[
            "assignment_program",
            "assignment_start_date",
            "assignment_end_date",
            "coach_name"
          ]}
          row_id="assignment_id"
          admin={true}
          loaded={clientAssignmentsLoaded}
          linkName="assignment"
          pathName="assignment_detail"
          buttonTitle="CREATE AN ASSIGNMENT"
          prefill={clientDetail}></AccordionContainer>
        <AccordionContainer
          values={clientSales}
          loaded={clientSalesLoaded}
          title="SALE ARCHIVE"
          type="SALES_FOR_CLIENT"
          handleClick={handleClick}
          source="AdminClientDetail"
          headers={["program_name", "payment_date", "coach_name"]}
          row_id="sale_id"
          admin={true}
          linkName="sale"
          pathName="sale_detail"
          buttonTitle="LOG A SALE"
          prefill={clientDetail}></AccordionContainer>
        <div className="mt-3 mb-3"/>
        <AccordionContainer
        values={clientNotes}
        loaded={clientNotesLoaded}
        title="NOTES ARCHIVE"
        type="CLIENT NOTES"
        headers={["notes", "date_recorded"]}
        linkName={"client_notes"}
        buttonTitle={"LOG CLIENT NOTES"}
        row_id={"client_notes_id"}
        pathName={"client_notes_detail"}
        handleClick={handleClick}
        source="AdminClientDetail"
        prefill={clientDetail}
        admin={true}/>
        <div className="mt-3 mb-3"/>
        <div className="detail-container">
          <div className="items">
            <div className="top-container">
              {!clicked && <div className="labels">ABOUT</div>}
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
            </div>
            {clicked ? (
              <>
                {clientDetail && clientDetail.client_type == "Athlete" && (
                  <AdminAthleteDetailForm
                    adminClientDetail={clientDetail}
                    clicked={clicked}
                    setClicked={setClicked}
                    refresh={refresh}
                  />
                )}
                {clientDetail && clientDetail.client_type == "Team" && (
                  <AdminTeamDetailForm
                    adminClientDetail={clientDetail}
                    clicked={clicked}
                    setClicked={setClicked}
                    refresh={refresh}
                  />
                )}
                {clientDetail && clientDetail.client_type == "Corporate" && (
                  <AdminCorporateDetailForm
                    adminClientDetail={clientDetail}
                    clicked={clicked}
                    setClicked={setClicked}
                    refresh={refresh}
                  />
                )}
              </>
            ) : (
              <>
                {clientDetail && clientDetail.client_type == "Athlete" && (
                  <AthleteDetail clientDetail={clientDetail} isAdmin={true}/>
                )}
                {clientDetail && clientDetail.client_type == "Team" && (
                  <TeamDetail clientDetail={clientDetail} isAdmin={true}/>
                )}
                {clientDetail && clientDetail.client_type == "Corporate" && (
                  <CorporateDetail clientDetail={clientDetail} isAdmin={true} />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export const AthleteDetail = ({ clientDetail, isAdmin=false }) => {
  return (
    <>
      <div className="labels mt-3">
        Registration Date
        <div className="client-detail-info">{clientDetail.registration_timestamp}</div>
      </div>
      <div className="labels mt-3">
        Nickname
        <div className="client-detail-info">{clientDetail.nickname}</div>
      </div>
      <div className="labels mt-3">
        Date of Birth
        <div className="client-detail-info">{clientDetail.date_of_birth}</div>
      </div>
      <div className="labels mt-3">
        Gender
        <div className="client-detail-info">{clientDetail.gender}</div>
      </div>
      <div className="labels mt-3">
        Email
        <div className="client-detail-info">{clientDetail.email}</div>
      </div>
      <div className="labels mt-3">
        Phone
        <div className="client-detail-info">{clientDetail.phone}</div>
      </div>
      <div className="labels mt-3">
        Street Address
        <div className="client-detail-info">{clientDetail.address}</div>
      </div>
      <div className="labels mt-3">
        City
        <div className="client-detail-info">{clientDetail.city}</div>
      </div>
      <div className="labels mt-3">
        State
        <div className="client-detail-info">{clientDetail.address_state}</div>
      </div>
      <div className="labels mt-3">
        Time Zone
        <div className="client-detail-info">{clientDetail.timezone}</div>
      </div>
      <div className="labels mt-3">
        Primary Sport
        <div className="client-detail-info">{clientDetail.sports}</div>
      </div>
      <div className="labels mt-3">
        School Name
        <div className="client-detail-info">{clientDetail.school_name}</div>
      </div>
      <div className="labels mt-3">
        Club Name
        <div className="client-detail-info">{clientDetail.club_name}</div>
      </div>
      <div className="labels mt-3">
        Training Level
        <div className="client-detail-info">{clientDetail.training_level}</div>
      </div>
      <div className="labels mt-3">
        Personality Type
        <div className="client-detail-info">{clientDetail.personality_type}</div>
      </div>
      <div className="labels mt-3">
        Wants To Include Faith In Their Training
        <div className="client-detail-info">{initializeFaithBased(clientDetail.faith_based)}</div>
      </div>
      <div className="labels mt-3">
        Experience
        <div className="client-detail-info">{clientDetail.experience}</div>
      </div>
      <div className="labels mt-3">
        Goals
        <div className="client-detail-info">{clientDetail.goals}</div>
      </div>
      <div className="labels mt-3">
        Other Aspirations
        <div className="client-detail-info">{clientDetail.other_aspirations}</div>
      </div>
      <div className="labels mt-3">
        Coach Energy Level Preference
        <div className="client-detail-info">{clientDetail.energy_level_preference}</div>
      </div>
      <div className="labels mt-3">
        Desired age group of Mindset Coach
        <div className="client-detail-info">{clientDetail.desired_experience_of_coach}</div>
      </div>
      <div className="labels mt-3">
        Any additional info that might help matching with a Mindset Coach
        <div className="client-detail-info">{clientDetail.additional_info}</div>
      </div>
      <div className="labels mt-3">
        Preferred Session Times
        <div className="client-detail-info">{clientDetail.preferred_session_availability}</div>
      </div>
      <div className="labels mt-3">
        Importance of Life Lessons Learned Through the Program
        <div className="client-detail-info">{clientDetail.importance_of_lessons}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Name
        <div className="client-detail-info">{clientDetail.responsible_person_name}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Phone
        <div className="client-detail-info">{clientDetail.responsible_person_phone}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Email
        <div className="client-detail-info">{clientDetail.responsible_person_email}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Relationship
        <div className="client-detail-info">{clientDetail.responsible_person_relationship}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Involvement
        <div className="client-detail-info">{clientDetail.responsible_person_involvement}</div>
      </div>
      <div className="labels mt-3">
        Current/Most Recent Salesperson
        <div className="client-detail-info">{clientDetail.salesperson_name}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Current/Most Recent Sales Program
        <div className="client-detail-info">{clientDetail.program_name}</div>
      </div>}
      <div className="labels mt-3">
        Assigned Coach
        <div className="client-detail-info">{clientDetail.coach_name}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Assigned Program
        <div className="client-detail-info">{clientDetail.assignment_program}</div>
      </div>}
      <div className="labels mt-3">
        Assignment Start Date
        <div className="client-detail-info">{clientDetail.assignment_start_date}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Assignment End Date
        <div className="client-detail-info">{clientDetail.assignment_end_date}</div>
      </div>}
    </>
  );
};

export const TeamDetail = ({ clientDetail, isAdmin=false }) => {
  return (
    <>
      <div className="labels mt-3">
        Registration Date
        <div className="client-detail-info">{clientDetail.registration_timestamp}</div>
      </div>
      <div className="labels mt-3">
        Primary Sport
        <div className="client-detail-info">{clientDetail.sports}</div>
      </div>
      <div className="labels mt-3">
        School/Street Address
        <div className="client-detail-info">{clientDetail.address}</div>
      </div>
      <div className="labels mt-3">
        City
        <div className="client-detail-info">{clientDetail.city}</div>
      </div>
      <div className="labels mt-3">
        State
        <div className="client-detail-info">{clientDetail.address_state}</div>
      </div>
      <div className="labels mt-3">
        Training Level
        <div className="client-detail-info">{clientDetail.training_level}</div>
      </div>
      <div className="labels mt-3">
        How experienced is your team?
        <div className="client-detail-info">{clientDetail.experience}</div>
      </div>
      <div className="labels mt-3">
        Team Goals
        <div className="client-detail-info">{clientDetail.goals}</div>
      </div>
      <div className="labels mt-3">
        Other Relevant Information
        <div className="client-detail-info">{clientDetail.other_aspirations}</div>
      </div>
      <div className="labels mt-3">
        Do you want to incorporate the Christian faith into your training?
        <div className="client-detail-info">{initializeFaithBased(clientDetail.faith_based)}</div>
      </div>
      <div className="labels mt-3">
        Any additional info that might help matching with a Mindset Coach
        <div className="client-detail-info">{clientDetail.additional_info}</div>
      </div>
      <div className="labels mt-3">
        Preferred Session Times
        <div className="client-detail-info">{clientDetail.preferred_session_availability}</div>
      </div>
      <div className="labels mt-3">
        Importance of Life Lessons Learned Through the Program
        <div className="client-detail-info">{clientDetail.importance_of_lessons}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Name
        <div className="client-detail-info">{clientDetail.responsible_person_name}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Phone
        <div className="client-detail-info">{clientDetail.responsible_person_phone}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Email
        <div className="client-detail-info">{clientDetail.responsible_person_email}</div>
      </div>
      <div className="labels mt-3">
        Responsible Person Relationship
        <div className="client-detail-info">{clientDetail.responsible_person_relationship}</div>
      </div>
      <div className="labels mt-3">
        Assigned Coach
        <div className="client-detail-info">{clientDetail.coach_name}</div>
      </div>
      <div className="labels mt-3">
        Recent Salesperson
        <div className="client-detail-info">{clientDetail.salesperson_name}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Recent Sales Program
        <div className="client-detail-info">{clientDetail.program_name}</div>
      </div>}
      {isAdmin === true && <div className="labels mt-3">
        Assigned Program
        <div className="client-detail-info">{clientDetail.assignment_program}</div>
      </div>}
      <div className="labels mt-3">
        Curriculum
        <div className="client-detail-info">{clientDetail.curriculum}</div>
      </div>
      <div className="labels mt-3">
        Assignment Start Date
        <div className="client-detail-info">{clientDetail.assignment_start_date}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Assignment End Date
        <div className="client-detail-info">{clientDetail.assignment_end_date}</div>
      </div>}
    </>
  );
};

export const CorporateDetail = ({ clientDetail, isAdmin=false }) => {
  return (
    <>
      <div className="labels mt-3">
        Registration Date
        <div className="client-detail-info">{clientDetail.registration_timestamp}</div>
      </div>
      <div className="labels mt-3">
        Profession
        <div className="client-detail-info">{clientDetail.profession}</div>
      </div>
      <div className="labels mt-3">
        Nickname
        <div className="client-detail-info">{clientDetail.nickname}</div>
      </div>
      <div className="labels mt-3">
        Gender
        <div className="client-detail-info">{clientDetail.gender}</div>
      </div>
      <div className="labels mt-3">
        Email
        <div className="client-detail-info">{clientDetail.email}</div>
      </div>
      <div className="labels mt-3">
        Phone
        <div className="client-detail-info">{clientDetail.phone}</div>
      </div>
      <div className="labels mt-3">
        Street Address
        <div className="client-detail-info">{clientDetail.address}</div>
      </div>
      <div className="labels mt-3">
        City
        <div className="client-detail-info">{clientDetail.city}</div>
      </div>
      <div className="labels mt-3">
        State
        <div className="client-detail-info">{clientDetail.address_state}</div>
      </div>
      <div className="labels mt-3">
        Personality Type
        <div className="client-detail-info">{clientDetail.personality_type}</div>
      </div>
      <div className="labels mt-3">
        Wants To Include Faith In Their Training
        <div className="client-detail-info">{initializeFaithBased(clientDetail.faith_based)}</div>
      </div>
      <div className="labels mt-3">
        Job Experience
        <div className="client-detail-info">{clientDetail.experience}</div>
      </div>
      <div className="labels mt-3">
        Goals
        <div className="client-detail-info">{clientDetail.goals}</div>
      </div>
      <div className="labels mt-3">
        Aspirations outside of career
        <div className="client-detail-info">{clientDetail.other_aspirations}</div>
      </div>
      <div className="labels mt-3">
        Coach Energy Level Preference
        <div className="client-detail-info">{clientDetail.energy_level_preference}</div>
      </div>
      <div className="labels mt-3">
        Desired age group of Mindset Coach
        <div className="client-detail-info">{clientDetail.desired_experience_of_coach}</div>
      </div>
      <div className="labels mt-3">
        Any additional info that might help matching with a Mindset Coach
        <div className="client-detail-info">{clientDetail.additional_info}</div>
      </div>
      <div className="labels mt-3">
        Date of Birth
        <div className="client-detail-info">{clientDetail.date_of_birth}</div>
      </div>
      <div className="labels mt-3">
        Assigned Coach
        <div className="client-detail-info">{clientDetail.coach_name}</div>
      </div>
      <div className="labels mt-3">
        Recent Salesperson
        <div className="client-detail-info">{clientDetail.salesperson_name}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Recent Sales Program
        <div className="client-detail-info">{clientDetail.program_name}</div>
      </div>}
      {isAdmin === true && <div className="labels mt-3">
        Assigned Program
        <div className="client-detail-info">{clientDetail.assignment_program}</div>
      </div>}
      <div className="labels mt-3">
        Assignment Start Date
        <div className="client-detail-info">{clientDetail.assignment_start_date}</div>
      </div>
      {isAdmin === true && <div className="labels mt-3">
        Assignment End Date
        <div className="client-detail-info">{clientDetail.assignment_end_date}</div>
      </div>}
    </>
  );
};

export default AdminClientDetail;
