import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import FormContainer from "../../Components/Forms/FormContainer";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import { sessionAction, adminAction } from "../../Redux/actions";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const ClientNotesDetailForm = ({ clientNotesDetail, clicked, setClicked, refresh }) => {
    const [coachName, setCoachName] = useState(
        {
            value: clientNotesDetail.coach_name + ' (' + clientNotesDetail.coach_id + ')',
            label: clientNotesDetail.coach_name + ' (' + clientNotesDetail.coach_id + ')'
        }
    );
    const [notes, setNotes] = useState(clientNotesDetail.notes);
    const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
    const [coachList, setCoachList] = useState([]);
    const [recordedAt, setRecordedAt] = useState(new Date(clientNotesDetail.date_recorded).toLocaleDateString());
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const onClick = (e) => {
    const req_obj = {
        client_notes_id: clientNotesDetail.id,
        client_id: clientNotesDetail.client_id,
        coach_name: coachName.value,
        notes: notes,
        date_recorded: recordedAt
    };
    axios(
        requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_CLIENT_NOTES, data: req_obj })
      ).then((res) => {
        if (res) {
            refresh();
            setClicked(!clicked);
          } else {
            toastId.current = toast.error(
              "There was an error updating the notes",
              { position: "top-center" }
            );
          }
        }).catch((err) => {
            toastId.current = toast.error("There was an error updating the notes", {
              position: "top-center"
            });
          });
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Recorded by":
            setCoachName(e);
            break;
        case "Notes":
            setNotes(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleRecordedAtChange = (e) => {
    const selectedDate = new Date(e);
    setRecordedAt(selectedDate.toLocaleDateString());
  };

  return (
      <>
        <ReactSelect
            options={coachList}
            value={coachName}
            placeholder="Recorded by"
            handleChange={handleChange}
        />
        <DatePicker
          date={recordedAt}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Record of Notes"}
          title={"RECORD DATE"}>
          <Calendar showPreview={false} onChange={(e) => handleRecordedAtChange(e)} />
        </DatePicker>
        <TextArea
            value={notes}
            label="Notes"
            id={"Notes"}
            onChange={(e) => handleChange("Notes", e)}
            height={"300px"}
        />
        <div style={{ height: '200px' }}></div>
        <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
            SAVE
        </Button>
      </>
  );
};

export default ClientNotesDetailForm;
