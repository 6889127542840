import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import '../Components.scss';

const TextArea = ({ label, id, height, value, onChange }) => {
  return (
    <Form.Group className="mt-3">
      <FloatingLabel label={label} className="floating-label">
        <Form.Control
          id={id}
          as="textarea"
          style={{height: height, paddingTop: '3.5rem'}}
          value={value}
          onChange={onChange}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default TextArea;
