import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { saleAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { insert_data } from "../../Utils/insertData";
import { months, years } from "../../Utils/arrayItems";
import { clientTypeList } from "../../Utils/selectConstants";

const ProgramList = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.persona);
  const programList_db = useSelector((state) => state.saleReducer.programList);
  const programListLoaded = useSelector((state) => state.saleReducer.programListLoaded);
  const [allDatesSelected, setAllDatesSelected] = useState({value: 1, label: "All Dates"});
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" }
  ]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [applyClicked, setApplyClicked] = useState(false);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);
  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Program Management" } }));
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        start_date: [startDate],
        end_date: [endDate],
        date_subset_toggle: [allDatesSelected.value],
        page_name: "Program Management"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(saleAction.loadProgramData({ filters: savedUserFilters, groupby: true }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "date_subset_toggle") {
          if (savedUserFilters[key] == true) {
            setAllDatesSelected({
              value: 1, label: 'All Dates'
            })
          }
          else {
            setAllDatesSelected({
              value: 0, label: 'Subset of Dates'
            })
          }
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(saleAction.loadProgramData({ filters: filters, groupby: true }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range" && selector != "Date Range Toggle") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range Toggle":
        setAllDatesSelected(e);
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(saleAction.loadProgramData({ filters: filters, groupby: true }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={programList_db.result}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="PROGRAM MANAGEMENT/PROFIT PER PROGRAM"
      type="PROGRAMS"
      loaded={programListLoaded}
      linkName="program"
      pathName={"program_detail"}
      buttonTitle="ADD A PROGRAM"
      admin={userInfo.is_admin === "1"}
      headers={["program_name", "program_price", "revenue", "session_commission_paid", "num_program_sessions", "sales_commission_paid", "num_program_sales", "profit", "profit_margin"]}
      row_id={"program_id"}
      handleClick={handleClick}
      exportButton={true}
      exportData={programList_db.data}>
      <ReactSelect
        options={[clientTypeList[0], clientTypeList[1], clientTypeList[2], clientTypeList[4]]}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
        id={1}
      />
      <ReactSelect
        options={
          [
            {value: 1, label: 'All Dates'},
            {value: 0, label: 'Subset of Dates'}
          ]
        }
        value={allDatesSelected}
        placeholder="Date Range Toggle"
        handleChange={handleChange}
        id={2}
      />
      {allDatesSelected.value === 0 && <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
      }
    </AccordionContainer>
  );
};

export default ProgramList;
