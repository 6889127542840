import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/ListViews/TableContainer";
import { clientAction, filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { insert_data } from "../../Utils/insertData";

const ClientList = (props) => {
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.clientReducer.clientList);
  const clientListLoaded = useSelector((state) => state.clientReducer.clientListLoaded);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [clientOptions, setClientOptions] = useState({ value: "", label: "" });
  const [clientsSelected, setClientsSelected] = useState([]);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ]);
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team"],
    is_active: ["Active"],
    clients: ["All"]
  });

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "My Clients" } }));
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let sr_list_db = clientList.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientOptions(sr_list_db);
    }
  }, [clientList]);

  const clientTypes = [
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let client_arr = clientsSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: client_arr,
        page_name: "My Clients"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    let newVals = e.filter((item) => item.value != "All");
    switch (selector) {
      case "Clients":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      case "Client Types":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(clientAction.loadClientListData({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "clients") {
          setClientsSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(clientAction.loadClientListData({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(clientAction.loadClientListData({ filters: filters }));
  }, [applyClicked]);

  return (
    <>
      <TableContainer
        title="CLIENTS"
        values={clientList}
        buttonTitle="ADD A CLIENT"
        itemLeftLabel={"full_name"}
        itemRightLabel={"client_type"}
        itemId={"client_id"}
        pathname="/client_detail"
        filtersClicked={filtersClicked}
        filterButton={true}
        loaded={clientListLoaded}
        filters={{
          handleClick,
          setFiltersClicked,
          clientList,
          clientTypes,
          statuses
        }}>
        <ReactSelect
          options={clientOptions}
          value={clientsSelected}
          isMulti={true}
          placeholder="Clients"
          handleChange={handleChange}
        />
        <ReactSelect
          options={clientTypes}
          value={clientTypesSelected}
          isMulti={true}
          placeholder="Client Types"
          handleChange={handleChange}
        />
        <ReactSelect
          options={statuses}
          value={statusSelected}
          isMulti={true}
          placeholder="Status"
          handleChange={handleChange}
        />
      </TableContainer>
    </>
  );
};

export default ClientList;
