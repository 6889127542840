const initialState = {
  sessionList: [],
  sessionListLoaded: false,
  sessionDetail: [],
  sessionWorksheets: [],
  sessionWorksheetDetail: [],
  sessionWorksheetsLoaded: false,
  sessionTopics: [],
  sessionsByClient: [],
  sessionsByClientLoaded: false,
  reimbursements: [],
  reimbursementsLoaded: false,
  reimbursementDetail: [],
  missedSessionList: [],
  missedSessionListLoaded: false,
  missedSessionDetail: [],
  sessionCommissionList: [],
  sessionCommmissionListLoaded: false,
  sessionCommissionDetail: []
};

export const sessionReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_SESSION_LIST':
      return {
        ...state,
        sessionList: payload,
        sessionListLoaded: true,
      }
    case 'RESET_SESSION_LIST':
      return {
        ...state,
        sessionListLoaded: false,
      }
    case 'LOAD_SESSION_DETAIL':
      return {
        ...state,
        sessionDetail: payload
      }
    case 'LOAD_SESSION_WORKSHEET':
      return {
        ...state,
        sessionWorksheets: payload,
        sessionWorksheetsLoaded: true,
      }
    case 'LOAD_SESSION_WORKSHEET_DETAIL':
      return {
        ...state,
        sessionWorksheetDetail: payload
      }
    case 'RESET_SESSION_WORKSEHEET':
      return {
        ...state,
        sessionWorksheetsLoaded: false,
      }
    case 'LOAD_SESSION_TOPIC':
      return {
        ...state,
        sessionTopics: payload
      }
    case 'LOAD_REIMBURSEMENTS':
      return {
        ...state,
        reimbursements: payload,
        reimbursementsLoaded: true,
      }
    case 'RESET_REIMBURSEMENTS':
      return {
        ...state,
        reimbursementsLoaded: false,
      }
    case 'LOAD_REIMBURSEMENT_DETAIL':
      return {
        ...state,
        reimbursementDetail: payload
      }
    case 'LOAD_MISSED_SESSION_LIST':
      return {
        ...state,
        missedSessionList: payload,
        missedSessionListLoaded: true,
      }
    case 'RESET_MISSED_SESSION_LIST':
      return {
        ...state,
        missedSessionListLoaded: false,
      }
    case 'LOAD_SESSION_COMMISSION_LIST':
      return {
        ...state,
        sessionCommissionList: payload,
        sessionCommissionListLoaded: true,
      }
    case 'RESET_SESSION_COMMISSION_LIST':
      return {
        ...state,
        sessionCommissionListLoaded: false,
      }
    case 'LOAD_SESSION_COMMISSION_DETAIL':
      return {
        ...state,
        sessionCommissionDetail: payload
      }
    case 'LOAD_MISSED_SESSION_DETAIL':
      return {
        ...state,
        missedSessionDetail: payload
      }
    case 'LOAD_SESSIONS_BY_CLIENT':
      return {
        ...state,
        sessionsByClient: payload,
        sessionsByClientLoaded: true,
      }
    case 'RESET_SESSIONS_BY_CLIENT':
      return {
        ...state,
        sessionsByClientLoaded: false,
      } 
    case 'UPDATE_COACH_DETAIL':
      return {
        ...state,
        updatedCoachDetail: payload
      }
    default:
      return state
  }
};
