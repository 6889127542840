import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/ListViews/TableContainer";
import { adminAction, clientAction, filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { insert_data } from "../../Utils/insertData";

import { clientTypeList } from "../../Utils/selectConstants";

const AdminClientList = (props) => {
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.adminReducer.allClients);
  const clientAssignmentList = useSelector((state) => state.adminReducer.allClientAssignments);
  const clientAssignmentListLoaded = useSelector((state) => state.adminReducer.allClientAssignmentsLoaded);
  const coachList = useSelector((state) => state.adminReducer.allCoaches);
  const rpPhoneList = useSelector((state) => state.adminReducer.allRpPhoneNumbers);
  const rpNameList = useSelector((state) => state.adminReducer.allRpNames);
  const rpEmailList = useSelector((state) => state.adminReducer.allRpEmails);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const registrationKeyDB = useSelector((state) => state.clientReducer.registrationKey);
  const [clientOptions, setClientOptions] = useState({ value: "", label: "" });
  const [coachOptions, setCoachOptions] = useState({ value: "", label: "" });
  const [rpPhoneOptions, setRpPhoneOptions] = useState({ value: "", label: "" });
  const [rpNameOptions, setRpNameOptions] = useState({ value: "", label: "" });
  const [rpEmailOptions, setRpEmailOptions] = useState({ value: "", label: "" });
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [registrationKey, setRegistrationKey] = useState("");
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" },
    { value: "Corporate", label: "Corporate"}
  ]);
  const [assignmentStatusSelected, setAssignmentStatusSelected] = useState([
    { value: "Assigned", label: "Assigned" },
    { value: "Unassigned", label: "Unassigned" }
  ]);
  const [clientsSelected, setClientsSelected] = useState([{ value: "All", label: "All" }]);
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [rpPhoneSelected, setRpPhoneSelected] = useState([{ value: "All", label: "All" }]);
  const [rpEmailSelected, setRpEmailSelected] = useState([{ value: "All", label: "All" }]);
  const [rpNameSelected, setRpNameSelected] = useState([{ value: "All", label: "All" }]);
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team", "Corporate"],
    is_active: ["Active"],
    clients: ["All"],
    coaches: ["All"],
    rpPhones: ["All"],
    assignmentStatus: ["Assigned", "Unassigned"]
  });

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(adminAction.loadAllClients({ filters: { is_active: ["Active", "Inactive"] } }));
    dispatch(adminAction.loadAllRpPhoneNumbers({ filters: {} }));
    dispatch(adminAction.loadAllRpNames({ filters: {} }));
    dispatch(adminAction.loadAllRpEmails({ filters: {} }));
    dispatch(clientAction.loadRegistrationKeyData({ filters: {} }));
    dispatch(adminAction.loadAllCoaches({ filters: filters }));
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "All Clients" } }));
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let sr_list_db = clientList.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientOptions(sr_list_db);
    }
  }, [clientList]);

  const exportData =
    clientAssignmentList
      .map((v) => {
          const exportRows = [];
          const { client_id, full_name, ...rest } = v;
          exportRows.push({
            full_name,
              ...rest,
          });
          return exportRows;
      })
      .flat() ?? [];

  useEffect(() => {
    if (registrationKeyDB) {
      setRegistrationKey(registrationKeyDB);
    }
  }, [registrationKeyDB]);

  useEffect(() => {
    if (coachList.length > 0) {
      let sr_list_db = coachList.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachOptions(sr_list_db);
    }
  }, [coachList]);

  useEffect(() => {
    if (rpPhoneList.length > 0) {
      let rp_phone_list_db = rpPhoneList.map((rp_phone) => {
        return {
          value: `${rp_phone.responsible_person_phone}`,
          label: `${rp_phone.responsible_person_phone}`
        };
      });
      rp_phone_list_db.unshift({ value: "All", label: "All" });
      setRpPhoneOptions(rp_phone_list_db);
    }
  }, [rpPhoneList]);

  useEffect(() => {
    if (rpNameList.length > 0) {
      let rp_name_list_db = rpNameList.map((rp_name) => {
        return {
          value: `${rp_name.responsible_person_name}`,
          label: `${rp_name.responsible_person_name}`
        };
      });
      rp_name_list_db.unshift({ value: "All", label: "All" });
      setRpNameOptions(rp_name_list_db);
    }
  }, [rpNameList]);

  useEffect(() => {
    if (rpEmailList.length > 0) {
      let rp_email_list_db = rpEmailList.map((rp_email) => {
        return {
          value: `${rp_email.responsible_person_email}`,
          label: `${rp_email.responsible_person_email}`
        };
      });
      rp_email_list_db.unshift({ value: "All", label: "All" });
      setRpEmailOptions(rp_email_list_db);
    }
  }, [rpEmailList]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAllClientAssignments({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "clients") {
          setClientsSelected(temp_array);
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        } else if (key == "rpPhones") {
          setRpPhoneSelected(temp_array);
        } else if (key == "rpNames") {
          setRpNameSelected(temp_array);
        } else if (key == "rpEmails") {
          setRpEmailSelected(temp_array);
        } else if (key == "assignmentStatus") {
          setAssignmentStatusSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAllClientAssignments({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = e.filter((item) => item.value != "All");
    switch (selector) {
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusSelected(newVals);
        }
        break;
      case "Clients":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Responsible Person Phone":
        if (e.length == 0) {
          setRpPhoneSelected([{ value: "All", label: "All" }]);
        } else {
          setRpPhoneSelected(newVals);
        }
        break;
      case "Responsible Person Name":
        if (e.length == 0) {
          setRpNameSelected([{ value: "All", label: "All" }]);
        } else {
          setRpNameSelected(newVals);
        }
        break;
      case "Responsible Person Email":
        if (e.length == 0) {
          setRpEmailSelected([{ value: "All", label: "All" }]);
        } else {
          setRpEmailSelected(newVals);
        }
        break;
      case "Assignment Status":
        if (e.length == 0) {
          setAssignmentStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setAssignmentStatusSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];
  const assignmentStatuses = [
    { value: "Assigned", label: "Assigned" },
    { value: "Unassigned", label: "Unassigned" }
  ];

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let client_arr = clientsSelected.map((data) => data.value);
      let coach_arr = coachesSelected.map((data) => data.value);
      let rpPhone_arr = rpPhoneSelected.map((data) => data.value);
      let rpName_arr = rpNameSelected.map((data) => data.value);
      let rpEmail_arr = rpEmailSelected.map((data) => data.value);
      let assignment_status_arr = assignmentStatusSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: client_arr,
        coaches: coach_arr,
        rpPhones: rpPhone_arr,
        rpNames: rpName_arr,
        rpEmails: rpEmail_arr,
        assignmentStatus: assignment_status_arr,
        page_name: "All Clients"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllClientAssignments({ filters: filters }));
  }, [applyClicked]);

  return (
    <>
      <TableContainer
        values={clientAssignmentList}
        itemId={"client_id"}
        itemLeftLabel={"client_name"}
        itemRightLabel={"coach_name"}
        title="ALL CLIENTS"
        buttonTitle="ADD A CLIENT"
        pathname="/admin_client_detail"
        filtersClicked={filtersClicked}
        filterButton={true}
        registrationButton={true}
        exportButton={true}
        exportData={exportData}
        loaded={clientAssignmentListLoaded}
        registrationKey={registrationKey}
        filters={{
          setFiltersClicked,
          handleClick
        }}>
        <ReactSelect
          options={clientOptions}
          value={clientsSelected}
          isMulti={true}
          placeholder="Clients"
          handleChange={handleChange}
        />
        <ReactSelect
          options={assignmentStatuses}
          value={assignmentStatusSelected}
          isMulti={true}
          placeholder="Assignment Status"
          handleChange={handleChange}
        />
        <ReactSelect
          options={coachOptions}
          value={coachesSelected}
          isMulti={true}
          placeholder="Coaches"
          handleChange={handleChange}
        />
        <ReactSelect
          options={clientTypeList}
          value={clientTypesSelected}
          isMulti={true}
          placeholder="Client Type"
          handleChange={handleChange}
        />
        <ReactSelect
          options={statuses}
          value={statusSelected}
          isMulti={true}
          placeholder="Status"
          handleChange={handleChange}
        />
        <ReactSelect
          options={rpNameOptions}
          value={rpNameSelected}
          isMulti={true}
          placeholder="Responsible Person Name"
          handleChange={handleChange}
        />
        <ReactSelect
          options={rpPhoneOptions}
          value={rpPhoneSelected}
          isMulti={true}
          placeholder="Responsible Person Phone"
          handleChange={handleChange}
        />
        <ReactSelect
          options={rpEmailOptions}
          value={rpEmailSelected}
          isMulti={true}
          placeholder="Responsible Person Email"
          handleChange={handleChange}
        />
      </TableContainer>
    </>
  );
};

export default AdminClientList;
