import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import API_CONSTANTS from "../../Redux/constants";
import { lessonsImportanceOptions, 
         clientTypeList, 
         stateList,
         trainingLevelList,
         experienceList,
         personalityTypeList,
         faithBasedOptions,
         sportOptions,
         energyLevelPreferenceOptions,
         desiredCoachExperienceLevelOptions,
         genderOptionsList
        } from "../../Utils/selectConstants.js"
import { requestOptions } from "../../Utils";
import { adminAction, saleAction, filterAction } from "../../Redux/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminCorporateDetailForm = ({ adminClientDetail, clicked, setClicked, refresh }) => {
    const dispatch = useDispatch();
    const [fullName, setFullName] = useState(adminClientDetail.client_name)
    const [active, setActive] = useState({value: adminClientDetail.is_active, label: adminClientDetail.is_active == 1 ? "Yes" : "No"});
    const [date_of_birth, setDateOfBirth] = useState(adminClientDetail.date_of_birth)
    const [email, setEmail] = useState(adminClientDetail.email)
    const [registration_timestamp, setRegistrationTimestamp] = useState(adminClientDetail.registration_timestamp)
    const [address, setAddress] = useState(adminClientDetail.address)
    const [nickname, setNickname] = useState(adminClientDetail.nickname)
    const [phone, setPhone] = useState(adminClientDetail.phone)
    const [city, setCity] = useState(adminClientDetail.city);
    const [state, setState] = useState({value: adminClientDetail.address_state, label: adminClientDetail.address_state})
    const [experience, setExperience] = useState({value: adminClientDetail.experience, label: adminClientDetail.experience})
    const [rpName, setRpName] = useState(adminClientDetail.responsible_person_name)
    const [rpEmail, setRpEmail] = useState(adminClientDetail.responsible_person_email)
    const [rpPhone, setRpPhone] = useState(adminClientDetail.responsible_person_phone)
    const [rpRelation, setRpRelation] = useState(adminClientDetail.responsible_person_relationship)
    const [coachName, setCoachName] = useState({value: adminClientDetail.coach_name + ' (' + adminClientDetail.coach_id +')', label: adminClientDetail.coach_name + ' (' + adminClientDetail.coach_id +')'});
    const [assignmentStart, setAssignmentStart] = useState(adminClientDetail.assignment_start_date);
    const [assignmentEnd, setAssignmentEnd] = useState(adminClientDetail.assignment_end_date);
    const assignmentId = useState(adminClientDetail.assignment_id);
    const clientId = useState(adminClientDetail.client_id);
    const [clientType, setClientType] = useState({value: adminClientDetail.client_type, label: adminClientDetail.client_type});
    const [personalityType, setPersonalityType] = useState({value: adminClientDetail.personality_type, label: adminClientDetail.personality_type});
    const [currentProgram, setCurrentProgram] = useState({value: adminClientDetail.assignment_program + ' (' + adminClientDetail.assignment_program_id +')', label: adminClientDetail.assignment_program + ' (' + adminClientDetail.assignment_program_id +')'})
    const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
    const toastId = useRef(null);
    const [faithBased, setFaithBased] = useState(adminClientDetail.faith_based == 1 ? {value: 1, label: "Yes"} : {value: 0, label: "No"});
    const [goals, setGoals] = useState(adminClientDetail.goals);
    const [aspirations, setAspirations] = useState(adminClientDetail.other_aspirations);
    const [lessonsImportance, setLessonsImportance] = useState({value: adminClientDetail.importance_of_lessons, label: adminClientDetail.importance_of_lessons});
    const [energyLevelPreference, setEnergyLevelPreference] = useState({value: adminClientDetail.energy_level_preference, label: adminClientDetail.energy_level_preference});
    const [desiredCoachExperienceLevel, setDesiredCoachExperienceLevel] = useState({value: adminClientDetail.desired_experience_of_coach, label: adminClientDetail.desired_experience_of_coach});
    const [additionalInfo, setAdditionalInfo] = useState(adminClientDetail.additional_info);
    const [gender, setGender] = useState({value: adminClientDetail.gender, label: adminClientDetail.gender});
    const [curriculum, setCurriculum] = useState(adminClientDetail.curriculum);
    const [profession, setProfession] = useState(adminClientDetail.profession);
    const [preferredSessionAvailability, setPreferredSessionAvailability] = useState(adminClientDetail.preferred_session_availability);

    const programList_db = useSelector((state) => state.filterReducer.programList);
    const [coachList, setCoachList] = useState({ value: "", label: "" });
    const [programList, setProgramList] = useState({value: "", label: "" });
  
    const activeList = [ {value: "1", label: "Yes"}, {value: "0", label: "No"}]
  
     useEffect(() => {
       dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
       dispatch(filterAction.loadProgramList({ filters: {} }))
     }, [dispatch])
  
     useEffect(() => {
       if (coachList_db.length > 0) {
         let sr_list_db = coachList_db.map((coach) => {
           return {
             value: `${coach.full_name} (${coach.coach_id})`,
             label: `${coach.full_name} (${coach.coach_id})`
           };
         });
         sr_list_db.push({ value: "All", label: "All" });
         setCoachList(sr_list_db);
       }
     }, [coachList_db]);
  
     useEffect(() => {
       let p_list_db = programList_db.map((program) => {
         return {
           value: `${program.program_name} (${program.program_id})`,
           label: `${program.program_name} (${program.program_id})`
         }
       });
       p_list_db.push({value: "Custom", label: "Custom"});
       p_list_db.push({value: "", label: ""});
       setProgramList(p_list_db);
     }, [programList_db]);
  
    const onClick = (e) => {
      const req_obj = {
        address: address,
        address_state: state.value,
        city: city,
        assignment_end_date: assignmentEnd,
        assignment_id: assignmentId,
        assignment_program: currentProgram.value,
        assignment_start_date: assignmentStart,
        assignment_type: clientType.value,
        client_id: clientId,
        full_name: fullName,
        client_type: clientType.value,
        coach_name: coachName.value,
        date_of_birth: date_of_birth,
        email: email,
        experience: experience.value,
        is_active: active.value,
        nickname: nickname,
        personality_type: personalityType.value,
        phone: phone,
        additional_info: additionalInfo,
        energy_level_preference: energyLevelPreference.value,
        desired_experience_of_coach: desiredCoachExperienceLevel.value,
        responsible_person_name: rpName,
        responsible_person_email: rpEmail,
        responsible_person_phone: rpPhone,
        responsible_person_relationship: rpRelation,
        faith_based: faithBased.value,
        goals: goals,
        other_aspirations: aspirations,
        importance_of_lessons: lessonsImportance.value,
        gender: gender.value,
        profession: profession,
        preferred_session_availability: preferredSessionAvailability
      };
      axios(
        requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_CLIENT_DETAIL, data: req_obj })
      ).then((res) => {
          if (res) {
            refresh();
            setClicked(!clicked);
          } else {
            toastId.current = toast.error(
              "There was an error updating the client profile for: " + req_obj["full_name"],
              { position: "top-center" }
            );
          }
        }).catch((err) => {
            toastId.current = toast.error("There was an error updating the client profile", {
              position: "top-center"
            });
          });
    };
  
    const handleChange = (selector, e) => {
      switch (selector) {
        case "Street Address":
          setAddress(e.target.value);
          break;
        case "Gender":
          setGender(e);
          break;
        case "City":
          setCity(e.target.value);
          break;
        case "State":
          setState(e);
          break;
        case "Assignment End":
          setAssignmentEnd(e.target.value);
          break;
        case "Current Program":
          setCurrentProgram(e);
          break;
        case "Assignment Start":
          setAssignmentStart(e.target.value);
          break;
        case "Client Type":
          setClientType(e);
          break;
        case "Full Name":
          setFullName(e.target.value);
          break;
        case "Assigned Coach":
          setCoachName(e);
          break;
        case "Date of Birth":
          setDateOfBirth(e.target.value);
          break;
        case "Email":
          setEmail(e.target.value);
          break;
        case "Job Experience":
          setExperience(e);
          break;
        case "Wants To Include Faith In Their Training":
          setFaithBased(e);
          break;
        case "Goals":
          setGoals(e.target.value);
          break;
        case "Other Aspirations":
          setAspirations(e.target.value);
          break;
        case "Importance of Life Lessons":
          setLessonsImportance(e);
          break;
        case "Active":
          setActive(e);
          break;
        case "Nickname":
          setNickname(e.target.value);
          break;
        case "Personality Type":
          setPersonalityType(e);
          break;
        case "Phone":
          setPhone(e.target.value);
          break;
        case "Responsible Person Name":
          setRpName(e.target.value);
          break;
        case "Responsible Person Phone":
          setRpPhone(e.target.value);
          break;
        case "Responsible Person Email":
          setRpEmail(e.target.value);
          break;
        case "Responsible Person Relationship":
          setRpRelation(e.target.value);
          break;
        case "Profession":
          setProfession(e);
          break;
        case "Do you prefer a Mindset Coach with low or high energy?":
          setEnergyLevelPreference(e);
          break;
        case "Desired age range of assigned Mindset Coach":
          setDesiredCoachExperienceLevel(e);
          break;
        case "Additional info that will help match you with a Mindset Coach":
          setAdditionalInfo(e.target.value);
          break;
        case "Curriculum":
          setCurriculum(e.target.value);
          break;
        case "Preferred Session Times":
          setPreferredSessionAvailability(e.target.value);
          break;
        default:
          break;
      }
    };
  
    return (
      <>
      <div className="mt-2">
        <ToastContainer limit={1} theme="dark" />
        <Input
          type="text"
          label="Full Name"
          value={fullName}
          onChange={(e) => handleChange("Full Name", e)}
        />
        <ReactSelect
          type="text"
          placeholder="Active"
          value={active}
          options={activeList}
          handleChange={handleChange}
        />
        <ReactSelect
          type="text"
          options={clientTypeList}
          placeholder="Client Type"
          handleChange={handleChange}
          value={clientType}
        />
        <Input
          type="text"
          label="Registration Date"
          value={registration_timestamp}
          disabled={true}
        />
        <Input
          type="text"
          label="Nickname"
          value={nickname}
          onChange={(e) => handleChange("Nickname", e)}
        />
        <ReactSelect
          type="text"
          placeholder="Gender"
          options={genderOptionsList}
          value={gender}
          handleChange={handleChange}
        />
        <Input
          type="text"
          label="Date of Birth"
          value={date_of_birth}
          onChange={(e) => handleChange("Date of Birth", e)}
          disabled={true}
        />
        <Input
          type="text"
          label="Email"
          value={email}
          onChange={(e) => handleChange("Email", e)}
        />
        <Input
          type="text"
          label="Phone"
          value={phone}
          onChange={(e) => handleChange("Phone", e)}
        />
        <Input
          type="text"
          label="Street Address"
          value={address}
          onChange={(e) => handleChange("Street Address", e)}
        />
        <Input
          type="text"
          label="City"
          value={city}
          onChange={(e) => handleChange("City", e)}
        />
        <ReactSelect
          type="text"
          placeholder="State"
          options={stateList}
          value={state}
          handleChange={handleChange}
        />
        <Input
          label="Profession"
          type="text"
          value={profession}
          onChange={(e) => handleChange("Profession", e)}
        />
        <ReactSelect
          type="text"
          placeholder="Personality Type"
          value={personalityType}
          options={personalityTypeList}
          handleChange={handleChange}
        />
        <ReactSelect
          options={faithBasedOptions}
          value={faithBased}
          placeholder="Wants To Include Faith In Their Training"
          handleChange={handleChange}
        />
        <ReactSelect
          type="text"
          placeholder="Job Experience"
          value={experience}
          options={experienceList}
          handleChange={handleChange}
        />
        <Input
          label="Goals"
          type="text"
          value={goals}
          onChange={(e) => handleChange("Goals", e)}
        />
        <Input
          label="Other Aspirations"
          type="text"
          value={aspirations}
          onChange={(e) => handleChange("Other Aspirations", e)}
        />
        <ReactSelect
          options={energyLevelPreferenceOptions}
          value={energyLevelPreference}
          placeholder="Do you prefer a Mindset Coach with low or high energy?"
          handleChange={handleChange}
        />
        <ReactSelect
          options={desiredCoachExperienceLevelOptions}
          value={desiredCoachExperienceLevel}
          placeholder="Desired age range of assigned Mindset Coach"
          handleChange={handleChange}
        />
        <Input
          label="Additional info that will help match you with a Mindset Coach"
          type="text"
          value={additionalInfo}
          onChange={(e) => handleChange("Additional info that will help match you with a Mindset Coach", e)}
        />
        <Input
          label="Preferred Session Times"
          type="text"
          value={preferredSessionAvailability}
          onChange={(e) => handleChange("Preferred Session Times", e)}
        />
        <ReactSelect
          options={lessonsImportanceOptions}
          value={lessonsImportance}
          placeholder="Importance of Life Lessons"
          handleChange={handleChange}
        />
        <Input
          type="text"
          label="Responsible Person Name"
          onChange={(e) => handleChange("Responsible Person Name", e)}
          value={rpName}
        />
        <Input
          type="text"
          label="Responsible Person Phone"
          onChange={(e) => handleChange("Responsible Person Phone", e)}
          value={rpPhone}
        />
        <Input
          type="text"
          label="Responsible Person Email"
          onChange={(e) => handleChange("Responsible Person Email", e)}
          value={rpEmail}
        />
        <Input
          type="text"
          label="Responsible Person Relationship"
          onChange={(e) => handleChange("Responsible Person Relationship", e)}
          value={rpRelation}
        />
        <Input
          type="text"
          label="Assigned Coach"
          onChange={(e) => handleChange("Assigned Coach", e)}
          value={coachName.value}
          disabled
        />
        <Input
          type="text"
          label="Current Program"
          onChange={(e) => handleChange("Current Program", e)}
          value={currentProgram.value}
          disabled
        />
        <Input
          type="date"
          label="Assignment Start"
          onChange={(e) => handleChange("Assignment Start", e)}
          value={assignmentStart}
          disabled
        />
        <Input
          type="date"
          label="Assignment End"
          onChange={(e) => handleChange("Assignment End", e)}
          value={assignmentEnd}
          disabled
        />
        <Button onClick={onClick} className="worksheet mt-3" style={{ width: "20%" }}>
          SAVE
        </Button>
      </div>
      </>
    );
  };

export default AdminCorporateDetailForm;
