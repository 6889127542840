import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let blogList, blogDetail;

let { resetLoader, setLoader } = loaderAction;

const loadBlogListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadBlogList'))
  if (typeof blogList != typeof undefined) {
    blogList.cancel('The load operation has been canceled due to a new request')
  }
  blogList = axios.CancelToken.source()
  dispatch(resetBlogListData());
  axios(requestOptions({method: 'POST', url: apiConstants.BLOG_LIST, data: filters, token: blogList.token})).then((res) => {
    dispatch(populateBlogListData(res['data']['result']));
    dispatch(resetLoader('loadBlogList'));
  })
  .catch(err => console.log(err))
};

const loadBlogDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadBlogDetail'))
  if (typeof blogDetail != typeof undefined) {
    blogDetail.cancel('The load operation has been canceled due to a new request')
  }
  blogDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.BLOG_DETAIL, data: filters, token: blogDetail.token})).then((res) => {
    dispatch(populateBlogDetailData(res['data']['result']));
    dispatch(resetLoader('loadBlogDetail'));
  })
  .catch(err => console.log(err))
};

const populateBlogListData = (data) => ({
  type: 'LOAD_BLOG_LIST',
  payload: data
});

const resetBlogListData = (data) => ({
  type: 'RESET_BLOG_LIST',
  blogListLoaded: false,
});

const populateBlogDetailData = (data) => ({
  type: 'LOAD_BLOG_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const blogAction = {
  loadBlogListData,
  loadBlogDetailData,
  resetData
}
