import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import React, { useState } from "react";
import {
  faithBasedOptions,
  salesSourceList,
  sportOptions,
  stateList,
  trainingLevelList
} from "../../Utils/selectConstants";
import insertSignUpData from "../../Utils/insertSignUpData.js";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormGroup from "./FormGroup.js";

const NewTeamForm = ({
  firstName,
  lastName,
  nickname,
  gender,
  email,
  phone,
  address,
  city,
  state,
  trainingLevel,
  school,
  club,
  experience,
  personalityType,
  faithBased,
  goals,
  aspirations,
  additionalInfo,
  lessonsImportance,
  dateOfBirth,
  rpName,
  rpPhone,
  rpEmail,
  rpRelation,
  rpInvolvement,
  teamExperienceLevel,
  teamName,
  profession,
  sports,
  salesSource,
  salesSourceDetailsLabel,
  salesSourceDetails,
  energyLevelPreference,
  desiredCoachExperienceLevel,
  timezone,
  preferredSessionAvailability,
  handleChange,
  clientType,
  reset_data,
  setClientType
}) => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log(form.checkValidity());
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      // do nothing
    } else {
      insertSignUpData(req_obj, clientType, reset_data);
    }

    setValidated(true);
  };

  const req_obj = {
    is_active: 1,
    client_type: clientType.value,
    first_name: firstName,
    last_name: lastName,
    nickname: nickname,
    gender: gender.value,
    team_name: teamName,
    email: email,
    phone: phone,
    address: address,
    teamExperienceLevel,
    city: city,
    profession: profession,
    address_state: state.value,
    training_level: trainingLevel.value,
    school: school,
    club: club,
    experience: experience.value,
    personality_type: personalityType.value,
    date_of_birth: dateOfBirth,
    responsible_person_name: rpName,
    responsible_person_phone: rpPhone,
    responsible_person_email: rpEmail,
    responsible_person_relationship: rpRelation,
    responsible_person_involvement: rpInvolvement.value,
    faith_based: faithBased.value,
    goals: goals,
    other_aspirations: aspirations,
    additional_info: additionalInfo,
    energy_level_preference: energyLevelPreference.value,
    desired_experience_of_coach: desiredCoachExperienceLevel.value,
    sports: sports.map((data) => data.value),
    importance_of_lessons: lessonsImportance.value,
    sales_source: salesSource.value,
    sales_source_details: salesSourceDetails,
    primary_time_zone: timezone.value,
    preferred_session_availability: preferredSessionAvailability
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3 mt-3">
        <FormGroup
          type={"text"}
          value={teamName}
          handleChange={handleChange}
          placeholder={"Team Name"}
          required={true}
          label={"Team Name"}
          error_message={"Please enter a Team Name."}
        />
        <FormGroup
          type={"text"}
          value={address}
          handleChange={handleChange}
          placeholder={"School/Team Street Address"}
          required={true}
          label={"School/Team Street Address"}
          error_message={"Please enter a School/Team Street Address."}
        />
        <FormGroup
          type={"text"}
          value={city}
          handleChange={handleChange}
          placeholder={"City/Town"}
          required={true}
          label={"City/Town"}
          error_message={"Please enter a City/Town."}
        />
        <FormGroup
          type={"select"}
          value={state.value}
          handleChange={handleChange}
          required={true}
          selector={"State"}
          label={"State"}
          options={stateList}
          error_message={"Please select a State."}
          defaultVal={"Select a State"}
        />
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <ReactSelect
            options={sportOptions}
            value={sports}
            placeholder="Primary Sport(s)"
            handleChange={handleChange}
            isMulti
          />
          <Form.Control.Feedback type="invalid">
            Please select a Primary Sport.
          </Form.Control.Feedback>
        </Form.Group>
        <FormGroup
          type={"select"}
          value={trainingLevel.value}
          handleChange={handleChange}
          required={true}
          selector={"Training Level"}
          label={"Training Level"}
          options={trainingLevelList}
          error_message={"Please select a training level."}
          defaultVal={"Select a Training Level"}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type={"text"}
          value={teamExperienceLevel}
          handleChange={handleChange}
          required={true}
          placeholder={"How experienced is your team?"}
          label={"How experienced is your team?"}
          error_message={"Please enter team experience."}
        />
        <FormGroup
          type={"select"}
          value={faithBased.value}
          handleChange={handleChange}
          required={true}
          selector={"Would you like to incorporate the Christian faith into your training?"}
          label={"Would you like to incorporate the Christian faith into your training?"}
          options={faithBasedOptions}
          error_message={"Please select an option."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={goals}
          handleChange={handleChange}
          placeholder={"Team Goals"}
          required={true}
          rows={5}
          label={"Team Goals"}
          error_message={"Please enter your team goals."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={aspirations}
          handleChange={handleChange}
          placeholder={"Other Relevant Information"}
          rows={5}
          label={"Other Relevant Information"}
          error_message={"Please enter any other relevant information."}
        />
        <FormGroup
          as={"textarea"}
          value={additionalInfo}
          handleChange={handleChange}
          placeholder={"Additional info that will help match you with a Mindset Coach"}
          rows={5}
          label={"Additional info that will help match you with a Mindset Coach"}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={preferredSessionAvailability}
          handleChange={handleChange}
          required={true}
          placeholder={
            "We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?"
          }
          rows={5}
          label={
            "We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?"
          }
          error_message={"Please fill out this field."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type={"text"}
          value={rpName}
          handleChange={handleChange}
          required={true}
          placeholder={"Responsible Person Name"}
          label={"Responsible Person Name"}
          error_message={"Please enter responsible person name."}
        />
        <FormGroup
          type={"text"}
          value={rpPhone}
          handleChange={handleChange}
          required={true}
          placeholder={"Responsible Person Phone"}
          label={"Responsible Person Phone"}
          error_message={"Please enter valid responsible person phone number."}
        />
        <FormGroup
          type={"text"}
          value={rpEmail}
          handleChange={handleChange}
          required={true}
          placeholder={"Responsible Person Email"}
          label={"Responsible Person Email"}
          error_message={"Please enter responsible person email."}
        />
        <FormGroup
          type={"text"}
          value={rpRelation}
          handleChange={handleChange}
          required={true}
          placeholder={"Responsible Person Relationship"}
          label={"Responsible Person Relationship"}
          error_message={"Please enter responsible person relationship."}
        />
      </Row>
      <ReactSelect
        options={salesSourceList}
        value={salesSource}
        placeholder="How did you hear about Winning Mindset?"
        handleChange={handleChange}
      />
      {salesSource.value === "Team/Club Presentation" ||
      salesSource.value === "Convention" ||
      salesSource.value == "Referral" ||
      salesSource.value == "Social Media" ||
      salesSource.value == "Tournament" ||
      salesSource.value == "Mindset Coach" ? (
        <Input
          label={salesSourceDetailsLabel}
          type="text"
          value={salesSourceDetails}
          onChange={(e) => handleChange(salesSourceDetailsLabel, e)}
        />
      ) : (
        <div></div>
      )}
      <Button className="mt-3" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default NewTeamForm;
