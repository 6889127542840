const initialState = {
  saleList: [],
  saleListLoaded: false,
  saleDetail: [],
  programList: [],
  programListLoaded: false,
  salesHours: [],
  salesHoursLoaded: false,
  salesHoursDetail: [],
  myExpiringSubscriptions: [],
  myExpiringSubscriptionsLoaded: false,
  programDetail: [],
  salesCommissionsList: [],
  salesCommissionsListLoaded: false,
  salesCommissionDetail: []
};

export const saleReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_SALE_LIST':
      return {
        ...state,
        saleList: payload,
        saleListLoaded: true,
      }
    case 'RESET_SALE_LIST':
      return {
        ...state,
        saleListLoaded: false,
      }
    case 'LOAD_SALE_DETAIL':
      return {
        ...state,
        saleDetail: payload
      }
    case 'LOAD_PROGRAMS':
      return {
        ...state,
        programList: payload,
        programListLoaded: true,
      }
    case 'RESET_PROGRAMS':
      return {
        ...state,
        programListLoaded: false,
      }
    case 'LOAD_PROGRAM_DETAIL':
      return {
        ...state,
        programDetail: payload
      }
    case 'LOAD_SALES_HOURS_LIST':
      return {
        ...state,
        salesHours: payload,
        salesHoursLoaded: true,
      }
    case 'RESET_SALES_HOURS_LIST':
      return {
        ...state,
        salesHoursLoaded: false,
      }
    case 'LOAD_SALES_HOURS_DETAIL':
      return {
        ...state,
        salesHoursDetail: payload
      }
    case 'LOAD_MY_EXPIRING_SUBSCRIPTIONS':
      return {
        ...state,
        myExpiringSubscriptions: payload,
        myExpiringSubscriptionsLoaded: true
      }
    case 'RESET_MY_EXPIRING_SUBSCRIPTIONS':
      return {
        ...state,
        myExpiringSubscriptionsLoaded: false
      }
    case 'LOAD_SALES_COMMISSIONS_LIST':
      return {
        ...state,
        salesCommissionsList: payload,
        salesCommissionsListLoaded: true
      }
    case 'RESET_SALES_COMMISSION_LIST':
      return {
        salesCommissionsListLoaded: false
      }
    case 'LOAD_SALES_COMMISSION_DETAIL':
      return {
        ...state,
        salesCommissionDetail: payload
      }
    default:
      return state
  }
};
