import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import { clientTypeList } from "../../Utils/selectConstants";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";

const AdminUnloggedSessions = () => {
  const dispatch = useDispatch();
  const unloggedSessionClients = useSelector((state) => state.adminReducer.adminUnloggedSessions);
  const unloggedSessionClientsLoaded = useSelector((state) => state.adminReducer.adminUnloggedSessionsLoaded);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [coachList, setCoachList] = useState({ value: "All", label: "All" });
  const [showToggle, setShowToggle] = useState(false);
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ]);
  const statuses = [{ value: "Active", label: "Active" }];
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team"],
    is_active: ["Active"],
    coaches: ["All"]
  });

  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Admin Unlogged Sessions" } })
    );
    dispatch(adminAction.loadAllCoaches({ filters: filters }));
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Coach":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          // remove default value if new one is selected
          let newVals = e.filter((item) => item.value != "All");
          setCoachesSelected(newVals);
        }
        break;
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([
            { value: "Athlete", label: "Athlete" },
            { value: "Team", label: "Team" }
          ]);
        } else {
          // remove default value if new one is selected
          let newVals = e.filter((item) => item.value != "All");
          setClientTypesSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        coaches: coaches_arr,
        start_date: [startDate],
        end_date: [endDate],
        page_name: "Admin Unlogged Sessions"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminUnloggedSessions({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        } else if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminUnloggedSessions({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminUnloggedSessions({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={unloggedSessionClients}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="UNLOGGED SESSIONS BY COACH"
      type="UNLOGGED_SESSIONS_BY_COACH"
      headers={["full_name", "assignment_program"]}
      row_id={"client_id"}
      pathName={"client_detail"}
      linkName={"client"}
      loaded={unloggedSessionClientsLoaded}
      admin={true}
      handleClick={handleClick}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coach"
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypeList}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={statuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
      />
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </AccordionContainer>
  );
};

export default AdminUnloggedSessions;
