import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import TableContainer from "../../Components/ListViews/TableContainer";
import { sessionAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { insert_data } from "../../Utils/insertData";
import { months, years } from "../../Utils/arrayItems";

const MissedSessionsList = (props) => {
  const dispatch = useDispatch();
  const missedSessionList = useSelector((state) => state.sessionReducer.missedSessionList);
  const missedSessionListLoaded = useSelector((state) => state.sessionReducer.missedSessionListLoaded);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [applyClicked, setApplyClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "My Missed Sessions" } }));
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        page_name: "My Missed Sessions"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(sessionAction.loadMissedSessionListData({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(sessionAction.loadMissedSessionListData({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(sessionAction.loadMissedSessionListData({ filters: filters }));
  }, [applyClicked]);

  return (
    <TableContainer
      title="MISSED SESSIONS"
      values={missedSessionList}
      buttonTitle="LOG A MISSED SESSION"
      itemLeftLabel={"client_name"}
      itemRightLabel={"session_date"}
      itemId={"missed_session_id"}
      pathname="/missed_session_detail"
      linkName="missed_session"
      filtersClicked={filtersClicked}
      loaded={missedSessionListLoaded}
      filterButton={true}
      filters={{ handleClick, setFiltersClicked }}>
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </TableContainer>
  );
};

export default MissedSessionsList;
