import React, { useEffect, useState } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { expenseData } from "./expenseData";
import Filters from "../../Components/Filters";
import "./AdminExpenses.scss";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { months, years } from "../../Utils/arrayItems";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { TailSpin } from "react-loader-spinner";

const AdminExpensesPage = (props) => {
  const dispatch = useDispatch();
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  let expense = useSelector((state) => state.adminReducer.expenseReport);
  let expensesLoaded = useSelector((state) => state.adminReducer.expenseReportLoaded);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [data, setData] = useState(expense);
  const [count, setCount] = useState(0);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [sortingDirection, setSortingDirection] = useState({
    coach_name: "ASC",
    total: "",
    athlete: "",
    sales: "",
    video: "",
    inPerson: "",
    events: "",
    corporateSessions: "",
    payment_adjustments: "",
    hours_commission: "",
    salary: "",
    bonus_amount: "",
    reimbursement_price: ""
  });

  const [showToggle, setShowToggle] = useState(false);

  const sortColumn = (column) => {
    const direction = sortingDirection[column];
    if (direction === "ASC" || direction === "") {
      const sortedData = [...expense].sort((a, b) => (a[column] < b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirection({ ...sortingDirection, [column]: "DESC" });
    } else if (direction === "DESC") {
      const sortedData = [...expense].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      setData(sortedData);
      setSortingDirection({ ...sortingDirection, [column]: "ASC" });
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Expenses" } }));
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        coaches: coaches_arr,
        page_name: "Expenses"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setData(expense);
  }, [expense]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadExpenseReport({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadExpenseReport({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadExpenseReport({ filters: filters }));
  }, [applyClicked]);

  return (
    <div className="expenses-container">
      <Row className="mt-4">
        <div className="title-row">
          <div className="expenses-title">EXPENSES</div>
          <Button
            className="filters-button-client"
            onClick={() => setFiltersClicked(!filtersClicked)}>
            FILTERS
          </Button>
        </div>
      </Row>
      {filtersClicked && (
        <Filters
          setFiltersClicked={setFiltersClicked}
          filtersClicked={filtersClicked}
          onClick={handleClick}>
          <ReactSelect
            options={coachList}
            value={coachesSelected}
            isMulti={true}
            placeholder="Coaches"
            handleChange={handleChange}
          />
          <DatePicker
            key={100}
            label={"Date Range"}
            startDate={startDate}
            endDate={endDate}
            showToggle={showToggle}
            setShowToggle={setShowToggle}
            type="range">
            <DateRangePicker
              startDatePlaceholder={startDate}
              endDatePlaceholder={endDate}
              ranges={[
                { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
              ]}
              onChange={(e) => handleChange("Date Range", e)}
              showPreview={false}
            />
          </DatePicker>
        </Filters>
      )}
      <Row className="mt-4">
        {!expensesLoaded && (
              <div style={{ margin: 'auto' }}>
                  <TailSpin
                    visible={true}
                    height="60"
                    width="100%"
                    color="#f1eb42e9"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  </div>
        )}
        {expensesLoaded && <Table striped variant="dark" className="expenses-table" responsive>
          <thead>
            <tr>
              <th onClick={() => sortColumn("coach_name")}>Coach Name</th>
              <th onClick={() => sortColumn("total")}>Total</th>
              <th onClick={() => sortColumn("athlete")}>Athlete</th>
              <th onClick={() => sortColumn("sales")}>Sales</th>
              <th onClick={() => sortColumn("video")}>Video</th>
              <th onClick={() => sortColumn("inPerson")}>In Person</th>
              <th onClick={() => sortColumn("events")}>Events</th>
              <th onClick={() => sortColumn("coporateSessions")}>Corporate Sessions</th>
              <th onClick={() => sortColumn("payment_adjustments")}>Payment Adjustments</th>
              <th onClick={() => sortColumn("hours_commission")}>Sales Hourly Commission</th>
              <th onClick={() => sortColumn("salary")}>Salary</th>
              <th onClick={() => sortColumn("bonus_amount")}>Bonus</th>
              <th onClick={() => sortColumn("reimbursement_price")}>Reimbursements</th>
            </tr>
          </thead>
          <tbody>
            {data && 
              data.map((coach) => {
                return (
                  <tr key={`{${coach.coach_name}${coach.coach_id}`}>
                    <td>{coach.breakdown.coach_name}</td>
                    <td>{`$${coach.breakdown.total.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.athlete.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.sales.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.video.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.inPerson.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.events.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.corporateSessions.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.payment_adjustments.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.hours_commission.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.salary.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.bonus_amount.toLocaleString()}`}</td>
                    <td>{`$${coach.breakdown.reimbursement_price.toLocaleString()}`}</td>
                  </tr>
                ); 
            })}
          </tbody>
        </Table>}
        {expense && expense.length === 0 && expensesLoaded && <div className="data-title">No Data Available</div>}
      </Row>
    </div>
  );
};

export default AdminExpensesPage;
