import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "react-bootstrap";
import TextArea from "../../Components/Forms/TextArea";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const AdminWorksheetDetailForm = ({
  worksheetDetail,
  clicked,
  setClicked,
  refresh,
  handleDetailEditedChange
}) => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [worksheetName, setWorksheetName] = useState(worksheetDetail.worksheet_name);

  const onSelectChange = (selector, e) => {
    switch (selector) {
      case "Worksheet Name":
        setWorksheetName(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    const req_obj = {
      worksheet_name: worksheetName,
    };
    if (
      worksheetName !== ""
    ) {
      insert_data(req_obj);
    } else {
      toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
    }
  };

  const insert_data = (req_obj) => {
    axios(
      requestOptions({ method: "POST", url: API_CONSTANTS.UPDATE_WORKSHEET_DETAIL, data: req_obj })
    )
      .then((res) => {
        if (res) {
          toastId.current = toast.success(
            "You’ve successfully updated the worksheet",
            { position: "top-center" }
          );
          handleDetailEditedChange();
        } else {
          toastId.current = toast.error(
            "There was an error updating the worksheet",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error updating the worksheet", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="mt-2">
        <div className="labels mt-3">Worksheet</div>
        <TextArea
          id={"worksheet_name"}
          height={'300px'}
          onChange={(e) => onSelectChange("Worksheet Name", e)}
          value={worksheetName}
        />
        <Button onClick={handleClick} className="worksheet mt-3" style={{ width: "20%" }}>
          SAVE
        </Button>
      </div>
    </>
  );
};

export default AdminWorksheetDetailForm;
