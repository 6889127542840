import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import { clientAction, saleAction, adminAction, filterAction } from "../../Redux/actions";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "../../Components/Forms/DatePicker";
import MindsetMondayForm from "./MindsetMondayForm";
import { Calendar } from "react-date-range";
import {
  clientTypeList,
  commissionOptions,
  intitialOrRenewalOptions,
  originsOfSale,
  paymentSourceList,
  sportOptions,
  takeOrPassOptions
} from "../../Utils/selectConstants";

const SaleForm = (props) => {
  const [clientName, setClientName] = useState(
    props.location.state && props.location.state.prefill
      ? {
          value:
            props.location.state.prefill.client_name +
            " (" +
            props.location.state.prefill.client_id +
            ")",
          label:
            props.location.state.prefill.client_name +
            " (" +
            props.location.state.prefill.client_id +
            ")"
        }
      : { value: "", label: "" }
  );
  const [clientId, setClientId] = useState(
    props.location.state && props.location.state.prefill
      ? props.location.state.prefill.client_name +
          " (" +
          props.location.state.prefill.client_id +
          ")"
      : ""
  );
  const [paymentDate, setPaymentDate] = useState(new Date().toLocaleDateString());
  const [clientType, setClientType] = useState(
    props.location.state && props.location.state.prefill
      ? {
          value: props.location.state.prefill.client_type,
          label: props.location.state.prefill.client_type
        }
      : "Athlete"
  );
  const [initial_or_renewal_selected, setInitialOrRenewalSelected] = useState("");
  const [saleOrigin, setSaleOrigin] = useState("");
  const [eventName, setEventName] = useState("");
  const [programName, setProgramName] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [commission, setCommission] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [registrationFeeCommission, setRegistrationFeeCommission] = useState(0);
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [salesRepList, setSalesRepList] = useState([]);
  const toastId = useRef(null);
  const clientList_db = useSelector((state) => state.adminReducer.allClients);
  const salesRepList_db = useSelector((state) => state.adminReducer.allSalesReps);
  const programList_db = useSelector((state) => state.filterReducer.programList);
  const commission_db = useSelector((state) => state.adminReducer.calculatedCommission);
  const [resetData, setResetData] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [sport, setSport] = useState("");
  const [takeOrPass, setTakeOrPass] = useState("");
  const [customCommission, setCustomCommission] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [percentage, setPercentage] = useState({ value: 0, label: "" });
  const [paymentSource, setPaymentSource] = useState({ value: "", label: "" });
  const [registrationFee, setRegistrationFee] = useState(0);
  const [showToggle, setShowToggle] = useState(false);
  const [showToggle2, setShowToggle2] = useState(false);
  const [customEndDate, setCustomEndDate] = useState(new Date().toLocaleDateString());
  const [form, setForm] = useState("");

  let formVals = {
    eventName: eventName,
    paymentDate: paymentDate,
    originsOfSale: originsOfSale,
    saleOrigin: saleOrigin,
    paymentSource: paymentSource,
    programList: programList,
    salesRep: salesRep,
    commission: commission,
    percentage: percentage,
    programName: programName,
    commissionOptions: commissionOptions,
    salesRepList: salesRepList,
    paymentSourceList: paymentSourceList
  };

  useEffect(() => {
    dispatch(adminAction.resetData());
    dispatch(saleAction.resetData());
    dispatch(
      adminAction.loadAllClients({
        filters: { is_active: ["Active"], clientType: [clientType.value] }
      })
    );
    dispatch(adminAction.loadAllSalesReps({ filters: {} }));
    dispatch(
      adminAction.loadCalculatedCommission({
        filters: {
          coach_name: salesRep.value,
          program_name: programName.value,
          take_or_pass: takeOrPass.value
        }
      })
    );
    dispatch(filterAction.loadProgramList({ filters: { clientType: [clientType.value] } }));
  }, [dispatch, clientType]);

  useEffect(() => {
    dispatch(
      adminAction.loadCalculatedCommission({
        filters: {
          coach_name: salesRep.value,
          program_name: programName.value,
          take_or_pass: takeOrPass.value
        }
      })
    );
  }, [dispatch, percentage]);

  useEffect(() => {
    let c_list_db = clientList_db.map((client) => {
      return {
        value: `${client.full_name} (${client.client_id})`,
        label: `${client.full_name} (${client.client_id})`
      };
    });
    c_list_db.push({ value: "", label: "" });
    setClientList(c_list_db);
  }, [clientList_db]);

  useEffect(() => {
    if (salesRepList_db.length > 0) {
      let sr_list_db = salesRepList_db.map((coach) => {
        return {
          value: `${coach.coach_name} (${coach.coach_id})`,
          label: `${coach.coach_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "", label: "" });
      setSalesRepList(sr_list_db);
    }
  }, [salesRepList_db]);

  useEffect(() => {
    let p_list_db = programList_db.map((program) => {
      return {
        value: `${program.program_name} (${program.program_id})`,
        label: `${program.program_name} (${program.program_id})`
      };
    });
    if (clientType.value == "Mindset Monday") {
      // do nothing
    } else if (clientType.value !== "Team") {
      p_list_db.push({ value: "Payment Owed", label: "Payment Owed" });
    }
    p_list_db.push({ value: "", label: "" });
    setProgramList(p_list_db);
  }, [programList_db]);

  useEffect(() => {
    if (programName.value && !programName.value.includes("Custom")) {
      dispatch(
        adminAction.loadCalculatedCommission({
          filters: {
            coach_name: salesRep.value,
            program_name: programName.value,
            take_or_pass: takeOrPass.value,
            clientType: clientType.value
          }
        })
      );
    }
  }, [salesRep, programName, takeOrPass]);

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  useEffect(() => {
    if (commission_db && percentage.value !== 0) {
      setCommission("$" + round(commission_db.program_price * percentage.value, 4));
    }
  }, [commission_db]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Client Name":
        setClientName(e);
        setClientId(e.value);
        break;
      case "Client Type":
        setClientType(e);
        break;
      case "Initial or Renewal":
        setInitialOrRenewalSelected(e);
        break;
      case "Origin of Sale":
        setSaleOrigin(e);
        break;
      case "Payment Source":
        setPaymentSource(e);
        break;
      case "Event Name":
        setEventName(e.target.value);
        break;
      case "Commission Percentage":
        setPercentage(e);
        break;
      case "Program Name":
        setProgramName(e);
        break;
      case "Sales Person":
        setSalesRep(e);
        break;
      case "Sport":
        setSport(e);
        break;
      case "Take or Pass":
        setTakeOrPass(e);
        break;
      case "Custom Price":
        setCustomPrice(e.target.value);
        break;
      case "Custom Commission":
        setCustomCommission(e.target.value);
        break;
      case "Registration Fee":
        setRegistrationFee(e.target.value);
        break;
      default:
        break;
    }
  };

  function removeFirstChar(str) {
    return str.substring(1);
  }

  useEffect(() => {
    if (commission !== 0 && registrationFee !== 0 && percentage.value && percentage.value !== 0) {
      setRegistrationFeeCommission(registrationFee * percentage.value);
      let total_commission = parseFloat(removeFirstChar(commission)) + parseFloat(registrationFee * percentage.value);
      setTotalCommission(total_commission);
    }

  }, [commission, registrationFee])

  const handleClick = (e) => {
    if (e.target.id == "add") {
      let req_obj;
      if (clientType.value != "Mindset Monday") {
        req_obj = {
          client_name: clientName.value,
          payment_date: paymentDate,
          commission: commission,
          initial_sale_or_renewal: initial_or_renewal_selected.value,
          origin_of_sale: saleOrigin.value,
          event_name: eventName,
          program_name: programName.value,
          coach_name: salesRep.value,
          client_type: clientType.value,
          custom_price: customPrice,
          custom_commission: customCommission,
          sport: sport.value,
          take_or_pass: takeOrPass.value,
          commission_percentage: percentage.value,
          registration_fee: registrationFee,
          payment_source: paymentSource.value,
          custom_end_date: customEndDate
        };
      } else {
        req_obj = {
          payment_date: paymentDate,
          commission: commission,
          origin_of_sale: saleOrigin.value,
          event_name: eventName,
          program_name: programName.value,
          coach_name: salesRep.value,
          client_type: clientType.value,
          custom_commission: customCommission,
          commission_percentage: percentage.value,
          payment_source: paymentSource.value
        };
      }

      if (
        (clientType.value !== "" || clientType.value == "Mindset Monday") &&
        paymentDate !== "" &&
        (eventName !== "") & (saleOrigin.value !== "") &&
        paymentSource.value !== "" &&
        (programName.value !== "") & (salesRep.value !== "")
      ) {
        insert_data(req_obj);
      } else if (
        clientType.value !== "" &&
        paymentDate !== "" &&
        clientName.value !== "" &&
        sport.value !== "" &&
        initial_or_renewal_selected.value !== "" &&
        saleOrigin.value !== "" &&
        programName.value !== "" &&
        salesRep.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        toastId.current = toast.error("Please fill in all required form fields", {
          position: "top-center"
        });
      }
    } else {
      toastId.current = toast.info(
        "You've cleared the form. All fields have been reset to their default values"
      );
      reset_data();
    }
  };

  const reset_data = () => {
    setClientName({ value: "", label: "" });
    setPaymentDate(new Date().toLocaleDateString());
    setClientType("");
    setCommission(0);
    setInitialOrRenewalSelected("");
    setSalesRep("");
    setSaleOrigin("");
    setEventName("");
    setProgramName("");
    setCustomPrice(0);
    setCustomCommission(0);
    setTakeOrPass("");
    setSport("");
    setPaymentSource({ value: "", label: "" });
    setPercentage({ value: 0, label: "" });
    setRegistrationFee(0);
    setCustomEndDate(new Date().toLocaleDateString());
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SALE, data: req_obj }))
      .then((res) => {
        if (res) {
          if (res.data.message === "Success") {
            let client_name = "client_name" in req_obj ? req_obj["client_name"] : req_obj['event_name'];
            toastId.current = toast.success(
              "You’ve successfully inserted a new sale for " + client_name,
              { position: "top-center" }
            );
            reset_data();
          } else if (res.data.message === "Duplicates") {
            toastId.current = toast.warning(
              "You’ve entered info that matches a sale that already exists in the database. Please view the Sale Archive to see the existing sale",
              { position: "top-center" }
            );
          }
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new sale",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting sale data", {
          position: "top-center"
        });
      });
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    setPaymentDate(selectedDate.toLocaleDateString());
  };

  const handleCustomEndDateChange = (e) => {
    const selectedDate = new Date(e);
    setCustomEndDate(selectedDate.toLocaleDateString());
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A SALE" onClick={handleClick}>
        <ReactSelect
          options={clientTypeList}
          value={clientType}
          label="Client Type"
          placeholder={"Client Type"}
          id={"Client Type"}
          handleChange={handleChange}
        />
        {clientType.value == "Mindset Monday" ? (
          <MindsetMondayForm
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            insert_data={insert_data}
            formVals={formVals}
          />
        ) : (
          <>
            <DatePicker date={paymentDate} showToggle={showToggle} setShowToggle={setShowToggle}>
              <Calendar showPreview={false} onChange={(e) => handleDateChange(e)} />
            </DatePicker>
            <ReactSelect
              options={clientList}
              value={clientName}
              label="Client Name"
              placeholder={"Client Name"}
              id={"Client Name"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={sportOptions}
              value={sport}
              placeholder={"Sport"}
              id={"Sport"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={intitialOrRenewalOptions}
              value={initial_or_renewal_selected}
              label="Initial or Renewal"
              placeholder={"Initial or Renewal"}
              id={"Initial or Renewal"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={originsOfSale}
              value={saleOrigin}
              label="Origin of Sale"
              placeholder={"Origin of Sale"}
              id={"Origin of Sale"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={paymentSourceList}
              value={paymentSource}
              label="paymentSource"
              placeholder={"Payment Source"}
              id={"Payment Source"}
              handleChange={handleChange}
            />
            <Input
              value={eventName}
              label="Sale Notes"
              id={"Event Name"}
              onChange={(e) => handleChange("Event Name", e)}
            />
            <ReactSelect
              options={programList}
              value={programName}
              label="Program Name"
              placeholder={"Program Name"}
              id={"Program Name"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={salesRepList}
              value={salesRep}
              label="Sales Person"
              placeholder={"Sales Person"}
              id={"Sales Person"}
              handleChange={handleChange}
            />
            <ReactSelect
              options={takeOrPassOptions}
              value={takeOrPass}
              label="Take or Pass"
              placeholder={"Take or Pass"}
              id={"Take or Pass"}
              handleChange={handleChange}
            />
            <Input
              value={registrationFee}
              label="Registration Fee"
              id={"Registration Fee"}
              onChange={(e) => handleChange("Registration Fee", e)}
            />
            {!(programName.value && programName.value.includes("Custom")) ? (
              <Input
                value={commission}
                label="Commission"
                id={"Commission"}
                onChange={handleChange}
                disabled={true}
              />
            ) : (
              <Input
                value={customPrice}
                label="Custom Price"
                id={"Custom Price"}
                onChange={(e) => handleChange("Custom Price", e)}
              />
            )}
            {!(programName.value && programName.value.includes("Custom")) && (
              <Input
                value={registrationFeeCommission}
                label="Registration Fee Commission"
                id={"Registration Fee Commission"}
                disabled={true}
              />)}
            {!(programName.value && programName.value.includes("Custom")) ? (
              <ReactSelect
                options={commissionOptions}
                value={percentage}
                placeholder={"Commission Percentage"}
                label="Commission Percentage"
                id={"Commission Percentage"}
                handleChange={handleChange}
              />
            ) : (
              <Input
                value={customCommission}
                label="Custom Commission"
                id={"Custom Commission"}
                onChange={(e) => handleChange("Custom Commission", e)}
              />
            )}
            {!(programName.value && programName.value.includes("Custom")) && (
              <Input
                value={totalCommission}
                label={"Total Commission"}
                id={"Total Commission"}
                disabled={true}
              />
            )}
            {(programName.value && (programName.value.includes('Custom PIF') || programName.value.includes('Custom Team'))) && (
              <DatePicker date={customEndDate} showToggle={showToggle2} setShowToggle={setShowToggle2} title='CUSTOM END DATE'>
                <Calendar showPreview={false} onChange={(e) => handleCustomEndDateChange(e)} />
              </DatePicker>
            )} 
          </>
        )}
      </FormContainer>
    </>
  );
};

export default SaleForm;
