import React, { useState, useRef } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_CONSTANTS from "../../Redux/constants";

import NewAthleteForm from "../../Components/Forms/NewAthleteForm";
import TeamForm from "../../Components/Forms/TeamForm";
import CoachForm from "../../Components/Forms/CoachForm";
import CorporateForm from "../../Components/Forms/CorporateForm";

import { signupTypeList } from "../../Utils/selectConstants";

export const Signup = ({ admin = false }) => {
  const toastId = useRef(null);
  const [registrationKey, setRegistrationKey] = useState("");
  const [registrationKeyVerified, setRegistrationKeyVerified] = useState(false);
  const [clientType, setClientType] = useState({ value: "", label: "" });
  const [signupTypeOptions, setSignupTypeOptions] = useState(signupTypeList);
  const [verifyClicks, setVerifyClicks] = useState(0);
  const [verifySubmittable, setVerifySubmittable] = useState(true);

  // Shared fields between all Client Types
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [preferredSessionAvailability, setPreferredSessionAvailability] = useState("");

  // Shared fields between Athletes and Coaches
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState({ value: "", label: "" });
  const [dateOfBirth, setDateOfBirth] = useState("");

  // Shared fields between Athletes and Teams
  const [trainingLevel, setTrainingLevel] = useState({ value: "", label: "" });
  const [rpName, setRpName] = useState("");
  const [rpPhone, setRpPhone] = useState("");
  const [rpEmail, setRpEmail] = useState("");
  const [rpRelation, setRpRelation] = useState("");
  const [goals, setGoals] = useState("");
  const [aspirations, setAspirations] = useState("");
  const [salesSource, setSalesSource] = useState({ value: "", label: "" });
  const [salesSourceDetailsLabel, setSalesSourceDetailsLabel] = useState("");
  const [salesSourceDetails, setSalesSourceDetails] = useState("");
  const [faithBased, setFaithBased] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  // Athlete-specific fields
  const [nickname, setNickname] = useState("");
  const [experience, setExperience] = useState({ value: "", label: "" });
  const [personalityType, setPersonalityType] = useState({ value: "", label: "" });
  const [lessonsImportance, setLessonsImportance] = useState({ value: "", label: "" });
  const [rpInvolvement, setRpInvolvement] = useState({ value: "", label: "" });
  const [sports, setSports] = useState([]);
  const [energyLevelPreference, setEnergyLevelPreference] = useState({ value: "", label: "" });
  const [desiredCoachExperienceLevel, setDesiredCoachExperienceLevel] = useState({
    value: "",
    label: ""
  });
  const [gender, setGender] = useState({ value: "", label: "" });
  const [school, setSchool] = useState("");
  const [club, setClub] = useState("");

  // Team-specific fields
  const [teamName, setTeamName] = useState("");
  const [teamExperienceLevel, setTeamExperienceLevel] = useState("");

  // Corporate-specific fields
  const [profession, setProfession] = useState("");

  // Coach-specific fields
  const [sport, setSport] = useState("");
  const [timezone, setTimeZone] = useState({ value: "", label: "" });
  const [weekdays, setWeekdays] = useState([]);
  const [timeofday, setTimeOfDay] = useState([]);
  const [areaOfStudy, setAreaOfStudy] = useState("");
  const [comfortableWithFaith, setComfortableWithFaith] = useState({ value: "", label: "" });
  const [preferredClientType, setPreferredClientType] = useState([]);
  const [weeklySessions, setWeeklySessions] = useState({ value: "", label: "" });
  const [preferredTrainingLevel, setPreferredTrainingLevel] = useState([]);
  const [fullTimeJob, setFullTimeJob] = useState("");
  const [highestLevelCompetedAt, setHighestLevelCompetedAt] = useState({ value: "", label: "" });
  const [schoolTeamCompetedFor, setSchoolTeamCompetedFor] = useState("");

  const requestOptions = (reqObj) => ({
    method: reqObj.method,
    url: reqObj.url,
    data: reqObj.data,
    headers: {
      Authorization: window.location.toString().split("key=")[1],
      "Content-Type": "application/json"
    },
    cancelToken: reqObj.token ? reqObj.token : ""
  });
  const insert_data = (req_obj) => {
    if (
      clientType.value == "Team" ||
      clientType.value === "Corporate"
    ) {
      axios(requestOptions({ method: "POST", url: API_CONSTANTS.ADD_CLIENT_NEW, data: req_obj }))
        .then((res) => {
          if (res.data.status == 'Success') {
            toastId.current = toast.success(
              res.data.message,
              { position: "top-center" }
            );
            reset_data();
          } else {
            toastId.current = toast.error(res.data.message, {
              position: "top-center"
            });
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting form data. Please contact the Winning Mindset team for assistance.", {
            position: "top-center"
          });
        });
    } else if (clientType.value == "Mindset Coach") {
      axios(requestOptions({ method: "POST", url: API_CONSTANTS.ADD_COACH_NEW, data: req_obj }))
        .then((res) => {
          if (res) {
            if (res.data.message === "Success") {
              toastId.current = toast.success("Successfully signed up as a new coach", {
                position: "top-center"
              });
              reset_data();
            } else if (res.data.message === "Duplicates") {
              toastId.current = toast.warning(
                "Entered info matches a coach that already exists in the database. Please contact an admin.",
                { position: "top-center" }
              );
            }
          } else {
            toastId.current = toast.error("There was an error signing up as a new coach", {
              position: "top-center"
            });
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting new coach data", {
            position: "top-center"
          });
        });
    }
  };

  const reset_data = () => {
    setFirstName("");
    setLastName("");
    setTeamName("");
    setNickname("");
    setGender({ value: "", label: "" });
    setEmail("");
    setPhone("");
    setAddress("");
    setState({ value: "", label: "" });
    setExperience({ value: "", label: "" });
    setSchool("");
    setClub("");
    setPersonalityType({ value: "", label: "" });
    setFaithBased({ value: "", label: "" });
    setGoals("");
    setAspirations("");
    setAdditionalInfo("");
    setLessonsImportance({ value: "", label: "" });
    setPreferredSessionAvailability("");
    setDateOfBirth("");
    setRpName("");
    setRpPhone("");
    setRpEmail("");
    setRpRelation("");
    setRpInvolvement({ value: "", label: "" });
    setSports([]);
    setEnergyLevelPreference({ value: "", label: "" });
    setSalesSource({ value: "", label: "" });
    setSalesSourceDetails("");
    setDesiredCoachExperienceLevel({ value: "", label: "" });
    setCity("");
    setSport("");
    setTimeZone({ value: "", label: "" });
    setTimeOfDay("");
    setWeekdays("");
    setWeeklySessions({ value: "", label: "" });
    setPreferredClientType("");
    setComfortableWithFaith({ value: "", label: "" });
    setAreaOfStudy("");
    setPreferredTrainingLevel("");
    setTeamExperienceLevel("");
    setFullTimeJob("");
    setHighestLevelCompetedAt({ value: "", label: "" });
    setSchoolTeamCompetedFor("");
    setProfession("");
  };

  const verifyClick = (e) => {
    if (verifySubmittable === false) {
      return;
    }
    setVerifyClicks(verifyClicks + 1);
    if (verifyClicks > 10) {
      setVerifySubmittable(false);
      toastId.current = toast.error(
        "Too many invalid attempts at providing a registration key. Please try again later.",
        { position: "top-center" }
      );
      setTimeout(() => {
        setVerifySubmittable(true);
        setVerifyClicks(0);
      }, "600000");
    }
    if (registrationKey !== "") {
      axios(
        requestOptions({
          method: "POST",
          url: API_CONSTANTS.VERIFY_REGISTRATION_KEY,
          data: { registration_key: registrationKey }
        })
      )
        .then((res) => {
          if (res) {
            if (res["data"]["status"] == true) {
              if (res["data"]["key_type"] === "coach") {
                setSignupTypeOptions([signupTypeList[0], signupTypeList[4]]);
              } else if (res["data"]["key_type"] === "client") {
                setSignupTypeOptions([
                  signupTypeList[0],
                  signupTypeList[1],
                  signupTypeList[2],
                  signupTypeList[3]
                ]);
              }
              setRegistrationKeyVerified(true);
            } else {
              toastId.current = toast.error("The provided registration key is not valid", {
                position: "top-center"
              });
              setRegistrationKeyVerified(false);
            }
          } else {
            setRegistrationKeyVerified(false);
          }
        })
        .catch((err) => {
          setRegistrationKeyVerified(false);
        });
    }
  };

  const test = (str) => /^[A-Za-z0-9_ -]+$/.test(str);

  const prepareAddClientRequest = () => {
    const req_obj = {
      is_active: 1,
      client_type: clientType.value,
      first_name: firstName,
      last_name: lastName,
      team_name: teamName,
      nickname: nickname,
      gender: gender.value,
      email: email,
      phone: phone,
      address: address,
      city: city,
      address_state: state.value,
      training_level: trainingLevel.value,
      school: school,
      club: club,
      experience: experience.value,
      personality_type: personalityType.value,
      date_of_birth: dateOfBirth,
      responsible_person_name: rpName,
      responsible_person_phone: rpPhone,
      responsible_person_email: rpEmail,
      responsible_person_relationship: rpRelation,
      responsible_person_involvement: rpInvolvement.value,
      faith_based: faithBased.value,
      goals: goals,
      other_aspirations: aspirations,
      additional_info: additionalInfo,
      energy_level_preference: energyLevelPreference.value,
      desired_experience_of_coach: desiredCoachExperienceLevel.value,
      sports: sports.map((data) => data.value),
      importance_of_lessons: lessonsImportance.value,
      sales_source: salesSource.value,
      sales_source_details: salesSourceDetails,
      profession: profession,
      primary_time_zone: timezone.value,
      preferred_session_availability: preferredSessionAvailability
    };
    if (
      (clientType.value === "Athlete" || clientType.value === "Corporate") &&
      ((firstName !== "" && !test(firstName)) || !test(lastName))
    ) {
      toastId.current = toast.error(
        "Please remove special characters from first and/or last name field(s)",
        { position: "top-center" }
      );
    } else if (clientType.value === "Team" && !test(teamName)) {
      toastId.current = toast.error("Please remove special characters from team name field(s)", {
        position: "top-center"
      });
    } else if (
      (clientType.value === "Team" &&
        teamName !== "" &&
        address !== "" &&
        state !== "" &&
        trainingLevel.value !== "" &&
        rpName !== "" &&
        rpEmail !== "" &&
        rpPhone !== "" &&
        rpRelation !== "") ||
      (clientType.value === "Corporate" &&
        firstName !== "" &&
        lastName !== "" &&
        address !== "" &&
        state.value !== "" &&
        experience.value !== "" &&
        faithBased.value !== "" &&
        energyLevelPreference.value !== "" &&
        desiredCoachExperienceLevel.value !== "" &&
        lessonsImportance.value !== "" &&
        profession !== "")
    ) {
      insert_data(req_obj);
    } else {
      toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
    }
  };

  const prepareAddCoachRequest = () => {
    const req_obj = {
      is_active: 1,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      address: address,
      city: city,
      address_state: state.value,
      date_of_birth: dateOfBirth,
      sport_specialty: sports.map((data) => data.value),
      primary_time_zone: timezone.value,
      dow_availability: weekdays.map((data) => data.value),
      tod_availability: timeofday.map((data) => data.value),
      desired_weekly_sessions: weeklySessions.value,
      athlete_team_preference: preferredClientType.map((data) => data.value),
      is_religious: comfortableWithFaith.value,
      client_preference_rank: preferredTrainingLevel.map((data) => data.value),
      eligible_for_sales: 0,
      area_of_study: areaOfStudy,
      full_time_job: fullTimeJob,
      highest_level_of_competition: highestLevelCompetedAt.value,
      school_competed_for: schoolTeamCompetedFor
    };
    if (!test(firstName) || !test(lastName)) {
      toastId.current = toast.error(
        "Please remove special characters from first and/or last name field(s)",
        { position: "top-center" }
      );
    } else if (
      clientType.value === "Mindset Coach" &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      weekdays !== "" &&
      timeofday !== ""
    ) {
      insert_data(req_obj);
      reset_data();
    } else {
      toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      if (
        clientType.value === "Athlete" ||
        clientType.value === "Team" ||
        clientType.value === "Corporate"
      ) {
        prepareAddClientRequest();
      }
    } else if (clientType.value === "Mindset Coach") {
      prepareAddCoachRequest();
    } else {
      reset_data();
    }
  };

  const handleChange = (selector, e, type = false) => {
    switch (selector) {
      case "Registration Type":
        setClientType(e);
        break;
      case "First Name":
        setFirstName(e.target.value);
        break;
      case "Last Name":
        setLastName(e.target.value);
        break;
      case "Nickname":
        setNickname(e.target.value);
        break;
      case "Gender":
        setGender(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Team Name":
        setTeamName(e.target.value);
        break;
      case "Email":
        setEmail(e.target.value);
        break;
      case "Phone":
        setPhone(e.target.value);
        break;
      case "Street Address":
        setAddress(e.target.value);
        break;
      case "School/Team Street Address":
        setAddress(e.target.value);
        break;
      case "State":
        setState(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Training Level":
        setTrainingLevel(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "School Name":
        setSchool(e.target.value);
        break;
      case "Club Name(s)":
        setClub(e.target.value);
        break;
      case "Experience":
        setExperience(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "How experienced is your team?":
        setTeamExperienceLevel(e.target.value);
        break;
      case "Job Experience":
        setExperience(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Would you like to incorporate the Christian faith into your training?":
        setFaithBased(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Goals":
        setGoals(e.target.value);
        break;
      case "Team Goals":
        setGoals(e.target.value);
        break;
      case "Personality Type":
        setPersonalityType(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Aspirations outside of athletics":
        setAspirations(e.target.value);
        break;
      case "Aspirations outside of career":
        setAspirations(e.target.value);
        break;
      case "Primary Sport(s)":
        setSports(e);
        break;
      case "Profession":
        setProfession(e.target.value);
        break;
      case "Other Relevant Information":
        setAspirations(e.target.value);
        break;
      case "Additional info that will help match you with a Mindset Coach":
        setAdditionalInfo(e.target.value);
        break;
      case "Importance of learning life lessons in the program":
        setLessonsImportance(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Date of Birth":
        setDateOfBirth(e.target.value);
        break;
      case "Responsible Person Name":
        setRpName(e.target.value);
        break;
      case "Responsible Person Phone":
        setRpPhone(e.target.value);
        break;
      case "Responsible Person Email":
        setRpEmail(e.target.value);
        break;
      case "Responsible Person Relationship":
        setRpRelation(e.target.value);
        break;
      case "Responsible Person Involvement":
        setRpInvolvement(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "How did you hear about Winning Mindset?":
        setSalesSource(type ? e : { value: e.target.value, label: e.target.value });
        setSalesSourceDetailsLabel("Please specify the name of the " + e.value);
        break;
      case "Do you prefer a Mindset Coach with low or high energy?":
        setEnergyLevelPreference(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Desired age range of assigned Mindset Coach":
        setDesiredCoachExperienceLevel(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Please specify the name of the Team/Club Presentation":
        setSalesSourceDetails(e.target.value);
        break;
      case "Please specify the name of the Convention":
        setSalesSourceDetails(e.target.value);
        break;
      case "Please specify the name of the Referral":
        setSalesSourceDetails(e.target.value);
        break;
      case "Please specify the name of the Social Media":
        setSalesSourceDetails(e.target.value);
        break;
      case "Please specify the name of the Tournament":
        setSalesSourceDetails(e.target.value);
        break;
      case "Please specify the name of the Mindset Coach":
        setSalesSourceDetails(e.target.value);
        break;
      case "Area of Study/Background":
        setAreaOfStudy(e.target.value);
        break;
      case "Full-time Job":
        setFullTimeJob(e.target.value);
        break;
      case "Highest level competed at?":
        setHighestLevelCompetedAt(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "What school/team did you compete for?":
        setSchoolTeamCompetedFor(e.target.value);
        break;
      case "Sport Specialty":
        setSports(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Primary Time Zone":
        setTimeZone(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Available Days":
        setWeekdays(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Available Time of Day":
        setTimeOfDay(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?":
        setPreferredSessionAvailability(e.target.value);
        break;
      case "Desired Number of Weekly Sessions":
        setWeeklySessions(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Preferred Client Type":
        setPreferredClientType(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Are you equipped to incorporate the Christian faith into mindset training?":
        setComfortableWithFaith(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "Preferred Training Level":
        setPreferredTrainingLevel(type ? e : { value: e.target.value, label: e.target.value });
        break;
      case "City/Town":
        setCity(e.target.value);
        break;
      case "Registration Key":
        setRegistrationKey(e.target.value);
        break;
    }
  };

  if (!registrationKeyVerified && admin === false) {
    return (
      <>
        <ToastContainer limit={1} theme="dark" />
        <div className="signup">
          <div className="main-container mt-5">
            <p className="form-title">SIGN UP</p>
            <div className="form-container">
              <div className="item">
                <Input
                  label="Please enter your registration key"
                  type="text"
                  value={registrationKey}
                  onChange={(e) => handleChange("Registration Key", e)}
                />
              </div>
              <Button id={"auth"} className="add mt-3 m-auto" onClick={verifyClick}>
                VERIFY
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (clientType.value === "" && admin === false) {
    return (
      <div className="signup">
        <div className="client-type-container mt-5">
          <p className="form-title">SIGN UP</p>
          <div className="form-container">
            <div className="item">
              <ReactSelect
                options={signupTypeOptions}
                value={clientType}
                placeholder="Registration Type"
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ToastContainer limit={1} theme="dark" />
        <div className="signup">
          {clientType.value == "Athlete" && (
            <>
              <FormContainer buttons={false}>
                <ReactSelect
                  options={signupTypeOptions}
                  value={clientType}
                  placeholder="Registration Type"
                  handleChange={handleChange}
                />
                <NewAthleteForm
                  id={"new_athlete_form"}
                  clientType={clientType}
                  firstName={firstName}
                  lastName={lastName}
                  nickname={nickname}
                  gender={gender}
                  email={email}
                  phone={phone}
                  address={address}
                  city={city}
                  state={state}
                  trainingLevel={trainingLevel}
                  school={school}
                  club={club}
                  experience={experience}
                  personalityType={personalityType}
                  faithBased={faithBased}
                  goals={goals}
                  aspirations={aspirations}
                  additionalInfo={additionalInfo}
                  lessonsImportance={lessonsImportance}
                  dateOfBirth={dateOfBirth}
                  rpName={rpName}
                  rpPhone={rpPhone}
                  rpEmail={rpEmail}
                  rpRelation={rpRelation}
                  rpInvolvement={rpInvolvement}
                  sports={sports}
                  salesSource={salesSource}
                  salesSourceDetailsLabel={salesSourceDetailsLabel}
                  salesSourceDetails={salesSourceDetails}
                  energyLevelPreference={energyLevelPreference}
                  desiredCoachExperienceLevel={desiredCoachExperienceLevel}
                  timezone={timezone}
                  preferredSessionAvailability={preferredSessionAvailability}
                  handleChange={handleChange}
                  teamName={teamName}
                  profession={profession}
                  reset_data={reset_data}
                  setClientType={setClientType}
                />
              </FormContainer>
            </>
          )}
          {clientType.value == "Team" && (
            <FormContainer onClick={handleClick} className="active" title="SIGN UP" admin={admin}>
              <ReactSelect
                options={signupTypeOptions}
                value={clientType}
                placeholder="Registration Type"
                handleChange={handleChange}
              />
              <TeamForm
                id={"team_form"}
                teamName={teamName}
                sports={sports}
                address={address}
                city={city}
                state={state}
                trainingLevel={trainingLevel}
                teamExperienceLevel={teamExperienceLevel}
                goals={goals}
                aspirations={aspirations}
                faithBased={faithBased}
                additionalInfo={additionalInfo}
                rpName={rpName}
                rpPhone={rpPhone}
                rpEmail={rpEmail}
                rpRelation={rpRelation}
                salesSource={salesSource}
                salesSourceDetailsLabel={salesSourceDetailsLabel}
                salesSourceDetails={salesSourceDetails}
                preferredSessionAvailability={preferredSessionAvailability}
                handleChange={handleChange}
              />
            </FormContainer>
          )}
          {clientType.value == "Corporate" && (
            <FormContainer onClick={handleClick} className="active" title="SIGN UP" admin={admin}>
              <ReactSelect
                options={signupTypeOptions}
                value={clientType}
                placeholder="Registration Type"
                handleChange={handleChange}
              />
              <CorporateForm
                id={"corp_form"}
                firstName={firstName}
                lastName={lastName}
                nickname={nickname}
                gender={gender}
                email={email}
                phone={phone}
                address={address}
                city={city}
                state={state}
                experience={experience}
                personalityType={personalityType}
                faithBased={faithBased}
                goals={goals}
                aspirations={aspirations}
                additionalInfo={additionalInfo}
                lessonsImportance={lessonsImportance}
                dateOfBirth={dateOfBirth}
                rpName={rpName}
                rpPhone={rpPhone}
                rpEmail={rpEmail}
                rpRelation={rpRelation}
                profession={profession}
                salesSource={salesSource}
                salesSourceDetailsLabel={salesSourceDetailsLabel}
                salesSourceDetails={salesSourceDetails}
                energyLevelPreference={energyLevelPreference}
                desiredCoachExperienceLevel={desiredCoachExperienceLevel}
                preferredSessionAvailability={preferredSessionAvailability}
                handleChange={handleChange}
              />
            </FormContainer>
          )}
          {clientType.value == "Mindset Coach" && (
            <FormContainer onClick={handleClick} className="active" title="SIGN UP" admin={admin}>
              <ReactSelect
                options={signupTypeOptions}
                value={clientType}
                placeholder="Registration Type"
                handleChange={handleChange}
              />
              <CoachForm
                id={"coach_form"}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                address={address}
                city={city}
                state={state}
                dateOfBirth={dateOfBirth}
                sport={sports}
                timezone={timezone}
                weekdays={weekdays}
                timeofday={timeofday}
                weeklySessions={weeklySessions}
                preferredClientType={preferredClientType}
                comfortableWithFaith={comfortableWithFaith}
                preferredTrainingLevel={preferredTrainingLevel}
                areaOfStudy={areaOfStudy}
                fullTimeJob={fullTimeJob}
                highestLevelCompetedAt={highestLevelCompetedAt}
                schoolTeamCompetedFor={schoolTeamCompetedFor}
                handleChange={handleChange}
              />
            </FormContainer>
          )}
        </div>
      </>
    );
  }
};

export default Signup;
