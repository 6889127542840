import { Form, Col } from "react-bootstrap";
import React from "react";
import FormSelect from "./FormSelect.js";

const FormGroup = ({
  label,
  type,
  error_message,
  defaultVal,
  placeholder,
  rows,
  value,
  as,
  required,
  handleChange,
  options,
  selector,
  pattern
}) => {
  return (
    <Form.Group as={Col} controlId="validationCustom01">
      <Form.Label style={{ color: "white" }}>{label}</Form.Label>
      {type == "select" ? (
        <FormSelect
          defaultVal={defaultVal}
          options={options}
          required={required}
          selector={selector}
          value={value}
          handleChange={handleChange}></FormSelect>
      ) : (
        <Form.Control
          pattern={pattern}
          required={required}
          type={type}
          as={as}
          placeholder={placeholder}
          rows={rows}
          value={value}
          onChange={(e) => {
            handleChange(label, e);
          }}
        />
      )}
      <Form.Control.Feedback type="invalid">{error_message}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormGroup;
