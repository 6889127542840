import React, { useState, useEffect } from "react";
import { Image, Button, Accordion, NavDropdown, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { List } from "react-bootstrap-icons";
import { useMediaQuery } from "beautiful-react-hooks";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Logo from "../assets/wm-logo.jpg";
import { authAction } from "../Redux/actions";

const Navbar = ({ open }) => {
  const dispatch = useDispatch();
  const [adminClicked, setAdminClicked] = useState(false);
  const match = useMediaQuery("(min-width: 992px");
  const userInfo = useSelector((state) => state.authReducer.persona);
  const [admin, setAdmin] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();

  const logout = async () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login`
    });
  };
  useEffect(() => {
    if (userInfo) {
      setAdmin(userInfo.is_admin);
    }
  }, [userInfo]);

  useEffect(() => {
    //dispatch(coachDashboardAction.resetData())
    if (authState && authState.isAuthenticated) {
      dispatch(authAction.loadPersonaData({ filters: {} }));
    }
  }, [dispatch, authState]);

  const handleClick = () => {
    // console.log("here")
    setAdminClicked(!adminClicked);
  };

  return (
    <>
      {!match ? (
        <div className="navigation" style={{ padding: "1.5rem 1.5rem 3rem 1rem" }}>
          <List size={35} onClick={open} color="white" />
        </div>
      ) : (
        <ul className="navigation-desktop">
          <Link to="/" className="link">
            <Image src={Logo} className="wm-logo-desktop" />
            <p>CLIENT HUB</p>
          </Link>

          <Link to="/clients" className="link" id="my_clients">
            <li>CLIENTS</li>
          </Link>
          <NavDropdown title="SESSIONS" className="dropdown-link">
            <NavLink as={Link} eventKey="1" title="Item" to="/sessions">
              ALL SESSIONS
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="2" title="Item" to="/sessions_by_client">
              SESSIONS BY CLIENT
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="3" title="Item" to="/events">
              EVENTS/LOG AN EVENT
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="4" title="Item" to="/reimbursements">
              REIMBURSEMENTS
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="5" title="Item" to="missed_sessions">
              MISSED SESSIONS
            </NavLink>
          </NavDropdown>
          <NavDropdown title="PAYMENTS" className="dropdown-link">
            <NavLink as={Link} eventKey="1" title="Item" to="/sales">
              MY SALES
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="2" title="Item" to="/sales_hours_list">
              SALES HOURS LOGGED
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="3" title="Item" to="/payment">
              MY PAYMENTS
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="4" title="Item" to="/monthly_payments">
              MONTHLY PAYMENT BREAKDOWN
            </NavLink>
            <NavDropdown.Divider />
            <NavLink
              as={Link}
              eventKey="5"
              to="/my_expiring_subscriptions"
              onClick={handleClick}>
              EXPIRING PAID IN FULLS
            </NavLink>
          </NavDropdown>
          <NavDropdown title="COACHES" className="dropdown-link">
            <NavLink as={Link} eventKey="1" title="Item" to="/coach_detail">
              MY PROFILE
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="2" title="Item" to="/blogs">
              MY BLOGS
            </NavLink>
            <NavDropdown.Divider />
            <NavLink as={Link} eventKey="3" title="Item" to="/miscellaneous_time_list">
              MY MISCELLANEOUS TIME
            </NavLink>
          </NavDropdown>
          <Link to="/resources" className="link">
            <li>RESOURCES</li>
          </Link>
          {admin === "1" && (
            <Button className="logout" onClick={handleClick}>
              <li>ADMIN</li>
            </Button>
          )}
          {adminClicked && (
            <div className="nav-admin-container">
              <ul className="nav-admin">
                <NavDropdown title="CLIENTS" className="dropdown-link-admin">
                  <NavLink as={Link} eventKey="1" to="/all_clients" onClick={handleClick}>
                    ALL CLIENTS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="2" to="/admin_client_assignments" onClick={handleClick}>
                    CLIENT ASSIGNMENTS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="5" to="/clients_by_state" onClick={handleClick}>
                    CLIENTS BY STATE
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="6"
                    to="/admin_session_inactivity"
                    onClick={handleClick}>
                    SESSION INACTIVITY
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="6" to="/add_client" onClick={handleClick}>
                    ADD CLIENT
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="COACHES" className="dropdown-link-admin">
                  <NavLink as={Link} eventKey="1" to="/all_coaches" onClick={handleClick}>
                    ALL COACHES
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="1" to="/clients_by_coach" onClick={handleClick}>
                    CLIENTS BY COACH
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="2" to="/add_coach" onClick={handleClick}>
                    ADD COACH
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="PAYMENTS" className="dropdown-link-admin">
                  <NavLink as={Link} eventKey="2" title="Item" to="/expenses" onClick={handleClick}>
                    EXPENSES (PAYROLL EXPENSES)
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="1"
                    to="/admin_payment_adjustments"
                    onClick={handleClick}>
                    PAYMENT ADJUSTMENTS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="1"
                    to="/add_payment_adjustment"
                    onClick={handleClick}>
                    LOG A PAYMENT ADJUSTMENT
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="3" to="/admin_reimbursements" onClick={handleClick}>
                    REIMBURSEMENTS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="3" to="/admin_miscellaneous_time_list" onClick={handleClick}>
                    MISCELLANEOUS TIME
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="SESSIONS" className="dropdown-link-admin">
                  <NavLink
                    as={Link}
                    eventKey="1"
                    to="/admin_sessions_by_coach"
                    onClick={handleClick}>
                    SESSIONS BY COACH
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="2" to="/admin_clients_detail" onClick={handleClick}>
                    SESSIONS BY CLIENT
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="4"
                    to="/admin_unlogged_sessions"
                    onClick={handleClick}>
                    UNLOGGED SESSIONS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="5"
                    to="/admin_missed_sessions"
                    onClick={handleClick}>
                    MISSED SESSIONS BY COACH
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="2"
                    to="/admin_events_by_coach"
                    onClick={handleClick}>
                    EVENTS BY COACH
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="SALES" className="dropdown-link-admin">
                  <NavLink as={Link} eventKey="1" to="/admin_sales_by_coach" onClick={handleClick}>
                    SALES BY COACH
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="3" title="Item" to="/add_sale" onClick={handleClick}>
                    LOG A SALE
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="5"
                    to="/admin_expiring_subscriptions"
                    onClick={handleClick}>
                    EXPIRING PAID IN FULLS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="1" title="Item" to="/admin_sales_hours_list">
                    ADMIN SALES HOURS
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="ANALYTICS" className="dropdown-link-admin">
                  <NavLink as={Link} eventKey="4" to="/admin_assignments_by_length" onClick={handleClick}>
                    ASSIGNMENTS BY LENGTH
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="1"
                    title="Item"
                    to="/payroll_breakdown"
                    onClick={handleClick}>
                    REVENUE & PAYROLL BREAKDOWN
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="2"
                    title="Item"
                    to="/revenue_by_state"
                    onClick={handleClick}>
                    REVENUE BY STATE
                  </NavLink>
                </NavDropdown>
                <NavDropdown title="MANAGEMENT" className="dropdown-link-admin">
                  <NavLink
                    as={Link}
                    eventKey="2"
                    title="Item"
                    to="/admin_monthly_payments"
                    onClick={handleClick}>
                    MONTHLY PAYMENTS
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="4" to="/program_management" onClick={handleClick}>
                    PROGRAM MANAGEMENT/PROFIT PER PROGRAM
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink
                    as={Link}
                    eventKey="6"
                    to="/session_commission_list"
                    onClick={handleClick}>
                    SESSION COMMISSION MANAGEMENT
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="5" to="/sales_hours_commissions" onClick={handleClick}>
                    SALES HOURS COMMISSION MANAGEMENT
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="5" to="/sales_commission_list" onClick={handleClick}>
                    SALES COMMISSION MANAGEMENT
                  </NavLink>
                  <NavDropdown.Divider />
                  <NavLink as={Link} eventKey="6" to="/worksheet_list" onClick={handleClick}>
                    WORKSHEET MANAGEMENT
                  </NavLink>
                </NavDropdown>
                <Button className="admin-button" onClick={handleClick}>
                  BACK
                </Button>
              </ul>
            </div>
          )}
          <Button className="logout" onClick={logout}>
            <li>LOGOUT</li>
          </Button>
        </ul>
      )}
    </>
  );
};

export default Navbar;
