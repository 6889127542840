import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import "./AdminWorksheetDetail.scss";
import AdminWorksheetDetailForm from "./AdminWorksheetDetailForm";
import { sessionAction, adminAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AdminWorksheetDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const worksheetDetail = useSelector((state) => state.sessionReducer.sessionWorksheetDetail);
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    dispatch(
    sessionAction.loadSessionWorksheetDetail({ filters: { worksheet_id: props.location.state.worksheet_id } })
    );
  }, [detailEdited, dispatch]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    dispatch(
    sessionAction.loadSessionWorksheetDetail({ filters: { worksheet_id: props.location.state.worksheet_id } })
    );
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  };

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(
      requestOptions({
        method: "POST",
        url: API_CONSTANTS.DELETE_WORKSHEET,
        data: { worksheet_id: worksheetDetail.worksheet_id }
      })
    )
      .then((res) => {
        if (res) {
          history.push("/worksheet_list");
        } else {
          toastId.current = toast.error(
            "There was an error deleting the worksheet",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the worksheet", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <div className="session-title">WORKSHEET DETAIL</div>
      <div className="session-container">
        <div className="items">
          <div className="top-container">
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <AdminWorksheetDetailForm
              worksheetDetail={worksheetDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
          ) : (
            <>
              <div className="labels mt-3">Worksheet</div>
              <div className="mt-3">{worksheetDetail.worksheet_name}</div>
              <div style={{height: '36px'}}></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminWorksheetDetail;
