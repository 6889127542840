import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Logo from "../assets/wm-logo.jpg";
import { Accordion } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";

const AdminSidebar = ({ setAdminClick, adminClick, open, setOpen, handleClick }) => {
  const handleOpen = () => {
    setAdminClick(!adminClick);
    setOpen(!open);
  };
  return (
    <div className="sidebar admin" style={{ display: adminClick ? "" : "none" }}>
      <ArrowLeft className="close" size={40} onClick={handleClick} />
      <Image src={Logo} className="wm-logo" />
      <ul className="nav-container">
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={1}>
            <Accordion.Header>CLIENTS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/all_clients" className="link" onClick={handleOpen}>
                <li>ALL CLIENTS</li>
              </Link>
              <hr/>
              <Link to="/admin_client_assignments" className="link" onClick={handleOpen}>
                <li>CLIENT ASSIGNMENTS</li>
              </Link>
              <hr/>
              <Link to="/clients_by_state" className="link" onClick={handleOpen}>
                <li>CLIENTS BY STATE</li>
              </Link>
              <hr/>
              <Link to="/admin_session_inactivity" className="link" onClick={handleOpen}>
                <li>SESSION INACTIVITY</li>
              </Link>
              <hr/>
              <Link to="/add_client" className="link" onClick={handleOpen}>
                <li>ADD CLIENT</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={2}>
            <Accordion.Header>COACHES</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/all_coaches" className="link" onClick={handleOpen}>
                <li>ALL COACHES</li>
              </Link>
              <hr/>
              <Link to="/clients_by_coach" className="link" onClick={handleOpen}>
                <li>CLIENTS BY COACH</li>
              </Link>
              <hr/>
              <Link to="/add_coach" className="link" onClick={handleOpen}>
                <li>ADD COACH</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={3}>
            <Accordion.Header>PAYMENTS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/expenses" className="link" onClick={handleOpen}>
                <li>EXPENSES</li>
              </Link>
              <hr/>
              <Link to="/admin_payment_adjustments" className="link" onClick={handleOpen}>
                <li>PAYMENT ADJUSTMENTS</li>
              </Link>
              <hr/>
              <Link to="/add_payment_adjustment" className="link" onClick={handleOpen}>
                <li>LOG A PAYMENT ADJUSTMENT</li>
              </Link>
              <hr/>
              <Link to="/admin_reimbursements" className="link" onClick={handleOpen}>
                <li>REIMBURSEMENTS</li>
              </Link>
              <hr/>
              <Link to="/admin_miscellaneous_time_list" className="link" onClick={handleOpen}>
                <li>MISCELLANEOUS TIME</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={4}>
            <Accordion.Header>SESSIONS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/admin_sessions_by_coach" className="link" onClick={handleOpen}>
                <li>SESSIONS BY COACH</li>
              </Link>
              <hr/>
              <Link to="/admin_clients_detail" className="link" onClick={handleOpen}>
                <li>SESSIONS BY CLIENT</li>
              </Link>
              <hr/>
              <Link to="/admin_unlogged_sessions" className="link" onClick={handleOpen}>
                <li>UNLOGGED SESSIONS</li>
              </Link>
              <hr/>
              <Link to="/admin_missed_sessions" className="link" onClick={handleOpen}>
                <li>MISSED SESSIONS BY COACH</li>
              </Link>
              <hr/>
              <Link to="/admin_events_by_coach" className="link" onClick={handleOpen}>
                <li>EVENTS BY COACH</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={5}>
            <Accordion.Header>SALES</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/admin_sales_by_coach" className="link" onClick={handleOpen}>
                <li>SALES BY COACH</li>
              </Link>
              <hr/>
              <Link to="/add_sale" className="link" onClick={handleOpen}>
                <li>LOG A SALE</li>
              </Link>
              <hr/>
              <Link to="/admin_expiring_subscriptions" className="link" onClick={handleOpen}>
                <li>EXPIRING PAID IN FULLS</li>
              </Link>
              <hr/>
              <Link to="/admin_sales_hours_list" className="link" onClick={handleOpen}>
                <li>ADMIN SALES HOURS</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={6}>
            <Accordion.Header>ANALYTICS</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/admin_assignments_by_length" className="link" onClick={handleOpen}>
                <li>ASSIGNMENTS BY LENGTH</li>
              </Link>
              <hr/>
              <Link to="/payroll_breakdown" className="link" onClick={handleOpen}>
                <li>REVENUE & PAYROLL BREAKDOWN</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <hr/>
        <Accordion className="sidebar-accordion">
          <Accordion.Item eventKey={7}>
            <Accordion.Header>MANAGEMENT</Accordion.Header>
            <hr/>
            <Accordion.Body className="table-accordion">
              <Link to="/admin_monthly_payments" className="link" onClick={handleOpen}>
                <li>MONTHLY PAYMENTS</li>
              </Link>
              <hr/>
              <Link to="/program_management" className="link" onClick={handleOpen}>
                <li>PROGRAM MANAGEMENT/PROFIT PER PROGRAM</li>
              </Link>
              <hr/>
              <Link to="/session_commission_list" className="link" onClick={handleOpen}>
                <li>SESSION COMMISSION MANAGEMENT</li>
              </Link>
              <hr/>
              <Link to="/sales_commission_list" className="link" onClick={handleOpen}>
                <li>SALES COMMISSION MANAGEMENT</li>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ul>
    </div>
  );
};

export default AdminSidebar;
