import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./ProgramDetail.scss";
import routingConstants from "../../Navigation/constants";
import { saleAction } from "../../Redux/actions";
import ProgramDetailForm from "./ProgramDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import ReactSelect from "../../Components/Forms/ReactSelect";
import DatePicker from "../../Components/Forms/DatePicker";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { DateRangePicker } from "react-date-range";

library.add(faRunning);
library.add(faBed);

const ProgramDetail = (props) => {
  const dispatch = useDispatch();
  const programDetail = useSelector((state) => state.saleReducer.programDetail);
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const history = useHistory();
  const toastId = useRef(null);
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const refresh = () => {
    dispatch(
        saleAction.loadProgramDetail(
          { 
            filters: { 
              program_id: props.location.state.id,
              date_subset_toggle: allDatesSelected.value,
              start_date: startDate,
              end_date: endDate
            } 
          }
        )
    );
  };

  const handleApplyClicked = () => {
    dispatch(
        saleAction.loadProgramDetail(
          { 
            filters: { 
              program_id: props.location.state.id,
              date_subset_toggle: allDatesSelected.value,
              start_date: startDate,
              end_date: endDate
            } 
          }
        )
    );
  }

  useEffect(() => {
    dispatch(
        saleAction.loadProgramDetail(
          { 
            filters: { 
              program_id: props.location.state.id,
              date_subset_toggle: allDatesSelected.value,
              start_date: startDate,
              end_date: endDate
            } 
          }
        )
    );
  }, [dispatch]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_PROGRAM, data: {'program_id': props.location.state.id} }))
      .then((res) => {
        if (res) {
          if (props.location.state) {
            history.push("/program_management")
          }
          else {
            history.push("/program_management")
          }
        } else {
          toastId.current = toast.error(
            "There was an error deleting the program " + programDetail ? programDetail.program_detail.program_name : '',
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the program", {
          position: "top-center"
        });
      });
  };

  const [allDatesSelected, setAllDatesSelected] = useState({value: 1, label: "All Dates"});

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range" && selector != "Date Range Toggle") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range Toggle":
        setAllDatesSelected(e);
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="club-run-title">PROGRAM</div>
      <div className="club-run-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">Program Name</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <ProgramDetailForm
              programDetail={programDetail.program_detail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
            />
          ) : (
            <>
              <div className="mt-3">{programDetail.program_detail && programDetail.program_detail.program_name}</div>
              <div className="labels mt-3">Program Price</div>
              <div className="mt-2">${programDetail.program_detail && programDetail.program_detail.program_price}</div>
              <div className="labels mt-3">Created at</div>
              <div className="mt-2">{programDetail.program_detail && programDetail.program_detail.uploaded_timestamp}</div>
              <div className="labels mt-3">Program Type</div>
              <div className="mt-2">{programDetail.program_detail && programDetail.program_detail.type}</div>
              <div className="labels mt-3">Monthly or PIF</div>
              <div className="mt-2">{programDetail.program_detail && programDetail.program_detail.monthly_or_pif}</div>
              <div className="labels mt-3">Program Status</div>
              <div className="mt-2">{programDetail.program_detail && programDetail.program_detail.is_active === 1 ? "Active" : "Inactive"}</div>
            </>
          )}
        </div>
        <div className="items">
          <ReactSelect
              options={
                [
                  {value: 1, label: 'All Dates'},
                  {value: 0, label: 'Subset of Dates'}
                ]
              }
              value={allDatesSelected}
              placeholder="Date Range Toggle"
              handleChange={handleChange}
              id={2}
            />
            {allDatesSelected.value === 0 && <DatePicker
              key={100}
              label={"Date Range"}
              startDate={startDate}
              endDate={endDate}
              showToggle={showToggle}
              setShowToggle={setShowToggle}
              type="range">
              
              <DateRangePicker
                startDatePlaceholder={startDate}
                endDatePlaceholder={endDate}
                ranges={[
                  { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
                ]}
                onChange={(e) => handleChange("Date Range", e)}
                showPreview={false}
              />
              </DatePicker>
            }
            <Button className='w-25 mt-3' onClick={handleApplyClicked}>APPLY</Button>
        </div>
        <AccordionContainer
              values={programDetail.program_sales}
              loaded={true}
              title="SALE ARCHIVE"
              type="SALES BY PROGRAM"
              handleClick={handleClick}
              source="ProgramDetail"
              headers={["payment_date", "coach_name", "commission", "client_name"]}
              row_id={"sale_id"}
              pathName={"sale_detail"}
              admin={true}
        >
        </AccordionContainer>
      </div>
    </>
  );
};

export default ProgramDetail;
