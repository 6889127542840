import React, { useState, useRef } from "react";
import FormContainer from "../../Components/Forms/FormContainer";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { requestOptions } from "../../Utils";
import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_CONSTANTS from "../../Redux/constants";
import { Signup } from "../Signup/Signup";

const AdminClientForm = (props) => {
  return (
    <Signup admin={true}/>
  );
};

export default AdminClientForm;
