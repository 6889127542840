const initialState = {
  thisMonthTotals: [],
  monthlyTotals: [],
  recentSessions: [],
  coachMonthlyPayments: [],
  coachMonthlyPaymentsLoaded: false,
  recentSessionsLoaded: false,

};

export const coachDashboardReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_THIS_MONTH_TOTALS':
      return {
        ...state,
        thisMonthTotals: payload
      }
    case 'LOAD_MONTHLY_TOTALS':
      return {
        ...state,
        monthlyTotals: payload
      }
    case 'LOAD_COACH_MONTHLY_PAYMENTS':
      return {
        ...state,
        coachMonthlyPayments: payload,
        coachMonthlyPaymentsLoaded: true,
      }
    case 'RESET_COACH_MONTHLY_PAYMENTS':
      return {
        ...state,
        coachMonthlyPaymentsLoaded: false,
      }
    case 'LOAD_RECENT_SESSIONS':
      return {
        ...state,
        recentSessions: payload,
        recentSessionsLoaded: true,
      }
    default:
      return state
  }
};
