const NAVIGATION_CONSTANTS = {
  CALL_BACK: "/login/callback",
  OVERVIEW: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  COACH_DETAIL: "/coach_detail",
  FORGOT_PASSWORD: "/forgotpassword",
  SESSIONS: "/sessions",
  SESSION_DETAIL: "/session_detail",
  SESSION_ADD: "/add_session",
  CLIENTS: "/clients",
  CLIENT_DETAIL: "/client_detail",
  CLIENT_ADD: "/add_client",
  SALES: "/sales",
  BLOGS: "/blogs",
  BLOG_DETAIL: "/blog_detail",
  BLOG_ADD: "/add_blog",
  EVENTS: "/events",
  EVENT_DETAIL: "/event_detail",
  EVENT_ADD: "/add_event",
  SALE_DETAIL: "/sale_detail",
  PAYMENT: "/payment",
  SALE_ADD: "/add_sale",
  ALL_CLIENTS: "/all_clients",
  ALL_COACHES: "/all_coaches",
  COACH_ADD: "/add_coach",
  PAYMENT_ADJUSTMENT_ADD: "/add_payment_adjustment",
  ADMIN_PAYMENT_ADJUSTMENTS: "/admin_payment_adjustments",
  ADMIN_PAYMENT_ADJUSTMENT_DETAIL: "/admin_payment_adjustment_detail",
  ADMIN_CLIENT_DETAIL: "/admin_client_detail",
  ADMIN_SALES_BY_COACH: "/admin_sales_by_coach",
  ADMIN_SESSIONS_BY_COACH: "/admin_sessions_by_coach",
  ADMIN_CLIENTS_DETAIL: "/admin_clients_detail",
  ADMIN_CLIENTS_BY_COACH: "/clients_by_coach",
  ADMIN_CLIENTS_BY_STATE: "/clients_by_state",
  ADMIN_REVENUE_BY_STATE: "/revenue_by_state",
  COACH_SESSIONS_BY_CLIENT: "/sessions_by_client",
  ADMIN_EVENTS_BY_COACH: "/admin_events_by_coach",
  ADMIN_EXPIRING_SUBSCRIPTIONS: "/admin_expiring_subscriptions",
  ADMIN_EXPENSES: "/expenses",
  ADMIN_PAYROLL_BREAKDOWN: "/payroll_breakdown",
  ADMIN_SESSION_INACTIVITY: "/admin_session_inactivity",
  ADMIN_MONTHLY_PAYMENTS: "/admin_monthly_payments",
  COACH_MONTHLY_PAYMENTS: "/monthly_payments",
  RESOURCE_CENTER: "/resources",
  SALES_HOURS_LIST: "/sales_hours_list",
  SALES_HOURS_DETAIL: "/sales_hours_detail",
  SALES_HOURS_FORM: "/add_sales_hours",
  ADMIN_SALES_HOURS_LIST: "/admin_sales_hours_list",
  REIMBURSEMENTS: "/reimbursements",
  REIMBURSEMENT_DETAIL: "/reimbursement_detail",
  REIMBURSEMENT_FORM: "/add_reimbursement",
  ADMIN_REIMBURSEMENTS_LIST: "/admin_reimbursements",
  ADMIN_UNLOGGED_SESSIONS: "/admin_unlogged_sessions",
  MISSED_SESSIONS: "/missed_sessions",
  MISSED_SESSION_DETAIL: "/missed_session_detail",
  MISSED_SESSION_ADD: "/add_missed_session",
  ADMIN_MISSED_SESSIONS: "/admin_missed_sessions",
  ADMIN_CLIENT_ASSIGNMENTS: "/admin_client_assignments",
  ASSIGNMENT_DETAIL: "/assignment_detail",
  ASSIGNMENT_ADD: "/add_assignment",
  ASSIGNMENT_INSIGHTS: "/admin_assignment_insights",
  ASSIGNMENTS_BY_LENGTH: "/admin_assignments_by_length",
  MISCELLANEOUS_TIME: "/miscellaneous_time_list",
  MISCELLANEOUS_TIME_DETAIL: "/miscellaneous_time_detail",
  ADMIN_MISCELLANEOUS_TIME: "/admin_miscellaneous_time_list",
  MISCELLANEOUS_TIME_ADD: "/add_miscellaneous_time",
  MY_EXPIRING_SUBSCRIPTIONS: "/my_expiring_subscriptions",
  PROGRAM_MANAGEMENT: "/program_management",
  PROGRAM_ADD: "/add_program",
  PROGRAM_DETAIL: "/program_detail",
  SESSION_COMMISSION_LIST: "/session_commission_list",
  ADD_SESSION_COMMISSION: "/add_session_commission",
  SESSION_COMMISSION_DETAIL: "/session_commission_detail",
  SALES_HOURS_COMMISSIONS: "/sales_hours_commissions",
  ADD_SALES_HOURS_COMMISSION: "/add_sales_hours_commission",
  SALES_HOURS_COMMISSION_DETAIL: "/sales_hours_commission_detail",
  CLIENT_NOTES_ADD: "/add_client_notes",
  CLIENT_NOTES_DETAIL: "/client_notes_detail",
  SALES_COMMISSION_LIST: "/sales_commission_list",
  SALES_COMMISSION_DETAIL: "/sales_commission_detail",
  SALES_COMMISSION_ADD: "/add_sales_commission",
  WORKSHEET_LIST: "/worksheet_list",
  WORKSHEET_DETAIL: "/worksheet_detail",
  WORKSHEET_ADD: "/add_worksheet"
};

export default NAVIGATION_CONSTANTS;
