import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import FormContainer from "../../Components/Forms/FormContainer";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import { sessionAction, adminAction } from "../../Redux/actions";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const SalesHoursCommissionForm = (props) => {
    const [coachName, setCoachName] = useState([{value: "", label: "" }]);
    const [rate, setRate] = useState(0);
    const coachList_db = useSelector((state) => state.adminReducer.allSalesReps);
    const [coachList, setCoachList] = useState([]);
    const [salesHoursCommissionStart, setSalesHoursCommissionStart] = useState(new Date().toLocaleDateString());
    const [salesHoursCommissionEnd, setSalesHoursCommissionEnd] = useState(new Date().toLocaleDateString());
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const handleClick = (e) => {
    if (e.target.id == "add") {
        const req_obj = {
            coach_name: coachName.value,
            rate: rate,
            commission_rate_start_date: salesHoursCommissionStart,
            commission_rate_end_date: salesHoursCommissionEnd
        };
        if (
            coachName.value !== "" &&
            salesHoursCommissionStart !== "" &&
            salesHoursCommissionEnd !== "" &&
            rate !== ""
        ) {
            insert_data(req_obj);
        } else {
            toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
        }
    } else {
      reset_data();
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllSalesReps({ filters: { is_active: ["Active"] } }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.coach_name} (${coach.coach_id})`,
          label: `${coach.coach_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  const insert_data = (req_obj) => {
    axios(
        requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SALES_HOURS_COMMISSION, data: req_obj })
      ).then((res) => {
        if (res.data.message === "Success") {
            toastId.current = toast.success(
              "You’ve successfully inserted a new sales hours commission",
              { position: "top-center" }
            );
            reset_data();
          } else if (res.data.message === "Duplicate") {
            toastId.current = toast.error(
              "You’ve already inserted sales hours commission for that coach for that time period",
              { position: "top-center" }
            );
          } else {
            toastId.current = toast.error(
              "There was an error inserting a new sales hours commission",
              { position: "top-center" }
            );
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting sales hours commission data", {
            position: "top-center"
          });
        });
  }

  const reset_data = () => {
    setCoachName("");
    setRate(0);
    setSalesHoursCommissionStart(new Date().toLocaleDateString());
    setSalesHoursCommissionEnd(new Date().toLocaleDateString());
  }

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Sales Rep":
            setCoachName(e);
            break;
        case "Rate":
            setRate(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleStartChange = (e) => {
    const selectedDate = new Date(e);
    setSalesHoursCommissionStart(selectedDate.toLocaleDateString());
  };
  const handleEndChange = (e) => {
    const selectedDate = new Date(e);
    setSalesHoursCommissionEnd(selectedDate.toLocaleDateString());
  };

  return (
      <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="ADD A SALES HOURS COMMISSION" onClick={handleClick}>
        <ReactSelect
            options={coachList}
            value={coachName}
            placeholder="Sales Rep"
            handleChange={handleChange}
        />
        <Input
            value={rate}
            label="Rate"
            id={"Rate"}
            onChange={(e) => handleChange("Rate", e)}
        />
        <DatePicker
          date={salesHoursCommissionStart}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Sales Hours Commission Start Date"}
          title={"COMMISSION START"}>
          <Calendar showPreview={false} onChange={(e) => handleStartChange(e)} />
        </DatePicker>
        <DatePicker
          date={salesHoursCommissionEnd}
          showToggle={showToggle2}
          setShowToggle={setShowToggle2}
          label={"Sales Hours Commission End Date"}
          title={"COMMISSION END"}>
          <Calendar showPreview={false} onChange={(e) => handleEndChange(e)} />
        </DatePicker>
        <div style={{ height: '200px' }}></div>
      </FormContainer>
      </>
  );
};

export default SalesHoursCommissionForm;
