import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import React, { useState, useEffect, useRef } from "react";

import {
    faithBasedOptions,
    salesSourceList,
    sportOptions,
    stateList, 
    trainingLevelList,
    rpInvolvementOptions }
from "../../Utils/selectConstants";
import TextArea from "../../Components/Forms/TextArea";

const TeamForm = ({
                    teamName,
                    sports,
                    address,
                    city,
                    state,
                    trainingLevel,
                    teamExperienceLevel,
                    goals,
                    aspirations,
                    faithBased,
                    additionalInfo,
                    rpName,
                    rpPhone,
                    rpEmail,
                    rpRelation,
                    salesSource,
                    salesSourceDetailsLabel,
                    salesSourceDetails,
                    preferredSessionAvailability,
                    handleChange
                  }) => {
    return (
        <>
            <Input
                label="Team Name"
                type="text"
                value={teamName}
                onChange={(e) => handleChange("Team Name", e)}
            />
            <ReactSelect
                options={sportOptions}
                value={sports}
                placeholder="Primary Sport(s)"
                handleChange={handleChange}
                isMulti
            />
            <Input
                label="School/Team Street Address"
                type="text"
                value={address}
                onChange={(e) => handleChange("School/Team Street Address", e)}
            />
            <Input
                label="City/Town"
                type="text"
                value={city}
                onChange={(e) => handleChange("City/Town", e)}
            />
            <ReactSelect
                type="text"
                placeholder="State"
                options={stateList}
                value={state}
                handleChange={handleChange}
            />
            <ReactSelect
                options={trainingLevelList}
                value={trainingLevel}
                placeholder="Training Level"
                handleChange={handleChange}
            />
            <Input
                label="How experienced is your team?"
                type="text"
                value={teamExperienceLevel}
                onChange={(e) => handleChange("How experienced is your team?", e)}
            />
            <Input
                label="Team Goals"
                type="text"
                value={goals}
                onChange={(e) => handleChange("Team Goals", e)}
            />
            <Input
                label="Other Relevant Information"
                type="text"
                value={aspirations}
                onChange={(e) => handleChange("Other Relevant Information", e)}
            />
            <ReactSelect
                options={faithBasedOptions}
                value={faithBased}
                placeholder="Would you like to incorporate the Christian faith into your training?"
                handleChange={handleChange}
            />
            <Input
                label="Additional info that will help match you with a Mindset Coach"
                type="text"
                value={additionalInfo}
                onChange={(e) => handleChange("Additional info that will help match you with a Mindset Coach", e)}
            />
            <TextArea
                label="We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?"
                type="text"
                height={"300px"}
                value={preferredSessionAvailability}
                onChange={(e) => handleChange("We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?", e)}
            />
            <Input
                label="Responsible Person Name"
                type="text"
                value={rpName}
                onChange={(e) => handleChange("Responsible Person Name", e)}
            />
            <Input
                label="Responsible Person Phone"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={rpPhone}
                onChange={(e) => handleChange("Responsible Person Phone", e)}
            />
            <Input
                label="Responsible Person Email"
                type="text"
                value={rpEmail}
                onChange={(e) => handleChange("Responsible Person Email", e)}
            />
            <Input
                label="Responsible Person Relationship"
                type="text"
                value={rpRelation}
                onChange={(e) => handleChange("Responsible Person Relationship", e)}
            />
            <ReactSelect
                options={salesSourceList}
                value={salesSource}
                placeholder="How did you hear about Winning Mindset?"
                handleChange={handleChange}
            />
            {(salesSource.value === "Team/Club Presentation" ||
              salesSource.value === "Convention" ||
              salesSource.value == "Referral" ||
              salesSource.value == "Social Media" ||
              salesSource.value == "Tournament" || 
              salesSource.value == "Mindset Coach")
             ? <Input
                label={salesSourceDetailsLabel}
                type="text"
                value={salesSourceDetails}
                onChange={(e) => handleChange(salesSourceDetailsLabel, e)}
            /> : <div></div>
            }
        </>
    )
}

export default TeamForm;