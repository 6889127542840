import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import FormContainer from "../../Components/Forms/FormContainer";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import { saleAction, adminAction } from "../../Redux/actions";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const SalesCommissionForm = (props) => {
    const [coachName, setCoachName] = useState([{ value: "", label: "" }]);
    const [commissionPercentage, setCommissionPercentage] = useState(0.1456446774);
    const coachList_db = useSelector((state) => state.adminReducer.allSalesReps);
    const [coachList, setCoachList] = useState([]);
    const [salesCommissionStart, setSalesCommissionStart] = useState(new Date().toLocaleDateString());
    const [salesCommissionEnd, setSalesCommissionEnd] = useState(new Date().toLocaleDateString());
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const handleClick = (e) => {
    if (e.target.id == "add") {
        const req_obj = {
            coach_name: coachName.value,
            commission_percentage: commissionPercentage,
            sales_commission_start_date: salesCommissionStart,
            sales_commission_end_date: salesCommissionEnd
        };
        if (
            coachName.value !== "" &&
            salesCommissionStart !== "" &&
            salesCommissionEnd !== ""
        ) {
            insert_data(req_obj);
        } else {
            toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
        }
    } else {
      reset_data();
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllSalesReps({ filters: { is_active: ["Active"] } }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.coach_name} (${coach.coach_id})`,
          label: `${coach.coach_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  const insert_data = (req_obj) => {
    axios(
        requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SALES_COMMISSION, data: req_obj })
      ).then((res) => {
        if (res.data.message === "Success") {
            toastId.current = toast.success(
              "You’ve successfully inserted a new sales commission",
              { position: "top-center" }
            );
            reset_data();
          } else if (res.data.message === "Duplicate") {
            toastId.current = toast.error(
              "You’ve already inserted sales commission for that coach for that time period",
              { position: "top-center" }
            );
          } else {
            toastId.current = toast.error(
              "There was an error inserting a new sales commission",
              { position: "top-center" }
            );
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting sales commission data", {
            position: "top-center"
          });
        });
  }

  const reset_data = () => {
    setCoachName({value: "", label: ""});
    setCommissionPercentage(0.1456446774);
    setSalesCommissionStart(new Date().toLocaleDateString());
    setSalesCommissionEnd(new Date().toLocaleDateString());
  }

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Sales Rep":
            setCoachName(e);
            break;
        case "Commission Percentage":
            setCommissionPercentage(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleStartChange = (e) => {
    const selectedDate = new Date(e);
    setSalesCommissionStart(selectedDate.toLocaleDateString());
  };
  const handleEndChange = (e) => {
    const selectedDate = new Date(e);
    setSalesCommissionEnd(selectedDate.toLocaleDateString());
  };

  return (
      <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A SALES COMMISSION" onClick={handleClick}>
        <ReactSelect
            options={coachList}
            value={coachName}
            placeholder="Sales Rep"
            handleChange={handleChange}
        />
        <Input
            value={commissionPercentage}
            label="Commission Percentage"
            id={"Commission Percentage"}
            onChange={(e) => handleChange("Commission Percentage", e)}
        />
        <DatePicker
          date={salesCommissionStart}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Sales Commission Start Date"}
          title={"COMMISSION START"}>
          <Calendar showPreview={false} onChange={(e) => handleStartChange(e)} />
        </DatePicker>
        <DatePicker
          date={salesCommissionEnd}
          showToggle={showToggle2}
          setShowToggle={setShowToggle2}
          label={"Sales Commission End Date"}
          title={"COMMISSION END"}>
          <Calendar showPreview={false} onChange={(e) => handleEndChange(e)} />
        </DatePicker>
      </FormContainer>
      </>
  );
};

export default SalesCommissionForm;
