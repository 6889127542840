const initialState = {
  blogList: [],
  blogListLoaded: false,
  blogDetail: []
};

export const blogReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_BLOG_LIST':
      return {
        ...state,
        blogList: payload,
        blogListLoaded: true,
      }
    case 'RESET_BLOG_LIST':
      return {
        ...state,
        blogListLoaded: false,
      }
    case 'LOAD_BLOG_DETAIL':
      return {
        ...state,
        blogDetail: payload
      }
    default:
      return state
  }
};
