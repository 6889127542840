import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Col, Container, Row, Form, Button, Image, FloatingLabel } from "react-bootstrap";
import Input from "../../Components/Forms/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Logo from "../../assets/wm-logo.jpg";
import "./AdminSalesCommissionDetail.scss";
import routingConstants from "../../Navigation/constants";
import { saleAction } from "../../Redux/actions";
import SalesCommissionDetailForm from "./AdminSalesCommissionDetailForm";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

library.add(faRunning);
library.add(faBed);

const SalesCommissionDetail = (props) => {
  const dispatch = useDispatch();
  const salesCommissionDetail = useSelector((state) => state.saleReducer.salesCommissionDetail);
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);

  const history = useHistory();
  const toastId = useRef(null);

  const refresh = () => {
    dispatch(
        saleAction.loadSalesCommissionDetail({ filters: { sales_commission_id: props.location.state.id } })
    );
  };

  useEffect(() => {
    dispatch(
        saleAction.loadSalesCommissionDetail({ filters: { sales_commission_id: props.location.state.id } })
    );
  }, [dispatch]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_SALES_COMMISSION, data: {'sales_commission_id': props.location.state.id} }))
      .then((res) => {
        if (res) {
          if (props.location.state) {
            history.push("/sales_commission_list")
          }
          else {
            history.push("/sales_commission_list")
          }
        } else {
          toastId.current = toast.error(
            "There was an error deleting the commission",
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the commission", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <div className="club-run-title">SALES COMMISSION RATE</div>
      <div className="club-run-container">
        <div className="items">
          <div className="top-container">
            {!clicked && <div className="labels">SALES REP</div>}
            <div style={{ display: "flex" }}>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
              <DeleteButton
                deleteClicked={deleteClicked}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {clicked ? (
            <SalesCommissionDetailForm
              salesCommissionDetail={salesCommissionDetail}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
            />
          ) : (
            <>
              <div className="mt-3">{salesCommissionDetail.coach_name}</div>
              <div className="labels mt-3">Commission Percentage</div>
              <div className="mt-2">{salesCommissionDetail.commission_percentage}%</div>
              <div className="labels mt-3">Commission Rate Start Date</div>
              <div className="mt-2">{salesCommissionDetail.commission_rate_start_date}</div>
              <div className="labels mt-3">Commission Rate End Date</div>
              <div className="mt-2">{salesCommissionDetail.commission_rate_end_date}</div>
              <div className="labels mt-3">Uploaded Timestamp</div>
              <div className="mt-2">{salesCommissionDetail.uploaded_timestamp}</div>
              <div className="labels mt-3">Active Salesperson</div>
              <div className="mt-2">{salesCommissionDetail.active_salesperson === 1 ? "Yes" : "No"}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesCommissionDetail;
