import axios from "axios";
import { requestOptions } from "../../Utils";
import apiConstants from "../constants";
import { loaderAction } from "./loaderAction";

let allClients,
  allCoaches,
  filteredCoaches,
  allSalesReps,
  allRpPhoneNumbers,
  allRpEmails,
  allRpNames,
  calculatedCommission,
  allClientAssignments,
  adminClientDetail,
  adminCoachDetail,
  adminSessionsByCoach,
  adminClubRunsByCoach,
  adminClubRunDetail,
  adminSessionDetail,
  adminSalesByCoach,
  adminSessionsByClient,
  adminSalesByClient,
  adminSaleDetail,
  expenseReport,
  adminClientsByCoach,
  adminPaymentAdjustmentList,
  adminPaymentAdjustmentDetail,
  adminExpiringSubscriptions,
  adminClientsByState,
  adminSessionInactivity,
  adminPayrollBreakdown,
  adminMonthlyPayments,
  adminSalesHours,
  adminReimbursements,
  adminUnloggedSessions,
  adminMissedSessionsByCoach,
  allClientAssignmentsGrouped,
  adminAssignmentInsights,
  adminAssignmentsByLength,
  adminMiscellaneousTime,
  adminAdminAllCoaches,
  federalMileageRates,
  adminSalesHourlyCommissions,
  adminSalesHoursCommissionDetail;

let { resetLoader, setLoader } = loaderAction;

const loadAllClients = (filters) => (dispatch) => {
  dispatch(setLoader("loadClients"));
  if (typeof allClients != typeof undefined) {
    allClients.cancel("The load operation has been canceled due to a new request");
  }
  allClients = axios.CancelToken.source();
  dispatch(resetAllClients());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_CLIENTS,
      data: filters,
      token: allClients.token
    })
  )
    .then((res) => {
      dispatch(populateAllClients(res["data"]["result"]));
      dispatch(resetLoader("loadAllClients"));
    })
    .catch((err) => console.log(err));
};

const loadAdminMonthlyPayments = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminMonthylPayments"));
  if (typeof adminMonthlyPayments != typeof undefined) {
    adminMonthlyPayments.cancel("The load operation has been canceled due to a new request");
  }
  adminMonthlyPayments = axios.CancelToken.source();
  dispatch(resetAdminMonthlyPayments());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.MONTHLY_PAYMENTS,
      data: filters,
      token: adminMonthlyPayments.token
    })
  )
    .then((res) => {
      dispatch(populateAdminMonthlyPayments(res["data"]["result"]));
      dispatch(resetLoader("adminMonthlyPayments"));
    })
    .catch((err) => console.log(err));
};

const loadAdminPayrollBreakdown = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminPayrollBreakdown"));
  if (typeof adminPayrollBreakdown != typeof undefined) {
    adminPayrollBreakdown.cancel("The load operation has been canceled due to a new request");
  }
  adminPayrollBreakdown = axios.CancelToken.source();
  dispatch(resetAdminPayrollBreakdown());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.PAYROLL_BREAKDOWN,
      data: filters,
      token: adminPayrollBreakdown.token
    })
  )
    .then((res) => {
      dispatch(populateAdminPayrollBreakdown(res["data"]["result"]));
      dispatch(resetLoader("loadAdminPayrollBreakdown"));
    })
    .catch((err) => console.log(err));
};

const loadAllRpPhoneNumbers = (filters) => (dispatch) => {
  dispatch(setLoader("loadAllRpPhoneNumbers"));
  if (typeof allRpPhoneNumbers != typeof undefined) {
    allRpPhoneNumbers.cancel("The load operation has been canceled due to a new request");
  }
  allRpPhoneNumbers = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_RP_PHONES,
      data: filters,
      token: allRpPhoneNumbers.token
    })
  )
    .then((res) => {
      dispatch(populateAllRpPhoneNumbers(res["data"]["result"]));
      dispatch(resetLoader("loadAllRpPhoneNumbers"));
    })
    .catch((err) => console.log(err));
};

const loadAllRpEmails = (filters) => (dispatch) => {
  dispatch(setLoader("loadAllRpEmails"));
  if (typeof allRpPhoneEmails != typeof undefined) {
    allRpEmails.cancel("The load operation has been canceled due to a new request");
  }
  allRpEmails = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_RP_EMAILS,
      data: filters,
      token: allRpEmails.token
    })
  )
    .then((res) => {
      dispatch(populateAllRpEmails(res["data"]["result"]));
      dispatch(resetLoader("loadAllRpEmails"));
    })
    .catch((err) => console.log(err));
};

const loadAllRpNames = (filters) => (dispatch) => {
  dispatch(setLoader("loadAllRpNames"));
  if (typeof allRpNames != typeof undefined) {
    allRpNames.cancel("The load operation has been canceled due to a new request");
  }
  allRpNames = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_RP_NAMES,
      data: filters,
      token: allRpNames.token
    })
  )
    .then((res) => {
      dispatch(populateAllRpNames(res["data"]["result"]));
      dispatch(resetLoader("loadAllRpNames"));
    })
    .catch((err) => console.log(err));
};

const loadAdminClientDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminClientDetail"));
  if (typeof adminClientDetail != typeof undefined) {
    adminClientDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminClientDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_CLIENT_DETAIL,
      data: filters,
      token: adminClientDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminClientDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminClientDetail"));
    })
    .catch((err) => console.log(err));
};

const loadAdminCoachDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminCoachDetail"));
  if (typeof adminCoachDetail != typeof undefined) {
    adminCoachDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminCoachDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_COACH_DETAIL,
      data: filters,
      token: adminCoachDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminCoachDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminCoachDetail"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSaleDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSaleDetail"));
  if (typeof adminSaleDetail != typeof undefined) {
    adminSaleDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminSaleDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SALE_DETAIL,
      data: filters,
      token: adminSaleDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSaleDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSaleDetail"));
    })
    .catch((err) => console.log(err));
};

const loadExpenseReport = (filters) => (dispatch) => {
  dispatch(setLoader("loadExpenseReport"));
  if (typeof expenseReport != typeof undefined) {
    expenseReport.cancel("The load operation has been canceled due to a new request");
  }
  expenseReport = axios.CancelToken.source();
  dispatch(resetExpenseReportLoader());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.EXPENSE_REPORT,
      data: filters,
      token: expenseReport.token
    })
  )
    .then((res) => {
      dispatch(populateExpenseReport(res["data"]["result"]));
      dispatch(resetLoader("loadExpenseReport"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSessionDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSessionDetail"));
  if (typeof adminSessionDetail != typeof undefined) {
    adminSessionDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminSessionDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SESSION_DETAIL,
      data: filters,
      token: adminSessionDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSessionDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSessionDetail"));
    })
    .catch((err) => console.log(err));
};

const loadAdminClubRunDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminClubRunDetail"));
  if (typeof adminClubRunDetail != typeof undefined) {
    adminClubRunDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminClubRunDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_CLUB_RUN_DETAIL,
      data: filters,
      token: adminClubRunDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminClubRunDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminClubRunDetail"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSessionsByClient = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSessionsByClient"));
  if (typeof adminSessionsByClient != typeof undefined) {
    adminSessionsByClient.cancel("The load operation has been canceled due to a new request");
  }
  adminSessionsByClient = axios.CancelToken.source();
  dispatch(resetAdminSessionsByClient());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SESSIONS_BY_CLIENT,
      data: filters,
      token: adminSessionsByClient.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSessionsByClient(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSessionsByClient"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSalesByClient = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSalesByCoach"));
  if (typeof adminSalesByClient != typeof undefined) {
    adminSalesByClient.cancel("The load operation has been canceled due to a new request");
  }
  adminSalesByClient = axios.CancelToken.source();
  dispatch(resetAdminSalesByClient());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SALES_BY_CLIENT,
      data: filters,
      token: adminSalesByClient.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSalesByClient(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSalesByClient"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSessionsByCoach = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSessionsByCoach"));
  if (typeof adminSessionsByCoach != typeof undefined) {
    adminSessionsByCoach.cancel("The load operation has been canceled due to a new request");
  }
  adminSessionsByCoach = axios.CancelToken.source();
  dispatch(resetAdminSessionsByCoach());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SESSIONS_BY_COACH,
      data: filters,
      token: adminSessionsByCoach.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSessionsByCoach(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSessionsByCoach"));
    })
    .catch((err) => console.log(err));
};

const loadAdminClubRunsByCoach = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminClubRunsByCoach"));
  if (typeof adminClubRunsByCoach != typeof undefined) {
    adminClubRunsByCoach.cancel("The load operation has been canceled due to a new request");
  }
  adminClubRunsByCoach = axios.CancelToken.source();
  dispatch(resetAdminClubRunsByCoach());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_CLUB_RUNS_BY_COACH,
      data: filters,
      token: adminClubRunsByCoach.token
    })
  )
    .then((res) => {
      dispatch(populateAdminClubRunsByCoach(res["data"]["result"]));
      dispatch(resetLoader("loadAdminClubRunsByCoach"));
    })
    .catch((err) => console.log(err));
};

const loadAdminClientsByCoach = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminClientsByCoach"));
  if (typeof adminClientsByCoach != typeof undefined) {
    adminClientsByCoach.cancel("The load operation has been canceled due to a new request");
  }
  adminClientsByCoach = axios.CancelToken.source();
  dispatch(resetAdminClubRunsByCoach());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_CLIENTS_BY_COACH,
      data: filters,
      token: adminClientsByCoach.token
    })
  )
    .then((res) => {
      dispatch(populateAdminClientsByCoach(res["data"]["result"]));
      dispatch(resetLoader("loadAdminClientsByCoach"));
    })
    .catch((err) => console.log(err));
};

const loadAdminClientsByState = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminClientsByState"));
  if (typeof adminClientsByState != typeof undefined) {
    adminClientsByState.cancel("The load operation has been canceled due to a new request");
  }
  adminClientsByState = axios.CancelToken.source();
  dispatch(resetAdminClientsByState());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_CLIENTS_BY_STATE,
      data: filters,
      token: adminClientsByState.token
    })
  )
    .then((res) => {
      dispatch(populateAdminClientsByState(res["data"]["result"]));
      dispatch(resetLoader("loadAdminClientsByState"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSalesByCoach = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSalesByCoach"));
  if (typeof adminSalesByCoach != typeof undefined) {
    adminSalesByCoach.cancel("The load operation has been canceled due to a new request");
  }
  adminSalesByCoach = axios.CancelToken.source();
  dispatch(resetAdminSalesByCoach());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SALES_BY_COACH,
      data: filters,
      token: adminSalesByCoach.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSalesByCoach(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSalesByCoach"));
    })
    .catch((err) => console.log(err));
};

const loadAdminExpiringSubscriptions = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminExpiringSubscriptions"));
  if (typeof adminExpiringSubscriptions != typeof undefined) {
    adminExpiringSubscriptions.cancel("The load operation has been canceled due to a new request");
  }
  adminExpiringSubscriptions = axios.CancelToken.source();
  dispatch(resetAdminExpiringSubscriptions());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_EXPIRING_SUBSCRIPTIONS,
      data: filters,
      token: adminExpiringSubscriptions.token
    })
  )
    .then((res) => {
      dispatch(populateAdminExpiringSubscriptions(res["data"]["result"]));
      dispatch(resetLoader("loadAdminExpiringSubscriptions"));
    })
    .catch((err) => console.log(err));
};

const loadAllClientAssignments = (filters) => (dispatch) => {
  dispatch(setLoader("loadClientAssignments"));
  if (typeof allClientAssignments != typeof undefined) {
    allClientAssignments.cancel("The load operation has been canceled due to a new request");
  }
  allClientAssignments = axios.CancelToken.source();
  dispatch(resetAllClientAssignments());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_CLIENT_ASSIGNMENTS,
      data: filters,
      token: allClientAssignments.token
    })
  )
    .then((res) => {
      dispatch(populateAllClientAssignments(res["data"]["result"]));
      dispatch(resetLoader("loadAllClientAssignments"));
    })
    .catch((err) => console.log(err));
};

const loadAllClientAssignmentsGrouped = (filters) => (dispatch) => {
  dispatch(setLoader("loadAllClientAssignmentsGrouped"));
  if (typeof allClientAssignmentsGrouped != typeof undefined) {
    allClientAssignmentsGrouped.cancel("The load operation has been canceled due to a new request");
  }
  allClientAssignmentsGrouped = axios.CancelToken.source();
  dispatch(resetAllClientAssignmentsGrouped());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_CLIENT_ASSIGNMENTS_GROUPED,
      data: filters,
      token: allClientAssignmentsGrouped.token
    })
  )
    .then((res) => {
      dispatch(populateAllClientAssignmentsGrouped(res["data"]["result"]));
      dispatch(resetLoader("loadAllClientAssignmentsGrouped"));
    })
    .catch((err) => console.log(err));
};

const loadAllCoaches = (filters) => (dispatch) => {
  dispatch(setLoader("loadAllCoaches"));
  if (typeof allCoaches != typeof undefined) {
    allCoaches.cancel("The load operation has been canceled due to a new request");
  }
  allCoaches = axios.CancelToken.source();
  dispatch(resetAllCoaches());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_COACHES,
      data: filters,
      token: allCoaches.token
    })
  )
    .then((res) => {
      dispatch(populateAllCoaches(res["data"]["result"]));
      dispatch(resetLoader("loadAllCoaches"));
    })
    .catch((err) => console.log(err));
};

const loadFilteredCoaches = (filters) => (dispatch) => {
  dispatch(setLoader("loadFilteredCoaches"));
  if (typeof filteredCoaches != typeof undefined) {
    filteredCoaches.cancel("The load operation has been canceled due to a new request");
  }
  filteredCoaches = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_COACHES,
      data: filters,
      token: filteredCoaches.token
    })
  )
    .then((res) => {
      dispatch(populateFilteredCoaches(res["data"]["result"]));
      dispatch(resetLoader("loadFilteredCoaches"));
    })
    .catch((err) => console.log(err));
};

const loadAllSalesReps = (filters) => (dispatch) => {
  dispatch(setLoader("loadSalesReps"));
  if (typeof allSalesReps != typeof undefined) {
    allSalesReps.cancel("The load operation has been canceled due to a new request");
  }
  allSalesReps = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ALL_SALES_REPS,
      data: filters,
      token: allSalesReps.token
    })
  )
    .then((res) => {
      dispatch(populateAllSalesReps(res["data"]["result"]));
      dispatch(resetLoader("loadAllSalesReps"));
    })
    .catch((err) => console.log(err));
};

const loadCalculatedCommission = (filters) => (dispatch) => {
  dispatch(setLoader("loadCalculatedCommission"));
  if (typeof calculatedCommission != typeof undefined) {
    calculatedCommission.cancel("The load operation has been canceled due to a new request");
  }
  calculatedCommission = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.CALCULATED_COMMISSION,
      data: filters,
      token: calculatedCommission.token
    })
  )
    .then((res) => {
      dispatch(populateCalculatedCommission(res["data"]["result"]));
      dispatch(resetLoader("loadCalculatedCommission"));
    })
    .catch((err) => console.log(err));
};

const loadAdminPaymentAdjustmentList = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminPaymentAdjustmentList"));
  if (typeof adminPaymentAdjustmentList != typeof undefined) {
    adminPaymentAdjustmentList.cancel("The load operation has been canceled due to a new request");
  }
  adminPaymentAdjustmentList = axios.CancelToken.source();
  dispatch(resetAdminPaymentAdjustmentList());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_PAYMENT_ADJUSTMENTS,
      data: filters,
      token: adminPaymentAdjustmentList.token
    })
  )
    .then((res) => {
      dispatch(populateAdminPaymentAdjustmentList(res["data"]["result"]));
      dispatch(resetLoader("loadAdminPaymentAdjustmentList"));
    })
    .catch((err) => console.log(err));
};

const loadAdminPaymentAdjustmentDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminPaymentAdjustmentDetail"));
  if (typeof adminPaymentAdjustmentDetail != typeof undefined) {
    adminPaymentAdjustmentDetail.cancel(
      "The load operation has been canceled due to a new request"
    );
  }
  adminPaymentAdjustmentDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_PAYMENT_ADJUSTMENT_DETAIL,
      data: filters,
      token: adminPaymentAdjustmentDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminPaymentAdjustmentDetail(res["data"]["result"]));
      dispatch(resetLoader("loadAdminPaymentAdjustmentDetail"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSessionInactivity = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSessionInactivity"));
  if (typeof adminSessionInactivity != typeof undefined) {
    adminSessionInactivity.cancel("The load operation has been canceled due to a new request");
  }
  adminSessionInactivity = axios.CancelToken.source();
  dispatch(resetAdminSessionInactivity());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SESSION_INACTIVITY,
      data: filters,
      token: adminSessionInactivity.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSessionInactivity(res["data"]["result"]));
      dispatch(resetLoader("loadAdminSessionInactivity"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSalesHoursData = (filters) => (dispatch) => {
  dispatch(setLoader('loadAdminSalesHours'))
  if (typeof adminSalesHours != typeof undefined) {
    adminSalesHours.cancel('The load operation has been canceled due to a new request')
  }
  adminSalesHours = axios.CancelToken.source()
  dispatch(resetAdminSalesHoursData());
  axios(requestOptions({method: 'POST', url: apiConstants.ADMIN_SALES_HOURS_LIST, data: filters, token: adminSalesHours.token})).then((res) => {
    dispatch(populateAdminSalesHoursData(res['data']['result']));
    dispatch(resetLoader('loadAdminSalesHours'));
  })
  .catch(err => console.log(err))
};

const loadAdminMiscellaneousTimeData = (filters) => (dispatch) => {
  dispatch(setLoader('loadAdminMiscellaneousTime'))
  if (typeof adminMiscellaneousTime != typeof undefined) {
    adminMiscellaneousTime.cancel('The load operation has been canceled due to a new request')
  }
  adminMiscellaneousTime = axios.CancelToken.source();
  dispatch(resetAdminMiscellaneousTimeData());
  axios(requestOptions({method: 'POST', url: apiConstants.ADMIN_MISCELLANEOUS_TIME_LIST, data: filters, token: adminMiscellaneousTime.token})).then((res) => {
    dispatch(populateAdminMiscellaneousTimeData(res['data']['result']));
    dispatch(resetLoader('loadAdminMiscellaneousTime'));
  })
  .catch(err => console.log(err))
};

const loadAdminReimbursements = (filters) => (dispatch) => {
  dispatch(setLoader('loadAdminReimbursements'))
  if (typeof adminReimbursements != typeof undefined) {
    adminReimbursements.cancel('The load operation has been canceled due to a new request')
  }
  adminReimbursements = axios.CancelToken.source();
  dispatch(resetAdminReimbursements());
  axios(requestOptions({method: 'POST', url: apiConstants.ADMIN_REIMBURSEMENT_LIST, data: filters, token: adminReimbursements.token})).then((res) => {

    dispatch(populateAdminReimbursements(res['data']['result']));
    dispatch(resetLoader('loadAdminReimbursements'));
  })
  .catch(err => console.log(err))
};

const loadAdminUnloggedSessions = (filters) => (dispatch) => {
  dispatch(setLoader('loadAdminUnloggedSessions'))
  if (typeof adminUnloggedSessions != typeof undefined) {
    adminUnloggedSessions.cancel('The load operation has been canceled due to a new request')
  }
  adminUnloggedSessions = axios.CancelToken.source();
  dispatch(resetAdminUnloggedSessions());
  axios(requestOptions({method: 'POST', url: apiConstants.ADMIN_UNLOGGED_SESSIONS, data: filters, token: adminUnloggedSessions.token})).then((res) => {

    dispatch(populateAdminUnloggedSessions(res['data']['result']));
    dispatch(resetLoader('loadAdminUnloggedSessions'));
  })
  .catch(err => console.log(err))
};

const loadAdminMissedSessionsByCoach = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminMissedSessionsByCoach"));
  if (typeof adminMissedSessionsByCoach != typeof undefined) {
    adminMissedSessionsByCoach.cancel("The load operation has been canceled due to a new request");
  }
  adminMissedSessionsByCoach = axios.CancelToken.source();
  dispatch(resetAdminMissedSessionsByCoach());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_MISSED_SESSIONS_BY_COACH,
      data: filters,
      token: adminMissedSessionsByCoach.token
    })
  )
    .then((res) => {
      dispatch(populateAdminMissedSessionsByCoach(res["data"]["result"]));
      dispatch(resetLoader("loadAdminMissedSessionsByCoach"));
    })
    .catch((err) => console.log(err));
};

const loadAdminAssignmentInsights = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminAssignmentInsights"));
  if (typeof adminAssignmentInsights != typeof undefined) {
    adminAssignmentInsights.cancel("The load operation has been canceled due to a new request");
  }
  adminAssignmentInsights = axios.CancelToken.source();
  dispatch(resetAdminAssignmentInsights());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_ASSIGNMENT_INSIGHTS,
      data: filters,
      token: adminAssignmentInsights.token
    })
  )
    .then((res) => {
      dispatch(populateAdminAssignmentInsights(res["data"]["result"]));
      dispatch(resetLoader("loadAdminAssignmentInsights"));
    })
    .catch((err) => console.log(err));
};

const loadAdminAssignmentsByLength = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminAssignmentsByLength"));
  if (typeof adminAssignmentsByLength != typeof undefined) {
    adminAssignmentsByLength.cancel("The load operation has been canceled due to a new request");
  }
  adminAssignmentsByLength = axios.CancelToken.source();
  dispatch(resetAdminAssignmentsByLength());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_ASSIGNMENTS_BY_LENGTH,
      data: filters,
      token: adminAssignmentsByLength.token
    })
  )
    .then((res) => {
      dispatch(populateAdminAssignmentsByLength(res["data"]));
      dispatch(resetLoader("loadAdminAssignmentsByLength"));
    })
    .catch((err) => console.log(err));
};

const loadAdminAllCoaches = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminAllCoaches"));
  if (typeof adminAdminAllCoaches != typeof undefined) {
    adminAdminAllCoaches.cancel("The load operation has been canceled due to a new request");
  }
  adminAdminAllCoaches = axios.CancelToken.source();
  dispatch(resetAdminAllCoaches());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_ALL_COACHES,
      data: filters,
      token: adminAdminAllCoaches.token
    })
  )
    .then((res) => {
      dispatch(populateAdminAllCoaches(res["data"]["result"]));
      dispatch(resetLoader("loadAdminAllCoaches"));
    })
    .catch((err) => console.log(err));
};

const loadFederalMileageRates = (filters) => (dispatch) => {
  dispatch(setLoader("loadFederalMileageRates"));
  if (typeof federalMileageRates != typeof undefined) {
    federalMileageRates.cancel("The load operation has been canceled due to a new request");
  }
  federalMileageRates = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.GET_FEDERAL_MILEAGE_RATES,
      data: filters,
      token: federalMileageRates.token
    })
  )
    .then((res) => {
      dispatch(populateFederalMileageRates(res["data"]["result"]));
      dispatch(resetLoader("loadFederalMileageRates"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSalesHourlyCommissions = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSalesHourlyCommissions"));
  if (typeof adminSalesHourlyCommissions != typeof undefined) {
    adminSalesHourlyCommissions.cancel("The load operation has been canceled due to a new request");
  }
  adminSalesHourlyCommissions = axios.CancelToken.source();
  dispatch(resetAdminSalesHourlyCommissions());
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SALES_HOURLY_RATES,
      data: filters,
      token: adminSalesHourlyCommissions.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSalesHourlyCommissions(res["data"]["result"]));
      dispatch(resetLoader("adminSalesHourlyCommissions"));
    })
    .catch((err) => console.log(err));
};

const loadAdminSalesHoursCommissionDetail = (filters) => (dispatch) => {
  dispatch(setLoader("loadAdminSalesHoursCommissionDetail"));
  if (typeof adminSalesHoursCommissionDetail != typeof undefined) {
    adminSalesHoursCommissionDetail.cancel("The load operation has been canceled due to a new request");
  }
  adminSalesHoursCommissionDetail = axios.CancelToken.source();
  axios(
    requestOptions({
      method: "POST",
      url: apiConstants.ADMIN_SALES_HOURS_COMMISSION_DETAIL,
      data: filters,
      token: adminSalesHoursCommissionDetail.token
    })
  )
    .then((res) => {
      dispatch(populateAdminSalesHoursCommissionDetail(res["data"]["result"]));
      dispatch(resetLoader("adminSalesHoursCommissionDetail"));
    })
    .catch((err) => console.log(err));
};

const populateAdminSalesHoursCommissionDetail = (data) => ({
  type: "LOAD_ADMIN_SALES_HOURS_COMMISSION_DETAIL",
  payload: data
});

const populateAdminSalesHourlyCommissions = (data) => ({
  type: "LOAD_ADMIN_SALES_HOURS_COMMISSIONS",
  payload: data
});

const resetAdminSalesHourlyCommissions = () => ({
  type: "RESET_ADMIN_SALES_HOURS_COMMISSIONS",
  adminSalesHourlyCommissionsLoaded: false
});

const populateAllClients = (data) => ({
  type: "LOAD_ALL_CLIENTS",
  payload: data
});

const resetAllClients = () => ({
  type: "RESET_ALL_CLIENTS",
  allClientsLoaded: false
});

const populateAdminAllCoaches = (data) => ({
  type: "LOAD_ADMIN_ALL_COACHES",
  payload: data
});

const resetAdminAllCoaches = () => ({
  type: "RESET_ADMIN_ALL_COACHES",
  adminAllCoachesLoaded: false
});

const populateAdminMonthlyPayments = (data) => ({
  type: "LOAD_ADMIN_MONTHLY_PAYMENTS",
  payload: data
});

const resetAdminMonthlyPayments = () => ({
  type: "RESET_ADMIN_MONTHLY_PAYMENTS",
  adminMonthlyPaymentsLoaded: false
});

const populateAdminPayrollBreakdown = (data) => ({
  type: "LOAD_ADMIN_PAYROLL_BREAKDOWN",
  payload: data
});

const resetAdminPayrollBreakdown = () => ({
  type: "RESET_ADMIN_PAYROLL_BREAKDOWN",
  adminPayrollBreakdownLoaded: false
});

const populateAllRpPhoneNumbers = (data) => ({
  type: "LOAD_ALL_RP_PHONE_NUMBERS",
  payload: data
});

const populateAllRpEmails = (data) => ({
  type: "LOAD_ALL_RP_EMAILS",
  payload: data
});

const populateAllRpNames = (data) => ({
  type: "LOAD_ALL_RP_NAMES",
  payload: data
});

const populateAdminClientDetail = (data) => ({
  type: "LOAD_ADMIN_CLIENT_DETAIL",
  payload: data
});

const populateAdminCoachDetail = (data) => ({
  type: "LOAD_ADMIN_COACH_DETAIL",
  payload: data
});

const populateAdminSessionDetail = (data) => ({
  type: "LOAD_ADMIN_SESSION_DETAIL",
  payload: data
});

const populateAdminClubRunDetail = (data) => ({
  type: "LOAD_ADMIN_CLUB_RUN_DETAIL",
  payload: data
});

const populateAllClientAssignments = (data) => ({
  type: "LOAD_ALL_CLIENT_ASSIGNMENTS",
  payload: data
});

const resetAllClientAssignments = () => ({
  type: "RESET_ALL_CLIENT_ASSIGNMENTS",
  allClientAssignmentsLoaded: false
});

const populateAllClientAssignmentsGrouped = (data) => ({
  type: "LOAD_ALL_CLIENT_ASSIGNMENTS_GROUPED",
  payload: data
});

const resetAllClientAssignmentsGrouped = () => ({
  type: "RESET_ALL_CLIENT_ASSIGNMENTS_GROUPED",
  allClientAssignmentsGroupedLoaded: false
});

const populateAdminAssignmentInsights = (data) => ({
  type: "LOAD_ADMIN_ASSIGNMENT_INSIGHTS",
  payload: data
});

const resetAdminAssignmentInsights = () => ({
  type: "RESET_ADMIN_ASSIGNMENT_INSIGHTS",
  adminAssignmentInsightsLoaded: false
});

const populateAdminAssignmentsByLength = (data) => ({
  type: "LOAD_ADMIN_ASSIGNMENTS_BY_LENGTH",
  payload: data
});

const resetAdminAssignmentsByLength = () => ({
  type: "RESET_ADMIN_ASSIGNMENTS_BY_LENGTH",
  adminAssignmentsByLengthLoaded: false
});

const populateAllCoaches = (data) => ({
  type: "LOAD_ALL_COACHES",
  payload: data
});

const resetAllCoaches = () => ({
  type: "RESET_ALL_COACHES",
  allCoachesLoaded: false
});

const populateFilteredCoaches = (data) => ({
  type: "LOAD_FILTERED_COACHES",
  payload: data
});

const populateAllSalesReps = (data) => ({
  type: "LOAD_ALL_SALES_REPS",
  payload: data
});

const populateCalculatedCommission = (data) => ({
  type: "LOAD_CALCULATED_COMMISSION",
  payload: data
});

const populateAdminSessionsByCoach = (data) => ({
  type: "LOAD_ADMIN_SESSIONS_BY_COACH",
  payload: data
});

const resetAdminSessionsByCoach = () => ({
  type: "RESET_ADMIN_SESSIONS_BY_COACH",
  adminSessionsByCoachLoaded: false
});

const populateAdminClubRunsByCoach = (data) => ({
  type: "LOAD_ADMIN_CLUB_RUNS_BY_COACH",
  payload: data
});

const resetAdminClubRunsByCoach = () => ({
  type: "RESET_ADMIN_CLUB_RUNS_BY_COACH",
  adminClubRunsByCoachLoaded: false
});

const populateAdminSalesByCoach = (data) => ({
  type: "LOAD_ADMIN_SALES_BY_COACH",
  payload: data
});

const resetAdminSalesByCoach = () => ({
  type: "RESET_ADMIN_SALES_BY_COACH",
  adminSalesByCoachLoaded: false
});

const populateAdminExpiringSubscriptions = (data) => ({
  type: "LOAD_ADMIN_EXPIRING_SUBSCRIPTIONS",
  payload: data
});

const resetAdminExpiringSubscriptions = () => ({
  type: "RESET_ADMIN_EXPIRING_SUBSCRIPTIONS",
  adminExpiringSubscriptionsLoaded: false
});

const populateAdminClientsByCoach = (data) => ({
  type: "LOAD_ADMIN_CLIENTS_BY_COACH",
  payload: data
});

const resetAdminClientsByCoach = () => ({
  type: "RESET_ADMIN_CLIENTS_BY_COACH",
  adminClientsByCoachLoaded: false
});

const populateAdminClientsByState = (data) => ({
  type: "LOAD_ADMIN_CLIENTS_BY_STATE",
  payload: data
});

const resetAdminClientsByState = () => ({
  type: "RESET_ADMIN_CLIENTS_BY_STATE",
  adminClientsByStateLoaded: false,
});

const populateAdminSessionsByClient = (data) => ({
  type: "LOAD_ADMIN_SESSIONS_BY_CLIENT",
  payload: data
});

const resetAdminSessionsByClient = () => ({
  type: "RESET_ADMIN_SESSIONS_BY_CLIENT",
  adminSessionsByClientLoaded: false
});

const populateAdminSalesByClient = (data) => ({
  type: "LOAD_ADMIN_SALES_BY_CLIENT",
  payload: data
});

const resetAdminSalesByClient = () => ({
  type: "RESET_ADMIN_SALES_BY_CLIENT",
  adminSalesByClientLoaded: false
});

const populateAdminSaleDetail = (data) => ({
  type: "LOAD_ADMIN_SALE_DETAIL",
  payload: data
});

const populateExpenseReport = (data) => ({
  type: "LOAD_EXPENSE_REPORT",
  payload: data
});

const resetExpenseReportLoader = () => ({
  type: "RESET_EXPENSE_REPORT",
  expenseReportLoaded: false
})

const populateAdminPaymentAdjustmentList = (data) => ({
  type: "LOAD_ADMIN_PAYMENT_ADJUSTMENT_LIST",
  payload: data
});

const resetAdminPaymentAdjustmentList = () => ({
  type: "RESET_ADMIN_PAYMENT_ADJUSTMENT_LIST",
  adminPaymentAdjustmentListLoaded: false
});

const populateAdminPaymentAdjustmentDetail = (data) => ({
  type: "LOAD_ADMIN_PAYMENT_ADJUSTMENT_DETAIL",
  payload: data
});

const populateAdminSessionInactivity = (data) => ({
  type: "LOAD_ADMIN_SESSION_INACTIVITY",
  payload: data
});

const resetAdminSessionInactivity = () => ({
  type: "RESET_ADMIN_SESSION_INACTIVITY",
  adminSessionInactivityLoaded: false
});

const populateAdminSalesHoursData = (data) => ({
  type: 'LOAD_ADMIN_SALES_HOURS_LIST',
  payload: data
});

const resetAdminSalesHoursData = () => ({
  type: 'RESET_ADMIN_SALES_HOURS_LIST',
  adminSalesHoursLoaded: false
});

const populateAdminMiscellaneousTimeData = (data) => ({
  type: 'LOAD_ADMIN_MISCELLANEOUS_TIME_LIST',
  payload: data
});

const resetAdminMiscellaneousTimeData = () => ({
  type: 'RESET_ADMIN_MISCELLANEOUS_TIME_LIST',
  adminMiscellaneousTimeLoaded: false
});

const populateAdminReimbursements = (data) => ({
  type: 'LOAD_ADMIN_REIMBURSEMENTS',
  payload: data
});

const resetAdminReimbursements = () => ({
  type: 'RESET_ADMIN_REIMBURSEMENTS',
  adminReimbursementsLoaded: false
});

const populateAdminUnloggedSessions = (data) => ({
  type: 'LOAD_ADMIN_UNLOGGED_SESSIONS',
  payload: data
});

const resetAdminUnloggedSessions = () => ({
  type: 'RESET_ADMIN_UNLOGGED_SESSIONS',
  adminUnloggedSessionsLoaded: false
});

const populateAdminMissedSessionsByCoach = (data) => ({
  type: "LOAD_ADMIN_MISSED_SESSIONS_BY_COACH",
  payload: data
});

const resetAdminMissedSessionsByCoach = () => ({
  type: "RESET_ADMIN_MISSED_SESSIONS_BY_COACH",
  adminMissedSessionsByCoachLoaded: false
});

const populateFederalMileageRates = (data) => ({
  type: "LOAD_FEDERAL_MILEAGE_RATES",
  payload: data
});

const resetData = () => ({
  type: "RESET_DATA"
});

export const adminAction = {
  loadAllClients,
  loadAllCoaches,
  loadFilteredCoaches,
  loadAllSalesReps,
  loadAllRpPhoneNumbers,
  loadAllRpNames,
  loadAllRpEmails,
  loadCalculatedCommission,
  loadAllClientAssignments,
  loadAllClientAssignmentsGrouped,
  loadAdminClientDetail,
  loadAdminCoachDetail,
  loadAdminSessionsByCoach,
  loadAdminClubRunsByCoach,
  loadAdminSalesByClient,
  loadAdminSessionDetail,
  loadAdminClubRunDetail,
  loadAdminSalesByCoach,
  loadAdminExpiringSubscriptions,
  loadAdminClientsByCoach,
  loadAdminSessionsByClient,
  loadAdminClientsByState,
  loadAdminSaleDetail,
  loadAdminPaymentAdjustmentList,
  loadAdminPaymentAdjustmentDetail,
  loadExpenseReport,
  loadAdminSessionInactivity,
  loadAdminPayrollBreakdown,
  loadAdminMonthlyPayments,
  loadAdminSalesHoursData,
  loadAdminReimbursements,
  loadAdminUnloggedSessions,
  loadAdminMissedSessionsByCoach,
  loadAdminAssignmentInsights,
  loadAdminAssignmentsByLength,
  loadAdminMiscellaneousTimeData,
  loadAdminAllCoaches,
  loadFederalMileageRates,
  loadAdminSalesHourlyCommissions,
  loadAdminSalesHoursCommissionDetail,
  resetData
};
