import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { months, years } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import { clientTypeList } from "../../Utils/selectConstants";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { Table, Button, Row } from "react-bootstrap";

const AdminSessionDetail = (props) => {
  const dispatch = useDispatch();
  const coachSessions = useSelector((state) => state.adminReducer.adminSessionsByCoach.result);
  const exportData = useSelector((state) => state.adminReducer.adminSessionsByCoach.export);
  const coachSessionsLoaded = useSelector((state) => state.adminReducer.adminSessionsByCoachLoaded);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showToggle, setShowToggle] = useState(false);
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [clientTypeSelected, setClientTypeSelected] = useState([{ value: "All", label: "All" }]);
  const [filters, setFilters] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Admin Sessions by Coach" } })
    );
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminSessionsByCoach({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypeSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminSessionsByCoach({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Coach":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Client Type":
        if (e.length == 0) {
          setClientTypeSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypeSelected(newVals);
        }
        break;
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let client_type_arr = clientTypeSelected.map((clientType) => clientType.value);
      let filtersObj = {
        start_date: [startDate],
        end_date: [endDate],
        coaches: coaches_arr,
        clientType: client_type_arr,
        page_name: "Admin Sessions by Coach"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAdminSessionsByCoach({ filters: filters }));
  }, [applyClicked]);

  useEffect(() => {
    if (coachSessions) {
      let numberOfCoaches = coachSessions.length;
      let athleteSum = coachSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Athlete");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let teamSum = coachSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Team");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      let corporateSum = coachSessions.reduce((accumulator, currentValue) => {
        const innerSum = currentValue.breakdown.filter((item) => item.client_type === "Corporate");
        // Sum the commission values of the filtered items
        const sum = innerSum.reduce((sum, item) => sum + item.total_commission, 0);

        // Add the sum to the accumulator
        return accumulator + sum;
      }, 0);
      setSummaryData({
        headers: [
          "Number of Coaches",
          "Total Commission",
          "Sum of Athlete",
          "Sum of Team",
          "Sum of Corporate"
        ],
        data: [
          numberOfCoaches,
          athleteSum + teamSum + corporateSum,
          athleteSum,
          teamSum,
          corporateSum
        ]
      });
    }
  }, [coachSessions]);

  return (
    <AccordionContainer
      values={coachSessions}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      handleClick={handleClick}
      title="SESSIONS BY COACH"
      row_id={"session_id"}
      pathName={"session_detail"}
      type="SESSIONS"
      admin={true}
      loaded={coachSessionsLoaded}
      summaryData={summaryData}
      exportButton={true}
      exportData={exportData}
      headers={["client_name", "session_date", "total_commission"]}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coach"
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypeList}
        value={clientTypeSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <DatePicker
        key={100}
        label={"Date Range"}
        startDate={startDate}
        endDate={endDate}
        showToggle={showToggle}
        setShowToggle={setShowToggle}
        type="range">
        <DateRangePicker
          startDatePlaceholder={startDate}
          endDatePlaceholder={endDate}
          ranges={[
            { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
          ]}
          onChange={(e) => handleChange("Date Range", e)}
          showPreview={false}
        />
      </DatePicker>
    </AccordionContainer>
  );
};

export default AdminSessionDetail;
