const API_CONSTANTS = {
  LOAD_PERSONA: "/api/users",
  THIS_MONTH_TOTALS: "/api/dashboard/this_month_totals",
  RECENT_SESSIONS: "/api/dashboard/recent_sessions",
  COACH_DETAIL: "/api/coach_profile/coaches_detail",
  UPDATE_COACH_DETAIL: "/api/coach_profile/update_coaches_detail",
  SESSION_LIST: "/api/sessions/session_list",
  SESSION_DETAIL: "/api/sessions/session_detail",
  SESSION_WORKSHEETS: "/api/sessions/session_worksheets",
  SESSION_TOPICS: "/api/sessions/session_topics",
  UPDATE_SESSION_DETAIL: "/api/sessions/update_session_detail",
  INSERT_SESSION: "/api/sessions/add_session",
  CLIENT_LIST: "/api/clients/client_list",
  CLIENT_DETAIL: "/api/clients/client_detail",
  SALE_LIST: "/api/sales/sale_list",
  SALE_DETAIL: "/api/sales/sale_detail",
  MONTHLY_TOTALS: "/api/dashboard/monthly_totals",
  LOAD_FILTER_DATA: "/api/filters/get_filters",
  LOAD_SAVED_USER_FILTERS: "/api/filters/get_saved_user_filters",
  INSERT_FILTERS: "/api/filters/add_filters",
  PROGRAMS: "/api/sales/programs",
  ALL_CLIENTS: "/api/filters/all_clients",
  ALL_COACHES: "/api/filters/all_coaches",
  ALL_SALES_REPS: "/api/filters/all_sales_reps",
  ALL_RP_PHONES: "/api/filters/all_rp_phone_numbers",
  ALL_RP_EMAILS: "/api/filters/all_rp_emails",
  ALL_RP_NAMES: "/api/filters/all_rp_names",
  CALCULATED_COMMISSION: "/api/admin/calculate_commission",
  INSERT_SALE: "/api/admin/add_sale",
  ALL_CLIENT_ASSIGNMENTS: "/api/admin/all_client_assignments",
  ADMIN_CLIENT_DETAIL: "/api/admin/admin_client_detail",
  ADMIN_SESSIONS_BY_COACH: "/api/admin/session_breakdown_by_coach",
  ADMIN_SALES_BY_COACH: "/api/admin/sale_breakdown_by_coach",
  ADMIN_SESSIONS_BY_CLIENT: "/api/admin/session_breakdown_by_client",
  ADMIN_CLUB_RUNS_BY_COACH: "/api/admin/club_run_breakdown_by_coach",
  ADMIN_SESSION_DETAIL: "/api/admin/admin_session_detail",
  ADMIN_CLUB_RUN_DETAIL: "/api/admin/admin_club_run_detail",
  ADMIN_SALE_DETAIL: "/api/admin/admin_sale_detail",
  ADMIN_CLIENTS_BY_COACH: "/api/admin/clients_per_coach",
  ADMIN_CLIENTS_BY_STATE: "/api/admin/clients_per_state",
  UPDATE_CLIENT_DETAIL: "/api/clients/update_client_detail",
  BLOG_LIST: "/api/blogs/blog_list",
  BLOG_DETAIL: "/api/blogs/blog_detail",
  INSERT_BLOG: "/api/blogs/add_blog",
  CLUB_RUN_LIST: "/api/club_runs/club_run_list",
  CLUB_RUN_DETAIL: "/api/club_runs/club_run_detail",
  INSERT_CLUB_RUN: "/api/club_runs/add_club_run",
  UPDATE_CLUB_RUN_DETAIL: "/api/club_runs/update_club_run_detail",
  COACH_SESSIONS_BY_CLIENT: "/api/sessions/coach_session_breakdown_by_client",
  UPDATE_SALE_DETAIL: "/api/sales/update_sale_detail",
  EXPENSE_REPORT: "/api/admin/admin_expense_report",
  INSERT_CLIENT: "/api/clients/add_client",
  ADMIN_SALES_BY_CLIENT: "/api/admin/sale_breakdown_by_client",
  ADMIN_COACH_DETAIL: "/api/admin/admin_coach_detail",
  ADMIN_EXPIRING_SUBSCRIPTIONS: "/api/admin/expiring_subscriptions",
  INSERT_COACH: "/api/coach_profile/add_coach",
  DELETE_SESSION: "/api/sessions/delete_session",
  DELETE_BLOG: "/api/blogs/delete_blog",
  DELETE_CLUB_RUN: "/api/club_runs/delete_club_run",
  DELETE_SALE: "/api/admin/delete_sale",
  PAYMENT_ADJUSTMENTS: "/api/payment_adjustments/payment_adjustments",
  PAYMENT_ADJUSTMENT_DETAIL: "/api/payment_adjustments/payment_adjustments",
  ADMIN_PAYMENT_ADJUSTMENTS: "/api/admin/admin_payment_adjustments",
  INSERT_PAYMENT_ADJUSTMENT: "/api/payment_adjustments/add_payment_adjustment",
  ADMIN_PAYMENT_ADJUSTMENT_DETAIL: "/api/admin/admin_payment_adjustment_detail",
  ADD_PAYMENT_ADJUSTMENT: "/api/payment_adjustments/add_payment_adjustment",
  UPDATE_PAYMENT_ADJUSTMENT: "/api/payment_adjustments/update_payment_adjustment_detail",
  DELETE_PAYMENT_ADJUSTMENT: "/api/payment_adjustments/delete_payment_adjustment",
  ADMIN_SESSION_INACTIVITY: "/api/admin/admin_session_inactivity",
  ADD_CLIENT_NEW: "/api/clients/add_client_new",
  VERIFY_REGISTRATION_KEY: "/api/clients/verify_registration_key",
  CURRENT_REGISTRATION_KEY: "/api/clients/get_current_registration_key",
  PAYROLL_BREAKDOWN: "/api/admin/admin_payroll_breakdown",
  MONTHLY_PAYMENTS: "/api/admin/admin_monthly_payments",
  COACH_MONTHLY_PAYMENTS: "/api/dashboard/coach_monthly_payments",
  MARK_MONTHLY_PAYMENT: "/api/admin/mark_monthly_payment",
  ADD_COACH_NEW: "/api/coach_profile/add_coach_new",
  INSERT_SALES_HOURS: "/api/sales/add_sales_hours",
  SALES_HOURS_LIST: "/api/sales/sales_hours",
  SALES_HOURS_DETAIL: "/api/sales/sales_hours_detail",
  ADMIN_SALES_HOURS_LIST: "/api/admin/admin_sales_hours_list",
  DELETE_SALES_HOURS: "/api/sales/delete_sales_hours",
  UPDATE_SALES_HOURS: "/api/sales/update_sales_hours",
  INSERT_REIMBURSEMENT: "/api/sessions/add_reimbursement",
  REIMBURSEMENTS_LIST: "/api/sessions/reimbursement_list",
  REIMBURSEMENT_DETAIL: "/api/sessions/reimbursement_detail",
  UPDATE_REIMBURSEMENT: "/api/sessions/update_reimbursement_detail",
  DELETE_REIMBURSEMENT: "/api/sessions/delete_reimbursement",
  ADMIN_REIMBURSEMENT_LIST: "/api/admin/admin_reimbursement_list",
  ADMIN_UNLOGGED_SESSIONS: "/api/admin/admin_unlogged_sessions",
  MISSED_SESSION_LIST: "/api/sessions/missed_session_list",
  MISSED_SESSION_DETAIL: "/api/sessions/missed_session_detail",
  DELETE_MISSED_SESSION: "/api/sessions/delete_missed_session",
  INSERT_MISSED_SESSION: "/api/sessions/add_missed_session",
  ADMIN_MISSED_SESSIONS_BY_COACH: "/api/admin/missed_session_breakdown_by_coach",
  UPDATE_MISSED_SESSION_DETAIL: "/api/sessions/update_missed_session_detail",
  ALL_CLIENT_ASSIGNMENTS_GROUPED: "/api/admin/client_coach_assignments_grouped",
  ASSIGNMENT_DETAIL: "/api/clients/assignment_detail",
  UPDATE_ASSIGNMENT_DETAIL: "/api/clients/update_assignment_detail",
  DELETE_ASSIGNMENT: "/api/clients/delete_assignment",
  INSERT_ASSIGNMENT: "/api/clients/add_assignment",
  ADMIN_ASSIGNMENT_INSIGHTS: "/api/admin/client_coach_assignment_insights",
  ADMIN_ASSIGNMENTS_BY_LENGTH: "/api/admin/client_coach_assignments_by_length",
  INSERT_MISCELLANEOUS_TIME: "/api/coach_profile/add_miscellaneous_time",
  MISCELLANEOUS_TIME_LIST: "/api/coach_profile/miscellaneous_time",
  MISCELLANEOUS_TIME_DETAIL: "/api/coach_profile/miscellaneous_time_detail",
  UPDATE_MISCELLANEOUS_TIME_DETAIL: "/api/coach_profile/update_miscellaneous_time",
  DELETE_MISCELLANEOUS_TIME: "/api/coach_profile/delete_miscellaneous_time",
  ADMIN_MISCELLANEOUS_TIME_LIST: "/api/admin/admin_miscellaneous_time_list",
  CONTACT_WITH_RP: "/api/clients/contact_with_rp",
  MY_EXPIRING_SUBSCRIPTIONS: "/api/sales/my_expiring_subscriptions",
  ADMIN_ALL_COACHES: "/api/admin/admin_all_coaches",
  INSERT_ASSIGNMENT_BONUS: "/api/admin/add_assignment_bonus",
  GET_FEDERAL_MILEAGE_RATES: "/api/filters/federal_mileage_rate",
  ADD_PROGRAM: "/api/sales/add_program",
  PROGRAM_DETAIL: "/api/sales/program_detail",
  UPDATE_PROGRAM_DETAIL: "/api/sales/update_program_detail",
  DELETE_PROGRAM: "/api/sales/delete_program",
  SESSION_COMMISSION_LIST: "/api/sessions/session_commissions",
  SESSION_COMMISSION_DETAIL: "/api/sessions/session_commission_detail",
  INSERT_SESSION_COMMISSION: "/api/sessions/add_session_commission",
  UPDATE_SESSION_COMMISSION: "/api/sessions/update_session_commission_detail",
  DELETE_SESSION_COMMISSION: "/api/sessions/delete_session_commission",
  PROGRAM_LIST: "/api/filters/programs",
  ADMIN_SALES_HOURLY_RATES: "/api/admin/sales_hourly_rates",
  ADMIN_SALES_HOURS_COMMISSION_DETAIL: "/api/admin/sales_hours_commission_detail",
  INSERT_SALES_HOURS_COMMISSION: "/api/admin/add_sales_hours_commission",
  UPDATE_SALES_HOURS_COMMISSION: "/api/admin/update_sales_hours_commission",
  DELETE_SALES_HOURS_COMMISSION: "/api/admin/delete_sales_hours_commission",
  CLIENT_NOTES_LIST: "/api/clients/client_notes_list",
  CLIENT_NOTES_DETAIL: "/api/clients/client_notes_detail",
  INSERT_CLIENT_NOTES: "/api/clients/add_client_notes",
  UPDATE_CLIENT_NOTES: "/api/clients/update_client_notes",
  DELETE_CLIENT_NOTES: "/api/clients/delete_client_notes",
  SALES_COMMISSION_LIST: "/api/sales/sales_commission_list",
  SALES_COMMISSION_DETAIL: "/api/sales/sales_commission_detail",
  INSERT_SALES_COMMISSION: "/api/sales/add_sales_commission",
  UPDATE_SALES_COMMISSION: "/api/sales/update_sales_commission",
  DELETE_SALES_COMMISSION: "/api/sales/delete_sales_commission"
};

export default API_CONSTANTS;
