const initialState = {
  allClients: [],
  allClientsLoaded: false,
  allClientAssignments: [],
  allClientAssignmentsLoaded: false,
  allClientAssignmentsGrouped: [],
  allClientAssignmentsGroupedLoaded: false,
  allSales: {},
  allSalesLoaded: false,
  allSessions: {},
  allSessionsLoaded: false,
  allCoaches: [],
  allCoachesLoaded: false,
  filteredCoaches: [],
  allSalesReps: {},
  allSalesRepsLoaded: false,
  allRpPhoneNumbers: {},
  allRpEmails: {},
  allRpNames: {},
  calculatedCommission: 0,
  adminClientDetail: [],
  adminSessionsByCoach: [],
  adminSessionsByCoachLoaded: false,
  adminSalesbyCoach: [],
  adminSalesbyCoachLoaded: false,
  adminExpiringSubscriptions: [],
  adminExpiringSubscriptionsLoaded: false,
  adminSessionsByClient: [],
  adminSessionsByClientLoaded: false,
  adminClubRunsByCoach: [],
  adminClubRunsByCoachLoaded: false,
  adminClubRunDetail: [],
  adminSessionDetail: [],
  adminSaleDetail: [],
  expenseReport: [],
  expenseReportLoaded: false,
  adminSalesByClient: [],
  adminSalesByClientLoaded: false,
  adminClientsByCoach: [],
  adminClientsByCoachLoaded: false,
  adminClientsByState: [],
  adminClientsByStateLoaded: false,
  adminCoachDetail: [],
  adminPaymentAdjustmentList: [],
  adminPaymentAdjustmentListLoaded: false,
  adminPaymentAdjustmentDetail: [],
  adminSessionInactivity: [],
  adminSessionInactivityLoaded: false,
  adminPayrollBreakdown: [],
  adminPayrollBreakdownLoaded: false,
  adminMonthlyPayments: [],
  adminMonthlyPaymentsLoaded: false,
  adminSalesHours: [],
  adminSalesHoursLoaded: false,
  adminReimbursements: [],
  adminReimbursementsLoaded: false,
  adminUnloggedSessions: [],
  adminUnloggedSessionsLoaded: false,
  adminMissedSessionsByCoach: [],
  adminMissedSessionsByCoachLoaded: false,
  adminAssignmentInsights: [],
  adminAssignmentInsightsLoaded: false,
  adminAssignmentsByLength: [],
  adminAssignmentsByLengthLoaded: false,
  adminMiscellaneousTime: [],
  adminMiscellaneousTimeLoaded: false,
  adminAllCoaches: [],
  adminAllCoachesLoaded: false,
  federalMileageRates: [],
  adminSalesHourlyCommissions: [],
  adminSalesHourlyCommissionsLoaded: false,
  adminSalesHoursCommissionDetail: []
};

export const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOAD_ALL_CLIENTS":
      return {
        ...state,
        allClients: payload,
        allClientsLoaded: true,
      };
    case "RESET_ALL_CLIENTS":
      return {
        ...state,
        allClientsLoaded: false,
      };
    case "LOAD_ADMIN_PAYROLL_BREAKDOWN":
      return {
        ...state,
        adminPayrollBreakdown: payload,
        adminPayrollBreakdownLoaded: true,
      };
    case "RESET_ADMIN_PAYROLL_BREAKDOWN":
      return {
        ...state,
        adminPayrollBreakdownLoaded: false,
      };
    case "LOAD_ALL_COACHES":
      return {
        ...state,
        allCoaches: payload,
        allCoachesLoaded: true,
      };
    case "RESET_ALL_COACHES":
      return {
        ...state,
        allCoachesLoaded: false,
      };
    case "LOAD_ADMIN_ALL_COACHES":
      return {
        ...state,
        adminAllCoaches: payload,
        adminAllCoachesLoaded: true,
      };
    case "RESET_ADMIN_ALL_COACHES":
      return {
        ...state,
        adminAllCoachesLoaded: false,
      };
    case "LOAD_FILTERED_COACHES":
      return {
        ...state,
        filteredCoaches: payload
      };
    case "LOAD_ALL_SALES_REPS":
      return {
        ...state,
        allSalesReps: payload
      };
    case "LOAD_ALL_RP_PHONE_NUMBERS":
      return {
        ...state,
        allRpPhoneNumbers: payload
      };
    case "LOAD_ALL_RP_EMAILS":
      return {
        ...state,
        allRpEmails: payload
      };
    case "LOAD_ALL_RP_NAMES":
      return {
        ...state,
        allRpNames: payload
      };
    case "LOAD_CALCULATED_COMMISSION":
      return {
        ...state,
        calculatedCommission: payload
      };
    case "LOAD_ALL_CLIENT_ASSIGNMENTS":
      return {
        ...state,
        allClientAssignments: payload,
        allClientAssignmentsLoaded: true,
      };
    case "RESET_ALL_CLIENT_ASSIGNMENTS":
      return {
        ...state,
        allClientAssignmentsLoaded: false,
      };
    case "LOAD_ALL_CLIENT_ASSIGNMENTS_GROUPED":
      return {
        ...state,
        allClientAssignmentsGrouped: payload,
        allClientAssignmentsGroupedLoaded: true,
      };
    case "RESET_ALL_CLIENT_ASSIGNMENTS_GROUPED":
      return {
        ...state,
        allClientAssignmentsGroupedLoaded: false,
      };
    case "LOAD_ADMIN_CLIENT_DETAIL":
      return {
        ...state,
        adminClientDetail: payload
      };
    case "LOAD_ADMIN_SESSIONS_BY_COACH":
      return {
        ...state,
        adminSessionsByCoach: payload,
        adminSessionsByCoachLoaded: true,
      };
    case "REST_ADMIN_SESSIONS_BY_COACH":
      return {
        ...state,
        adminSessionsByCoachLoaded: false,
      };
    case "LOAD_ADMIN_SESSIONS_BY_CLIENT":
      return {
        ...state,
        adminSessionsByClient: payload,
        adminSessionsByClientLoaded: true,
      };
    case "RESET_ADMIN_SESSIONS_BY_CLIENT":
      return {
        ...state,
        adminSessionsByClientLoaded: false,
      };
    case "LOAD_ADMIN_CLIENTS_BY_COACH":
      return {
        ...state,
        adminClientsByCoach: payload,
        adminClientsByCoachLoaded: true
      };
    case "RESET_ADMIN_CLIENTS_BY_COACH":
      return {
        ...state,
        adminClientsByCoachLoaded: false,
      };
    case "LOAD_ADMIN_CLIENTS_BY_STATE":
      return {
        ...state,
        adminClientsByState: payload,
        adminClientsByStateLoaded: true,
      };
    case "RESET_ADMIN_CLIENTS_BY_STATE":
      return {
        ...state,
        adminClientsByStateLoaded: false,
      };
    case "LOAD_ADMIN_SALES_BY_COACH":
      return {
        ...state,
        adminSalesByCoach: payload,
        adminSalesByCoachLoaded: true,
      };
    case "RESET_ADMIN_SALES_BY_COACH":
      return {
        ...state,
        adminSalesByCoachLoaded: false,
      };
    case "LOAD_ADMIN_EXPIRING_SUBSCRIPTIONS":
      return {
        ...state,
        adminExpiringSubscriptions: payload,
        adminExpiringSubscriptionsLoaded: true,
      };
    case "RESET_ADMIN_EXPIRING_SUBSCRIPTIONS":
      return {
        ...state,
        adminExpiringSubscriptionsLoaded: false,
      };
    case "LOAD_ADMIN_CLUB_RUNS_BY_COACH":
      return {
        ...state,
        adminClubRunsByCoach: payload,
        adminClubRunsByCoachLoaded: true,
      };
    case "RESET_ADMIN_CLUB_RUNS_BY_COACH":
      return {
        ...state,
        adminClubRunsByCoachLoaded: false,
      };
    case "LOAD_ADMIN_SALES_BY_CLIENT":
      return {
        ...state,
        adminSalesByClient: payload,
        adminSalesByClientLoaded: true,
      };
    case "RESET_ADMIN_SALES_BY_CLIENT":
      return {
        ...state,
        adminSalesByClientLoaded: false,
      };
    case "LOAD_ADMIN_CLUB_RUN_DETAIL":
      return {
        ...state,
        adminClubRunDetail: payload
      };
    case "LOAD_ADMIN_SESSION_DETAIL":
      return {
        ...state,
        adminSessionDetail: payload
      };
    case "LOAD_ADMIN_COACH_DETAIL":
      return {
        ...state,
        adminCoachDetail: payload
      };
    case "LOAD_ADMIN_SALE_DETAIL":
      return {
        ...state,
        adminSaleDetail: payload
      };
    case "LOAD_EXPENSE_REPORT":
      return {
        ...state,
        expenseReport: payload,
        expenseReportLoaded: true,
      };
    case "RESET_EXPENSE_REPORT":
      return {
        ...state,
        expenseReportLoaded: false,
      };
    case "LOAD_ADMIN_PAYMENT_ADJUSTMENT_LIST":
      return {
        ...state,
        adminPaymentAdjustmentList: payload,
        adminPaymentAdjustmentListLoaded: true,
      };
    case "RESET_ADMIN_PAYMENT_ADJUSTMENT_LIST":
      return {
        ...state,
        adminPaymentAdjustmentListLoaded: false,
      };
    case "LOAD_ADMIN_PAYMENT_ADJUSTMENT_DETAIL":
      return {
        ...state,
        adminPaymentAdjustmentDetail: payload
      };
    case "LOAD_ADMIN_SESSION_INACTIVITY":
      return {
        ...state,
        adminSessionInactivity: payload,
        adminSessionInactivityLoaded: true,
      };
    case "RESET_ADMIN_SESSION_INACTIVITY":
      return {
        ...state,
        adminSessionInactivityLoaded: false,
      };
    case "LOAD_ADMIN_MONTHLY_PAYMENTS":
      return {
        ...state,
        adminMonthlyPayments: payload,
        adminMonthlyPaymentsLoaded: true,
      };
    case "RESET_ADMIN_MONTHLY_PAYMENTS":
      return {
        ...state,
        adminMonthlyPaymentsLoaded: false,
      };
    case "LOAD_ADMIN_SALES_HOURS_LIST":
      return {
        ...state,
        adminSalesHours: payload,
        adminSalesHoursLoaded: true,
      }
    case "RESET_ADMIN_SALES_HOURS_LIST":
      return {
        ...state,
        adminSalesHoursLoaded: false,
      }
    case "LOAD_ADMIN_MISCELLANEOUS_TIME_LIST":
      return {
        ...state,
        adminMiscellaneousTime: payload,
        adminMiscellaneousTimeLoaded: true,
      }
    case "RESET_ADMIN_MISCELLANEOUS_TIME_LIST":
      return {
        ...state,
        adminMiscellaneousTimeLoaded: false,
      }
    case "LOAD_ADMIN_REIMBURSEMENTS":
      return {
        ...state,
        adminReimbursements: payload,
        adminReimbursementsLoaded: true,
      }
    case "RESET_ADMIN_REIMBURSEMENTS":
      return {
        ...state,
        adminReimbursementsLoaded: false,
      }
    case "LOAD_ADMIN_UNLOGGED_SESSIONS":
      return {
        ...state,
        adminUnloggedSessions: payload,
        adminUnloggedSessionsLoaded: true,
      }
    case "RESET_ADMIN_UNLOGGED_SESSIONS":
      return {
        ...state,
        adminUnloggedSessionsLoaded: false,
      }
    case "LOAD_ADMIN_MISSED_SESSIONS_BY_COACH":
      return {
        ...state,
        adminMissedSessionsByCoach: payload,
        adminMissedSessionsByCoachLoaded: true,
      }
    case "RESET_ADMIN_MISSED_SESSIONS_BY_COACH":
      return {
        ...state,
        adminMissedSessionsByCoachLoaded: false,
      }
    case "LOAD_ADMIN_ASSIGNMENT_INSIGHTS":
      return {
        ...state,
        adminAssignmentInsights: payload,
        adminAssignmentInsightsLoaded: true,
      }
    case "RESET_ADMIN_ASSIGNMENT_INSIGHTS":
      return {
        ...state,
        adminAssignmentInsightsLoaded: false,
      }
    case "LOAD_ADMIN_ASSIGNMENTS_BY_LENGTH":
      return {
        ...state,
        adminAssignmentsByLength: payload,
        adminAssignmentsByLengthLoaded: true,
      }
    case "RESET_ADMIN_ASSIGNMENTS_BY_LENGTH":
      return {
        ...state,
        adminAssignmentsByLengthLoaded: false,
      }
    case "LOAD_FEDERAL_MILEAGE_RATES":
      return {
        ...state,
        federalMileageRates: payload
      }
    case "LOAD_ADMIN_SALES_HOURS_COMMISSIONS":
      return {
        ...state,
        adminSalesHourlyCommissions: payload,
        adminSalesHourlyCommissionsLoaded: true,
      }
    case "LOAD_ADMIN_SALES_HOURS_COMMISSION_DETAIL":
      return {
        ...state,
        adminSalesHoursCommissionDetail: payload
      }
    case "RESET_ADMIN_SALES_HOURS_COMMISSIONS":
      return {
        ...state,
        adminSalesHourlyCommissionsLoaded: false,
      }
    default:
      return state;
  }
};
