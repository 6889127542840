import React, { useState } from "react";
import { Container, Row, Col, Accordion, Button, FormCheck, Table } from "react-bootstrap";
import { useMediaQuery } from "beautiful-react-hooks";
import { BorderStyle, PlusCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ExportButton } from "../ExportButton";
import Filters from "../Filters.js";
import "../Components.scss";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { TailSpin } from "react-loader-spinner";
import ReactSelect from "../Forms/ReactSelect.js";

library.add(faArrowRight);

function formatField(current_field_data, current_field_header) {
  if ([
    "total_commission",
    "program_price",
    "commission",
    "profit",
    "revenue",
    "session_commission_paid",
    "sales_commission_paid",
    "rate"
  ].includes(current_field_header)) {
    return `$${current_field_data.toLocaleString()}`
  }
  else if (["profit_margin"].includes(current_field_header)) {
    return `${current_field_data}%`
  }
  return current_field_data
}

const AccordionContainer = ({
  setFiltersClicked,
  filtersClicked,
  values,
  title,
  type,
  admin,
  children,
  handleClick,
  linkName,
  pathName,
  headers,
  buttonTitle,
  source = "Default",
  row_id,
  prefill = null,
  exportButton = false,
  exportData = [],
  handleBonusClick = null,
  handleApplyBonus = null,
  bonusExpanded = false,
  setBonusExpanded = null,
  bonusDate = null,
  handleBonusDate = null,
  showToggle = false,
  setShowToggle = null,
  summaryData = null,
  loaded = null
}) => {
  const match = useMediaQuery("(min-width: 992px)");
  return (
    <Container className="payments-container">
      <Row className="page-container mt-3">
        <Col className="top-row">
          <p className="title">{title}</p>
          {linkName && (
            <Link
              to={{
                pathname: `add_${linkName}`,
                state: { prefill: prefill, admin: admin }
              }}
              style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
              <div className="icon-button">
                <PlusCircleFill className="icon" size={15} />
                <p>{buttonTitle}</p>
              </div>
            </Link>
          )}
          {exportButton && <ExportButton contents={exportData} />}
          <div style={{ paddingRight: 10 }}></div>
          {source === "Default" && (
            <Button className="filter-button" onClick={() => setFiltersClicked(!filtersClicked)}>
              FILTERS
            </Button>
          )}
          {type == "ASSIGNMENTS BY LENGTH" && (
            <Button
              className="filter-button"
              style={{ marginLeft: "2rem" }}
              onClick={() => setBonusExpanded(!bonusExpanded)}>
              SET BONUS
            </Button>
          )}
        </Col>
      </Row>
      {filtersClicked && (
        <Row style={{ width: "100%" }}>
          <Filters
            setFiltersClicked={setFiltersClicked}
            filtersClicked={filtersClicked}
            onClick={handleClick}>
            {children}
          </Filters>
        </Row>
      )}
      {bonusExpanded && (
        <Row style={{ width: "100%" }}>
          <div className="filters-page mt-4">
            <p style={{ fontSize: "1.2rem", fontWeight: "700", color: "white" }}>SET BONUS</p>
            <div className="filters-container">
              <DatePicker
                date={bonusDate}
                showToggle={showToggle}
                setShowToggle={setShowToggle}
                label={"Bonus Payout Date"}>
                <Calendar showPreview={false} onChange={(e) => handleBonusDate(e)} />
              </DatePicker>
              <Row>
                <Col>
                  <Button id={"back"} onClick={handleApplyBonus}>
                    BACK
                  </Button>
                </Col>
                <Col>
                  <Button id={"apply"} style={{ whiteSpace: "nowrap" }} onClick={handleApplyBonus}>
                    APPLY BONUS TO SELECTION
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      )}
      {summaryData && (
        <Row style={{ marginTop: "2rem", width: "100%" }}>
          <div className="expenses-title">TOTALS</div>
          <Table
            striped
            variant="dark"
            className="expenses-table"
            responsive
            style={{ marginTop: "1rem" }}>
            <thead>
              <tr>
                {summaryData.headers.map((value, i) => {
                  return <th key={i}>{value}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr key={1}>
                {summaryData.data.map((value, i) => {
                  if (i == 0) {
                    return <td key={i}>{value}</td>;
                  } else {
                    return <td key={i}>{"$" + value.toLocaleString()}</td>;
                  }
                })}
              </tr>
            </tbody>
          </Table>
        </Row>
      )}
      <Row className="mt-2" style={{ width: "100%" }}>
        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="table-container">
            {loaded && values && values.length > 0 ? (
              values.map((value, i) => {
                return (
                  <Accordion key={i}>
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>
                        <div className="header-flex">
                          <div>
                            {(type == "CLIENTS" ||
                              type == "SALES_FOR_CLIENT" ||
                              type == "SESSIONS BY CLIENT" ||
                              type == "ASSIGNMENTS BY CLIENT" ||
                              type == "CLIENT NOTES") &&
                              value.client_name}
                            {
                              type == "ASSIGNMENTS BY LENGTH" && value.client_coach_combo
                            }
                            {(type == "CLIENTS_BY_COACH" ||
                              type == "SESSIONS" ||
                              type == "MISSED SESSIONS" ||
                              type == "SALES" ||
                              type == "EVENTS" ||
                              type == "EXPIRING SUBSCRIPTIONS" ||
                              type == "UNLOGGED_SESSIONS_BY_COACH" ||
                              type == "SESSION COMMISSIONS" ||
                              type == "SALES COMMISSIONS") &&
                              value &&
                              value.coach_name}
                            {(type == "CLIENTS_BY_STATE" || type == "REVENUE_BY_STATE") && value.address_state}
                            {(type == "PROGRAMS" || type == "SALES BY PROGRAM") && value.program_name_shortened}
                            {type === "SALES HOURS COMMISSIONS" && value.coach_name}
                          </div>
                          {type == "ASSIGNMENTS BY LENGTH" && (
                            <div>{value.months_on_program + " months"}</div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.bonus !== "Paid" && (
                              <div>
                                <div>13 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                  <FormCheck
                                    id={13}
                                    onClick={(e) => handleBonusClick(value.client_id + '_' + value.coach_id, e)}>
                                    </FormCheck>
                                </div>
                              </div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.bonus === "Paid" && (
                              <div>
                                <div>13 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                <FormCheck disabled checked={true}></FormCheck>
                                </div>
                              </div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.second_bonus !== "Paid" && (
                              <div>
                                <div>26 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                  <FormCheck
                                    id={26}
                                    onClick={(e) => handleBonusClick(value.client_id + '_' + value.coach_id, e)}>
                                    </FormCheck>
                                </div>
                              </div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.second_bonus === "Paid" && (
                              <div>
                                <div>26 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                <FormCheck disabled checked={true}></FormCheck>
                                </div>
                              </div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.third_bonus !== "Paid" && (
                              <div>
                                <div>39 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                  <FormCheck
                                    id={39}
                                    onClick={(e) => handleBonusClick(value.client_id + '_' + value.coach_id, e)}>
                                    </FormCheck>
                                </div>
                              </div>
                          )}
                          {type == "ASSIGNMENTS BY LENGTH" && value.third_bonus === "Paid" && (
                              <div>
                                <div>39 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                <FormCheck disabled checked={true}></FormCheck>
                                </div>
                              </div>
                          )}
                          <div>
                            {type == "SESSIONS BY CLIENT" && value && value.coach_name}
                            {(type == "SALES" || type == "EVENTS" || type == "SALES BY PROGRAM") &&
                              "$" + value.commission.toLocaleString()}
                            {type == "ASSIGNMENTS BY CLIENT" && value.number_of_assignments}
                            {(type == "SESSIONS" || type === "SESSIONS BY CLIENT") &&
                              title !== "SESSION INACTIVITY BY COACH" &&
                              "$" + value.total_commission.toLocaleString()}
                            {type == "ASSIGNMENTS BY LENGTH" && value.fourth !== "Paid" && (
                              <div>
                                <div>52 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                  <FormCheck
                                    id={52}
                                    onClick={(e) => handleBonusClick(value.client_id + '_' + value.coach_id, e)}>
                                    </FormCheck>
                                </div>
                            </div>
                            )}
                            {type == "ASSIGNMENTS BY LENGTH" && value.fourth_bonus === "Paid" && (
                              <div>
                                <div>52 Mo.</div>
                                <div style={{ paddingLeft: '1rem'}}>
                                  <FormCheck disabled checked={true}></FormCheck>
                                </div>
                              </div>
                          )}
                            {type == "MISSED SESSIONS" && value.missed_session_count}
                            {(type === "CLIENTS_BY_COACH" ||
                              type === "UNLOGGED_SESSIONS_BY_COACH" ||
                              type === "CLIENTS_BY_STATE" ||
                              title === "SESSION INACTIVITY BY COACH") &&
                              value.Number_of_Clients + " Clients"}
                            {type == "PROGRAMS" && value.num_programs}
                            {(type === "SALES HOURS COMMISSIONS" || type === "SALES COMMISSIONS") && value.num_commissions}
                            {type === "CLIENT NOTES" && value.total_notes}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="table-accordion">
                        {
                          <Table
                            striped
                            variant="dark"
                            className="test-table"
                            style={{ marginTop: "1rem" }}>
                            <thead>
                              <tr>
                                {headers.map((h, i) => {
                                  return <th key={i}>{h.replace("_", " ").toUpperCase()}</th>;
                                })}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {value.breakdown.map((row, i) => {
                                return (
                                  <tr key={i}>
                                    {headers.map((k, j) => {
                                      return (
                                        <td key={j}>
                                          {formatField(row[headers[j]], headers[j])}
                                        </td>
                                      );
                                    })}
                                    <td key="Detail">
                                      <Link
                                        to={{
                                          pathname: pathName,
                                          state: { id: row[row_id], admin: admin }
                                        }}
                                        key={row[row_id]}
                                        className="accordion-links">
                                        <FontAwesomeIcon icon="arrow-right" className="yellow" />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })
            ) : (
              (!loaded) ? (<TailSpin
                visible={true}
                height="60"
                width="60"
                color="#f1eb42e9"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                />) : (<div>No Data</div>)
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AccordionContainer;
