import axios from 'axios';
import {requestOptions} from '../../Utils';
import apiConstants from '../constants';
import {loaderAction} from './loaderAction';

let sessionList,
    sessionDetail,
    sessionWorksheets,
    sessionWorksheetDetail,
    sessionTopics,
    sessionsByClient,
    reimbursements,
    reimbursementDetail,
    missedSessionList,
    missedSessionDetail,
    sessionCommissionList,
    sessionCommissionDetail;

let { resetLoader, setLoader } = loaderAction;

const loadSessionListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionList'))
  if (typeof sessionList != typeof undefined) {
    sessionList.cancel('The load operation has been canceled due to a new request')
  }
  sessionList = axios.CancelToken.source()
  dispatch(resetSessionList());
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_LIST, data: filters, token: sessionList.token})).then((res) => {
    dispatch(populateSessionListData(res['data']['result']));
    dispatch(resetLoader('loadSessionList'));
  })
  .catch(err => console.log(err))
};

const loadSessionDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionDetail'))
  if (typeof sessionDetail != typeof undefined) {
    sessionDetail.cancel('The load operation has been canceled due to a new request')
  }
  sessionDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_DETAIL, data: filters, token: sessionDetail.token})).then((res) => {
    dispatch(populateSessionDetailData(res['data']['result']));
    dispatch(resetLoader('loadSessionDetail'));
  })
  .catch(err => console.log(err))
};

const loadSessionWorksheetData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionWorksheets'))
  if (typeof sessionWorksheets != typeof undefined) {
    sessionWorksheets.cancel('The load operation has been canceled due to a new request')
  }
  sessionWorksheets = axios.CancelToken.source()
  dispatch(resetWorksheetList());
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_WORKSHEETS, data: filters, token: sessionWorksheets.token})).then((res) => {
    dispatch(populateSessionWorksheetData(res['data']['result']));
    dispatch(resetLoader('loadSessionWorksheets'));
  })
  .catch(err => console.log(err))
};

const loadSessionWorksheetDetail = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionWorksheetDetail'))
  if (typeof sessionWorksheetDetail != typeof undefined) {
    sessionWorksheetDetail.cancel('The load operation has been canceled due to a new request')
  }
  sessionWorksheetDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_WORKSHEET_DETAIL, data: filters, token: sessionWorksheetDetail.token})).then((res) => {
    dispatch(populateSessionWorksheetDetail(res['data']['result']));
    dispatch(resetLoader('loadSessionWorksheetDetail'));
  })
  .catch(err => console.log(err))
};

const loadSessionTopicData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionTopics'))
  if (typeof sessionTopics != typeof undefined) {
    sessionTopics.cancel('The load operation has been canceled due to a new request')
  }
  sessionTopics = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_TOPICS, data: filters, token: sessionTopics.token})).then((res) => {
    dispatch(populateSessionTopicData(res['data']['result']));
    dispatch(resetLoader('loadSessionTopics'));
  })
  .catch(err => console.log(err))
};

const loadSessionsByClient = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionsByClient'))
  if (typeof sessionsByClient != typeof undefined) {
    sessionsByClient.cancel('The load operation has been canceled due to a new request')
  }
  sessionsByClient = axios.CancelToken.source()
  dispatch(resetSessionsByClient());
  axios(requestOptions({method: 'POST', url: apiConstants.COACH_SESSIONS_BY_CLIENT, data: filters, token: sessionsByClient.token})).then((res) => {

    dispatch(populateSessionsByClient(res['data']['result']));
    dispatch(resetLoader('loadSessionsByClient'));
  })
  .catch(err => console.log(err))
};

const loadReimbursements = (filters) => (dispatch) => {
  dispatch(setLoader('loadReimbursements'))
  if (typeof reimbursements != typeof undefined) {
    reimbursements.cancel('The load operation has been canceled due to a new request')
  }
  reimbursements = axios.CancelToken.source()
  dispatch(resetReimbursements());
  axios(requestOptions({method: 'POST', url: apiConstants.REIMBURSEMENTS_LIST, data: filters, token: reimbursements.token})).then((res) => {

    dispatch(populateReimbursements(res['data']['result']));
    dispatch(resetLoader('loadReimbursements'));
  })
  .catch(err => console.log(err))
};

const loadReimbursementDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadReimbursementDetail'))
  if (typeof reimbursementDetail != typeof undefined) {
    reimbursementDetail.cancel('The load operation has been canceled due to a new request')
  }
  reimbursementDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.REIMBURSEMENT_DETAIL, data: filters, token: reimbursementDetail.token})).then((res) => {

    dispatch(populateReimbursementDetailData(res['data']['result']));
    dispatch(resetLoader('loadReimbursementDetail'));
  })
  .catch(err => console.log(err))
};

const loadMissedSessionListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadMissedSessionList'))
  if (typeof missedSessionList != typeof undefined) {
    missedSessionList.cancel('The load operation has been canceled due to a new request')
  }
  missedSessionList = axios.CancelToken.source()
  dispatch(resetMissedSessionListData());
  axios(requestOptions({method: 'POST', url: apiConstants.MISSED_SESSION_LIST, data: filters, token: missedSessionList.token})).then((res) => {
    dispatch(populateMissedSessionListData(res['data']['result']));
    dispatch(resetLoader('loadMissedSessionList'));
  })
  .catch(err => console.log(err))
};

const loadMissedSessionDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadMissedSessionDetail'))
  if (typeof missedSessionDetail != typeof undefined) {
    missedSessionDetail.cancel('The load operation has been canceled due to a new request')
  }
  missedSessionDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.MISSED_SESSION_DETAIL, data: filters, token: missedSessionDetail.token})).then((res) => {
    dispatch(populateMissedSessionDetailData(res['data']['result']));
    dispatch(resetLoader('loadMissedSessionDetail'));
  })
  .catch(err => console.log(err))
};

const loadSessionCommissionListData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionCommissionList'))
  if (typeof sessionCommissionList != typeof undefined) {
    sessionCommissionList.cancel('The load operation has been canceled due to a new request')
  }
  sessionCommissionList = axios.CancelToken.source()
  dispatch(resetSessionCommissionList());
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_COMMISSION_LIST, data: filters, token: sessionCommissionList.token})).then((res) => {
    dispatch(populateSessionCommissionListData(res['data']));
    dispatch(resetLoader('loadSessionCommissionList'));
  })
  .catch(err => console.log(err))
};

const loadSessionCommissionDetailData = (filters) => (dispatch) => {
  dispatch(setLoader('loadSessionCommissionDetail'))
  if (typeof sessionCommissionDetail != typeof undefined) {
    sessionCommissionDetail.cancel('The load operation has been canceled due to a new request')
  }
  sessionCommissionDetail = axios.CancelToken.source()
  axios(requestOptions({method: 'POST', url: apiConstants.SESSION_COMMISSION_DETAIL, data: filters, token: sessionCommissionDetail.token})).then((res) => {
    dispatch(populateSessionCommissionDetailData(res['data']['result']));
    dispatch(resetLoader('loadSessionCommissionDetail'));
  })
  .catch(err => console.log(err))
};

const populateSessionCommissionListData = (data) => ({
  type: 'LOAD_SESSION_COMMISSION_LIST',
  payload: data
});

const resetSessionCommissionList = () => ({
  type: 'RESET_SESSION_COMMISSION_LIST',
  sessionCommissionListLoaded: false,
})

const populateSessionCommissionDetailData = (data) => ({
  type: 'LOAD_SESSION_COMMISSION_DETAIL',
  payload: data
});

const populateSessionListData = (data) => ({
  type: 'LOAD_SESSION_LIST',
  payload: data
});

const resetSessionList = () => ({
  type: 'RESET_SESSION_LIST',
  sessionListLoaded: false
});

const populateSessionDetailData = (data) => ({
  type: 'LOAD_SESSION_DETAIL',
  payload: data
});

const populateSessionWorksheetData = (data) => ({
  type: 'LOAD_SESSION_WORKSHEET',
  payload: data
});

const populateSessionWorksheetDetail = (data) => ({
  type: 'LOAD_SESSION_WORKSHEET_DETAIL',
  payload: data
});

const resetWorksheetList = () => ({
  type: 'RESET_WORKSHEET_LIST',
  worksheetListLoaded: false
});

const populateSessionTopicData = (data) => ({
  type: 'LOAD_SESSION_TOPIC',
  payload: data
});

const populateSessionsByClient = (data) => ({
  type: 'LOAD_SESSIONS_BY_CLIENT',
  payload: data
});

const resetSessionsByClient = () => ({
  type: 'RESET_SESSIONS_BY_CLIENT',
  sessionsByClientLoaded: false,
});

const populateReimbursements = (data) => ({
  type: 'LOAD_REIMBURSEMENTS',
  payload: data
});

const resetReimbursements = () => ({
  type: 'RESET_REIMBURSEMENTS',
  reimbursementsLoaded: false,
});

const populateReimbursementDetailData = (data) => ({
  type: 'LOAD_REIMBURSEMENT_DETAIL',
  payload: data
});

const populateMissedSessionListData = (data) => ({
  type: 'LOAD_MISSED_SESSION_LIST',
  payload: data
});

const resetMissedSessionListData = () => ({
  type: 'RESET_MISSED_SESSION_LIST',
  missedSessionListLoaded: false,
});

const populateMissedSessionDetailData = (data) => ({
  type: 'LOAD_MISSED_SESSION_DETAIL',
  payload: data
});

const resetData = () => ({
  type: 'RESET_DATA'
})

export const sessionAction = {
  loadSessionListData,
  loadSessionDetailData,
  loadSessionWorksheetData,
  loadSessionWorksheetDetail,
  loadSessionTopicData,
  loadSessionsByClient,
  loadReimbursements,
  loadReimbursementDetailData,
  loadMissedSessionListData,
  loadMissedSessionDetailData,
  loadSessionCommissionListData,
  loadSessionCommissionDetailData,
  resetData
}
