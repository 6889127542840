const initialState = {
  clientList: [],
  clientDetail: [],
  assignmentDetail: [],
  registrationKey: '',
  clientListLoaded: false,
  clientNotesList: [],
  clientNotesDetail: [],
  clientNotesListLoaded: false,
};

export const clientReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_CLIENT_LIST':
      return {
        ...state,
        clientList: payload,
        clientListLoaded: true,
      }
    case "RESET_CLIENT_LIST":
      return {
        ...state,
        clientListLoaded: false,
      };
    case 'LOAD_CLIENT_DETAIL':
      return {
        ...state,
        clientDetail: payload
      }
    case 'LOAD_ASSIGNMENT_DETAIL':
      return {
        ...state,
        assignmentDetail: payload
      }
    case 'LOAD_REGISTRATION_KEY':
      return {
        ...state,
        registrationKey: payload
      }
    case 'LOAD_CLIENT_NOTES_LIST':
      return {
        ...state,
        clientNotesList: payload,
        clientNotesListLoaded: true,
      }
    case "RESET_CLIENT_NOTES_LIST":
      return {
        ...state,
        clientNotesListLoaded: false,
      };
    case 'LOAD_CLIENT_NOTES_DETAIL':
      return {
        ...state,
        clientNotesDetail: payload
      }
    default:
      return state
  }
};
