const initialState = {
  coachDetail: [],
  updatedCoachDetail: {},
  miscellaneousTime: [],
  miscellaneousTimeLoaded: false,
  miscellaneousTimeDetail: []
};

export const coachDetailReducer = (state = initialState, {type, payload }) => {
  switch (type) {
    case 'LOAD_COACH_DETAIL':
      return {
        ...state,
        coachDetail: payload
      }
    case 'UPDATE_COACH_DETAIL':
      return {
        ...state,
        updatedCoachDetail: payload
      }
    case 'LOAD_MISCELLANEOUS_TIME':
      return {
        ...state,
        miscellaneousTime: payload,
        miscellaneousTimeLoaded: true,
      }
    case 'RESET_MISCELLANEOUS_TIME':
      return {
        ...state,
        miscellaneousTimeLoaded: false,
      }
    case 'LOAD_MISCELLANEOUS_TIME_DETAIL':
      return {
        ...state,
        miscellaneousTimeDetail: payload
      }
    default:
      return state
  }
};
