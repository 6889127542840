import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/ListViews/TableContainer";
import { adminAction, filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { insert_data } from "../../Utils/insertData";

const AdminCoachList = (props) => {
  const dispatch = useDispatch();
  // get list of coaches from db
  const coachList = useSelector((state) => state.adminReducer.allCoaches);
  // get filtered list of coaches from db
  const adminAllCoaches = useSelector((state) => state.adminReducer.adminAllCoaches);
  const adminAllCoachesLoaded = useSelector((state) => state.adminReducer.adminAllCoachesLoaded);
  // values for the selects
  const [coachOptions, setCoachOptions] = useState({ value: "", label: "" });
  // filters that user saved from db
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  // activates on fliter button clicked, updates state to show filter options
  const [filtersClicked, setFiltersClicked] = useState(false);
  // used to register new filters/hide filter options after click
  const [applyClicked, setApplyClicked] = useState(false);
  // coaches selected from select box
  const [coachesSelected, setCoachesSelected] = useState([]);
  // status selected from select box
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  // default filters, but also options that will be sent to the db
  const [filters, setFilters] = useState({ is_active: ["Active"], coaches: ["All"] });
  // default statuses
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "All Coaches" } }));
    dispatch(adminAction.loadAllCoaches({ filters: filters }));
  }, []);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminAllCoaches({ filters: savedUserFilters }));

      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminAllCoaches({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    if (coachList.length > 0) {
      let sr_list_db = coachList.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachOptions(sr_list_db);
    }
  }, [coachList]);

  useEffect(() => {
    dispatch(adminAction.loadAdminAllCoaches({ filters: filters }));
  }, [applyClicked]);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "Active", label: "Active" }]);
        } else {
          let newVals = e.filter((item) => item.value != "Active");
          setStatusSelected(newVals);
        }
        break;
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          // remove default value if new one is selected
          let newVals = e.filter((item) => item.value != "All");
          setCoachesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let coach_arr = coachesSelected.map((data) => data.value);
      let filtersObj = {
        is_active: status_arr,
        coaches: coach_arr,
        page_name: "All Coaches"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const exportData =
    adminAllCoaches
    .map((v) => {
        const exportRows = [];
        const { coach_id, full_name, ...rest } = v;
        exportRows.push({
          full_name,
            ...rest,
        });
        return exportRows;
    })
    .flat() ?? [];

  return (
    <>
      <TableContainer
        values={adminAllCoaches}
        itemId={"coach_id"}
        itemLeftLabel={"full_name"}
        itemRightLabel={"primary_time_zone"}
        title="ALL COACHES"
        pathname="/coach_detail"
        filtersClicked={filtersClicked}
        filterButton={true}
        loaded={adminAllCoachesLoaded}
        admin={true}
        filters={{
          setFiltersClicked,
          handleClick
        }}
        exportButton={true}
        exportData={exportData}
        >
        <ReactSelect
          options={coachOptions.length > 0 ? coachOptions : []}
          value={coachesSelected}
          isMulti={true}
          placeholder="Coaches"
          handleChange={handleChange}
        />
        <ReactSelect
          options={statuses}
          value={statusSelected}
          isMulti={true}
          placeholder="Status"
          handleChange={handleChange}
        />
      </TableContainer>
    </>
  );
};

export default AdminCoachList;
