import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Input from "../../Components/Forms/Input";
import Select from "../../Components/Forms/Select";
import ReactSelect from "../../Components/Forms/ReactSelect";
import TextArea from "../../Components/Forms/TextArea";
import FormContainer from "../../Components/Forms/FormContainer";
import { Col, Container, Row, Form, Image, FloatingLabel } from "react-bootstrap";
import { sessionAction, adminAction } from "../../Redux/actions";
import routingConstants from "../../Navigation/constants";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { clientTypeList, sessionTypeList, workshopNumberList } from "../../Utils/selectConstants";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "react-date-range";
import DatePicker from "../../Components/Forms/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const SessionCommissionForm = (props) => {
    const [coachName, setCoachName] = useState([{value: "", label: "" }]);
    const [individual, setIndividual] = useState(15);
    const [teamInPerson, setTeamInPerson] = useState(35);
    const [teamVideo, setTeamVideo] = useState(25);
    const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
    const [corporate, setCorporate] = useState(40);
    const [coachList, setCoachList] = useState([]);
    const [events, setEvents] = useState(65);
    const [sessionCommissionStart, setSessionCommissionStart] = useState(new Date().toLocaleDateString());
    const [sessionCommissionEnd, setSessionCommissionEnd] = useState(new Date().toLocaleDateString());
    const dispatch = useDispatch();
    const toastId = useRef(null);
    const [resetData, setResetData] = useState(false);
    const [showToggle1, setShowToggle1] = useState(false);
    const [showToggle2, setShowToggle2] = useState(false);

  const handleClick = (e) => {
    if (e.target.id == "add") {
        const req_obj = {
            coach_name: coachName.value,
            individual: individual,
            team_in_person: teamInPerson,
            team_video: teamVideo,
            corporate: corporate,
            club_run: events,
            session_commission_start_date: sessionCommissionStart,
            session_commission_end_date: sessionCommissionEnd
        };
        if (
            coachName.value !== "" &&
            sessionCommissionStart !== "" &&
            sessionCommissionEnd !== "" &&
            individual !== "" &&
            teamInPerson !== "" &&
            teamVideo !== "" &&
            corporate !== "" &&
            events !== ""
        ) {
            insert_data(req_obj);
        } else {
            toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
        }
    } else {
      reset_data();
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
  }, [dispatch]);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let coach_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      coach_list_db.push({ value: "", label: "" });
      setCoachList(coach_list_db);
    }
  }, [coachList_db]);

  const insert_data = (req_obj) => {
    axios(
        requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_SESSION_COMMISSION, data: req_obj })
      ).then((res) => {
        if (res.data.message === "Success") {
            toastId.current = toast.success(
              "You’ve successfully inserted a new session commission",
              { position: "top-center" }
            );
            reset_data();
          } else if (res.data.message === "Duplicate") {
            toastId.current = toast.error(
              "You’ve already inserted session commission for that coach for that time period",
              { position: "top-center" }
            );
          } else {
            toastId.current = toast.error(
              "There was an error inserting a new session commission",
              { position: "top-center" }
            );
          }
        })
        .catch((err) => {
          toastId.current = toast.error("There was an error inserting session commission data", {
            position: "top-center"
          });
        });
  }

  const reset_data = () => {
    setCoachName("");
    setIndividual(0);
    setTeamInPerson(0);
    setTeamVideo(0);
    setEvents(0);
    setCorporate(0);
    setSessionCommissionStart(new Date().toLocaleDateString());
    setSessionCommissionEnd(new Date().toLocaleDateString());
  }

  const handleChange = (selector, e) => {
    switch (selector) {
        case "Coach Name":
            setCoachName(e);
            break;
        case "Individual":
            setIndividual(e.target.value);
            break;
        case "Team In-Person":
            setTeamInPerson(e.target.value);
            break;
        case "Team Video":
            setTeamVideo(e.target.value);
            break;
        case "Corporate":
            setCorporate(e.target.value);
            break;
        case "Events/Club Runs":
            setEvents(e.target.value);
            break;
        default:
            break;
    }
  };

  const handleStartChange = (e) => {
    const selectedDate = new Date(e);
    setSessionCommissionStart(selectedDate.toLocaleDateString());
  };
  const handleEndChange = (e) => {
    const selectedDate = new Date(e);
    setSessionCommissionEnd(selectedDate.toLocaleDateString());
  };

  return (
      <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="LOG A SESSION" onClick={handleClick}>
        <ReactSelect
            options={coachList}
            value={coachName}
            placeholder="Coach Name"
            handleChange={handleChange}
        />
        <Input
            value={individual}
            label="Individual"
            id={"Individual"}
            onChange={(e) => handleChange("Individual", e)}
        />
        <Input
            value={teamInPerson}
            label="Team In-Person"
            id={"Team In-Person"}
            onChange={(e) => handleChange("Team In-Person", e)}
        />
        <Input
            value={teamVideo}
            label="Team Video"
            id={"Team Video"}
            onChange={(e) => handleChange("Team Video", e)}
        />
        <Input
            value={corporate}
            label="Corporate"
            id={"Corporate"}
            onChange={(e) => handleChange("Corporate", e)}
        />
        <Input
            value={events}
            label="Events/Club Runs"
            id={"Events/Club Runs"}
            onChange={(e) => handleChange("Events/Club Runs", e)}
        />
        <DatePicker
          date={sessionCommissionStart}
          showToggle={showToggle1}
          setShowToggle={setShowToggle1}
          label={"Session Commission Start Date"}
          title={"COMMISSION START"}>
          <Calendar showPreview={false} onChange={(e) => handleStartChange(e)} />
        </DatePicker>
        <DatePicker
          date={sessionCommissionEnd}
          showToggle={showToggle2}
          setShowToggle={setShowToggle2}
          label={"Session Commission End Date"}
          title={"COMMISSION END"}>
          <Calendar showPreview={false} onChange={(e) => handleEndChange(e)} />
        </DatePicker>
      </FormContainer>
      </>
  );
};

export default SessionCommissionForm;
