import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { states } from "../../Utils/arrayItems";
import { insert_data } from "../../Utils/insertData";

const AdminClientsByState = () => {
  const dispatch = useDispatch();
  const stateClients = useSelector((state) => state.adminReducer.adminClientsByState);
  const stateClientsLoaded = useSelector((state) => state.adminReducer.adminClientsByStateLoaded);
  const clientList_db = useSelector((state) => state.adminReducer.allClients);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [clientList, setClientList] = useState({ value: "All", label: "All" });
  const [clientsSelected, setClientsSelected] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team"],
    is_active: ["Active"],
    clients: ["All"],
    address_state: ["All"]
  });

  const clientTypes = [
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ]);
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];
  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Clients by State" } }));
    dispatch(adminAction.loadAllClients({ filters: { is_active: ["Active"] } }));
  }, []);

  useEffect(() => {
    if (clientList_db.length > 0) {
      let sr_list_db = clientList_db.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientList(sr_list_db);
    }
  }, [clientList_db]);

  const handleChange = (selector, e) => {
    let newVals = e.filter((item) => item.value != "All");
    switch (selector) {
      case "State":
        if (e.length == 0) {
          setStatesSelected([{ value: "All", label: "All" }]);
        } else {
          setStatesSelected(newVals);
        }
        break;
      case "Client":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([
            { value: "Athlete", label: "Athlete" },
            { value: "Team", label: "Team" }
          ]);
        } else {
          setClientTypesSelected(e);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "Active", label: "Active" }]);
        } else {
          setStatusSelected(e);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminClientsByState({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "is_active") {
          setStatusSelected(temp_array);
        } else if (key == "clients") {
          setClientsSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "address_state") {
          setStatesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminClientsByState({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let clients_arr = clientsSelected.map((client) => client.value);
      let states_arr = statesSelected.map((state) => state.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: clients_arr,
        address_state: states_arr,
        page_name: "Clients by State"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAdminClientsByState({ filters: filters }));
  }, [applyClicked]);

  const exportData = Array.isArray(stateClients) ? stateClients.map((v) => v.breakdown).flat() : [];

  return (
    <AccordionContainer
      values={stateClients}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="CLIENTS BY STATE"
      type="CLIENTS_BY_STATE"
      headers={["full_name", "client_type"]}
      row_id={"client_id"}
      pathName={"client_detail"}
      admin={true}
      handleClick={handleClick}
      exportButton={true}
      loaded={stateClientsLoaded}
      exportData={exportData}>
      <ReactSelect
        options={states}
        value={statesSelected}
        isMulti={true}
        placeholder="State"
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientList}
        value={clientsSelected}
        isMulti={true}
        placeholder="Client"
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypes}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={statuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
      />
    </AccordionContainer>
  );
};

export default AdminClientsByState;
