import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import { clientTypeList } from "../../Utils/selectConstants";

const AdminClientsByCoach = () => {
  const dispatch = useDispatch();
  const coachClients = useSelector((state) => state.adminReducer.adminClientsByCoach);
  const coachClientsLoaded = useSelector((state) => state.adminReducer.adminClientsByCoachLoaded);
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [coachList, setCoachList] = useState({ value: "All", label: "All" });
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team", "Corporate"],
    is_active: ["Active"],
    coaches: ["All"]
  });

  const clientTypes = ["Athlete", "Team", "Corporate"];
  const [clientTypesSelected, setClientTypesSelected] = useState(clientTypeList);
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];
  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Clients by Coach" } }));
    dispatch(adminAction.loadAllCoaches({ filters: filters }));
  }, []);

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  const handleChange = (selector, e) => {
    let newVals = [];
    newVals = e.filter((item) => item.value != "All");
    switch (selector) {
      case "Coach":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        coaches: coaches_arr,
        page_name: "Clients by Coach"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminClientsByCoach({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminClientsByCoach({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminClientsByCoach({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={coachClients}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="CLIENTS BY COACH"
      type="CLIENTS_BY_COACH"
      headers={["full_name", "assignment_program"]}
      row_id="client_id"
      admin={true}
      loaded={coachClientsLoaded}
      handleClick={handleClick}>
      <ReactSelect
        options={coachList}
        value={coachesSelected}
        isMulti={true}
        placeholder="Coach"
        handleChange={handleChange}
      />
      <ReactSelect
        options={clientTypeList}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
      />
      <ReactSelect
        options={statuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
      />
    </AccordionContainer>
  );
};

export default AdminClientsByCoach;
