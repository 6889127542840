import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FormContainer from "../../Components/Forms/FormContainer";
import TextArea from "../../Components/Forms/TextArea";
import API_CONSTANTS from "../../Redux/constants";
import { requestOptions } from "../../Utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../Components/Forms/Input";

const AdminWorksheetForm = (props) => {
  const userInfo = useSelector((state) => state.authReducer.persona);
  const [worksheetName, setWorksheetName] = useState();
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleString("default"))
  const dispatch = useDispatch();
  const toastId = useRef(null);

  const handleChange = (selector, e) => {
    switch (selector) {
      case "Worksheet Name":
        setWorksheetName(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (e.target.id == "add") {
      const req_obj = {
        worksheet_name: worksheetName
      };
      if (
        worksheetName.value !== ""
      ) {
        insert_data(req_obj);
      } else {
        
        toastId.current = toast.error("Please fill in all form fields", { position: "top-center" });
      }
    }
  };

  const insert_data = (req_obj) => {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.INSERT_WORKSHEET, data: req_obj }))
      .then((res) => {
        if (res.data.message === "Success") {
          toastId.current = toast.success(
            "You’ve successfully inserted a new worksheet named: " + req_obj["worksheet_name"],
            { position: "top-center" }
          );
          reset_data();
        } else if (res.data.message === "Duplicate") {
          toastId.current = toast.error(
            "You’ve already inserted a worksheet named: " + req_obj["worksheet_name"],
            { position: "top-center" }
          );
        } else {
          toastId.current = toast.error(
            "There was an error inserting a new worksheet named: " + req_obj["worksheet_name"],
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error inserting the new worksheet", {
          position: "top-center"
        });
      });
  };

  function reset_data() {
    setWorksheetName("");
  }

  return (
    <>
      <ToastContainer limit={1} theme="dark" />
      <FormContainer title="CREATE A NEW WORKSHEET" onClick={handleClick}>
        <TextArea
          label="Worksheet Name"
          height={"300px"}
          onChange={(e) => handleChange("Worksheet Name", e)}
          value={worksheetName}
        />
        <Input
          label="Current Date"
          type="text"
          disabled={true}
          value={currentDate}
        />
      </FormContainer>
    </>
  );
};

export default AdminWorksheetForm;
