import React, { useState, useEffect } from "react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { useDispatch, useSelector } from "react-redux";
import { months, years } from "../../Utils/arrayItems";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction, clientAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { insert_data } from "../../Utils/insertData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";

const AdminClientAssignments = () => {
  const dispatch = useDispatch();
  const clientAssignments = useSelector((state) => state.adminReducer.allClientAssignmentsGrouped);
  const clientAssignmentsLoaded = useSelector((state) => state.adminReducer.allClientAssignmentsGroupedLoaded);
  const clientList = useSelector((state) => state.adminReducer.allClients);
  const [clientOptions, setClientOptions] = useState({ value: "All", label: "All" });
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [applyClicked, setApplyClicked] = useState(false);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" }
  ]);
  const [clientsSelected, setClientsSelected] = useState([{ value: "All", label: "All" }]);
  const [statusSelected, setStatusSelected] = useState([{ value: "Active", label: "Active" }]);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Admin Client Assignments" } })
    );
    dispatch(adminAction.loadAllClients({ filters: { is_active: ["Active", "Inactive"] } }));
  }, []);

  useEffect(() => {
    if (clientList.length > 0) {
      let sr_list_db = clientList.map((client) => {
        return {
          value: `${client.full_name} (${client.client_id})`,
          label: `${client.full_name} (${client.client_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setClientOptions(sr_list_db);
    }
  }, [clientList]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    switch (selector) {
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([{ value: "All", label: "All" }]);
        } else {
          setClientTypesSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusSelected(newVals);
        }
        break;
      case "Clients":
        if (e.length == 0) {
          setClientsSelected([{ value: "All", label: "All" }]);
        } else {
          setClientsSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  const clientTypes = [
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];
  const statuses = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
  ];

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let status_arr = statusSelected.map((data) => data.value);
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let client_arr = clientsSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        is_active: status_arr,
        clients: client_arr,
        page_name: "Admin Client Assignments"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAllClientAssignmentsGrouped({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "clientType") {
          setClientTypesSelected(temp_array);
        } else if (key == "is_active") {
          setStatusSelected(temp_array);
        } else if (key == "clients") {
          setClientsSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAllClientAssignmentsGrouped({ filters: filters }));
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAllClientAssignmentsGrouped({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={clientAssignments}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="ASSIGNMENTS BY CLIENT"
      type="ASSIGNMENTS BY CLIENT"
      buttonTitle="CREATE AN ASSIGNMENT"
      linkName="assignment"
      headers={["assignment_program", "assignment_start_date", "assignment_end_date", "coach_name"]}
      row_id={"assignment_id"}
      pathName={"assignment_detail"}
      loaded={clientAssignmentsLoaded}
      admin={true}
      handleClick={handleClick}>
      <ReactSelect
        options={clientOptions}
        value={clientsSelected}
        isMulti={true}
        placeholder="Clients"
        handleChange={handleChange}
        id={1}
      />
      <ReactSelect
        options={clientTypes}
        value={clientTypesSelected}
        isMulti={true}
        placeholder="Client Type"
        handleChange={handleChange}
        id={2}
      />
      <ReactSelect
        options={statuses}
        value={statusSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
        id={3}
      />
    </AccordionContainer>
  );
};

export default AdminClientAssignments;
