import Input from "../../Components/Forms/Input";
import ReactSelect from "../../Components/Forms/ReactSelect";
import React, { useState } from "react";
import FormGroup from "./FormGroup.js";
import {
  desiredCoachExperienceLevelOptions,
  energyLevelPreferenceOptions,
  experienceList,
  faithBasedOptions,
  lessonsImportanceOptions,
  personalityTypeList,
  stateList,
  salesSourceList,
  genderOptionsList
} from "../../Utils/selectConstants";
import insertSignUpData from "../../Utils/insertSignUpData.js";
import { Button, Form, Row } from "react-bootstrap";

const NewCorporateForm = ({
  firstName,
  lastName,
  nickname,
  gender,
  email,
  phone,
  address,
  city,
  state,
  trainingLevel,
  school,
  club,
  experience,
  personalityType,
  faithBased,
  goals,
  aspirations,
  additionalInfo,
  lessonsImportance,
  dateOfBirth,
  rpName,
  rpPhone,
  rpEmail,
  rpRelation,
  rpInvolvement,
  teamExperienceLevel,
  teamName,
  profession,
  sports,
  salesSource,
  salesSourceDetailsLabel,
  salesSourceDetails,
  energyLevelPreference,
  desiredCoachExperienceLevel,
  timezone,
  preferredSessionAvailability,
  handleChange,
  clientType,
  reset_data
}) => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // do nothing
    } else {
      insertSignUpData(req_obj, clientType, reset_data);
    }

    setValidated(true);
  };

  const req_obj = {
    is_active: 1,
    client_type: clientType.value,
    first_name: firstName,
    last_name: lastName,
    nickname: nickname,
    gender: gender.value,
    team_name: teamName,
    email: email,
    phone: phone,
    address: address,
    city: city,
    profession: profession,
    address_state: state.value,
    training_level: trainingLevel.value,
    school: school,
    club: club,
    experience: experience.value,
    personality_type: personalityType.value,
    date_of_birth: dateOfBirth,
    responsible_person_name: rpName,
    responsible_person_phone: rpPhone,
    responsible_person_email: rpEmail,
    responsible_person_relationship: rpRelation,
    responsible_person_involvement: rpInvolvement.value,
    faith_based: faithBased.value,
    goals: goals,
    other_aspirations: aspirations,
    additional_info: additionalInfo,
    energy_level_preference: energyLevelPreference.value,
    desired_experience_of_coach: desiredCoachExperienceLevel.value,
    sports: sports.map((data) => data.value),
    importance_of_lessons: lessonsImportance.value,
    sales_source: salesSource.value,
    sales_source_details: salesSourceDetails,
    primary_time_zone: timezone.value,
    preferred_session_availability: preferredSessionAvailability
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3 mt-3">
        <FormGroup
          type="text"
          value={firstName}
          handleChange={handleChange}
          required={true}
          label={"First Name"}
          placeholder={"First Name"}
          error_message={"Please enter your First Name."}
        />
        <FormGroup
          type="text"
          value={lastName}
          handleChange={handleChange}
          required={true}
          label={"Last Name"}
          placeholder={"Last Name"}
          error_message={"Please enter your Last Name."}
        />
        <FormGroup
          type="text"
          value={nickname}
          handleChange={handleChange}
          label={"Nickname"}
          placeholder={"Nickname"}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="select"
          value={gender.value}
          handleChange={handleChange}
          required={true}
          selector={"Gender"}
          label={"Gender"}
          options={genderOptionsList}
          error_message={"Please select a gender."}
          defaultVal={"Select a Gender"}
        />
        <FormGroup
          type="text"
          value={email}
          handleChange={handleChange}
          required={true}
          label={"Email"}
          placeholder={"Email"}
          error_message={"Please enter your email address."}
        />
        <FormGroup
          type="text"
          value={phone}
          handleChange={handleChange}
          required={true}
          label={"Phone"}
          placeholder={"Phone"}
          error_message={"Please enter your phone number."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="text"
          value={address}
          handleChange={handleChange}
          required={true}
          label={"Street Address"}
          placeholder={"Street Address"}
          error_message={"Please enter your street address."}
        />
        <FormGroup
          type="text"
          value={city}
          handleChange={handleChange}
          required={true}
          label={"City/Town"}
          placeholder={"City/Town"}
          error_message={"Please enter your city/town."}
        />
        <FormGroup
          type="select"
          value={state.value}
          handleChange={handleChange}
          required={true}
          selector={"State"}
          label={"State"}
          options={stateList}
          error_message={"Please select a state."}
          defaultVal={"Select a State"}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="text"
          value={profession}
          handleChange={handleChange}
          required={true}
          label={"Profession"}
          placeholder={"Profession"}
          error_message={"Please enter your Profession."}
        />
        <FormGroup
          type="select"
          value={experience.value}
          handleChange={handleChange}
          required={true}
          selector={"Job Experience"}
          label={"Job Experience"}
          options={experienceList}
          error_message={"Please select a value."}
        />
        <FormGroup
          type="select"
          value={personalityType.value}
          handleChange={handleChange}
          required={true}
          selector={"Personality Type"}
          label={"Personality Type"}
          options={personalityTypeList}
          error_message={"Please select a value."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="select"
          value={faithBased.value}
          handleChange={handleChange}
          required={true}
          selector={"Would you like to incorporate the Christian faith into your training?"}
          label={"Would you like to incorporate the Christian faith into your training?"}
          options={faithBasedOptions}
          error_message={"Please select a value."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={goals}
          handleChange={handleChange}
          placeholder={"Goals"}
          rows={5}
          label={"Goals"}
          error_message={"Please enter your goals."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={aspirations}
          handleChange={handleChange}
          placeholder={"Aspirations outside of athletics"}
          rows={5}
          label={"Aspirations outside of athletics"}
          error_message={"Please enter your aspirations."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="select"
          value={energyLevelPreference.value}
          handleChange={handleChange}
          required={true}
          selector={"Do you prefer a Mindset Coach with low or high energy?"}
          label={"Do you prefer a Mindset Coach with low or high energy?"}
          options={energyLevelPreferenceOptions}
          error_message={"Please select a value."}
        />
        <FormGroup
          type="select"
          value={desiredCoachExperienceLevel.value}
          handleChange={handleChange}
          required={true}
          selector={"Desired age range of assigned Mindset Coach"}
          label={"Desired age range of assigned Mindset Coach"}
          options={desiredCoachExperienceLevelOptions}
          error_message={"Please select a value."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          as={"textarea"}
          value={additionalInfo}
          handleChange={handleChange}
          placeholder={"Additional info that will help match you with a Mindset Coach"}
          rows={5}
          label={"Additional info that will help match you with a Mindset Coach"}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="select"
          value={lessonsImportance.value}
          handleChange={handleChange}
          required={true}
          selector={"Importance of learning life lessons in the program"}
          label={"Importance of learning life lessons in the program"}
          options={lessonsImportanceOptions}
          error_message={"Please select a value."}
        />
        <FormGroup
          type="date"
          value={dateOfBirth}
          handleChange={handleChange}
          required={true}
          label={"Date of Birth"}
          error_message={"Please enter your date of birth."}
        />
      </Row>
      <Row className="mt-3">
        <FormGroup
          type="text"
          value={rpName}
          handleChange={handleChange}
          required={true}
          label={"Responsible Person Name"}
          placeholder={"Responsible Person Name"}
          error_message={"Please enter your Responsible Person Name."}
        />
        <FormGroup
          type="text"
          value={rpPhone}
          handleChange={handleChange}
          required={true}
          label={"Responsible Person Phone"}
          placeholder={"Responsible Person Phone Number"}
          error_message={"Please enter your Responsible Person Phone Number."}
        />
        <FormGroup
          type="text"
          value={rpEmail}
          handleChange={handleChange}
          required={true}
          label={"Responsible Person Email"}
          placeholder={"Responsible Person Email"}
          error_message={"Please enter your Responsible Person Email."}
        />
        <FormGroup
          type="text"
          value={rpRelation}
          handleChange={handleChange}
          required={true}
          label={"Responsible Person Relationship"}
          placeholder={"Responsible Person Relationship"}
          error_message={"Please enter your Responsible Person Relationship."}
        />
      </Row>
      <Row className="mt-3">
        <ReactSelect
          options={salesSourceList}
          value={salesSource}
          placeholder="How did you hear about Winning Mindset?"
          handleChange={handleChange}
        />
        {salesSource.value === "Team/Club Presentation" ||
        salesSource.value === "Convention" ||
        salesSource.value == "Referral" ||
        salesSource.value == "Social Media" ||
        salesSource.value == "Tournament" ||
        salesSource.value == "Mindset Coach" ? (
          <Input
            label={salesSourceDetailsLabel}
            type="text"
            value={salesSourceDetails}
            onChange={(e) => handleChange(salesSourceDetailsLabel, e)}
          />
        ) : (
          <div></div>
        )}
      </Row>
      <Button className="mt-3" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default NewCorporateForm;
