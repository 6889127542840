import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./SaleDetail.scss";
import SaleDetailForm from "./SaleDetailForm";
import { saleAction, adminAction } from "../../Redux/actions";
import DeleteButton from "../../Components/DeleteButton";
import API_CONSTANTS from '../../Redux/constants';
import { requestOptions } from '../../Utils';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import MindsetMondaySaleDetailForm from "./MindsetMondayDetailForm";

const SaleDetail = (props) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const saleDetail = useSelector((state) => state.saleReducer.saleDetail);
  const adminSaleDetail = useSelector((state) => state.adminReducer.adminSaleDetail);
  const [saleDetailFinal, setSaleDetailFinal] = useState({});
  const [detailEdited, setDetailEdited] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    //console.log(props.location.state)
    if (props.location.state && props.location.state.admin === true) {
      dispatch(adminAction.loadAdminSaleDetail({ filters: { sale_id: props.location.state.id } }));
    } else {
      dispatch(saleAction.loadSaleDetailData({ filters: { sale_id: props.location.state.id } }));
    }
  }, [detailEdited, dispatch]);

  useEffect(() => {
    if (props.location.state && props.location.state.admin == true) {
      setSaleDetailFinal(adminSaleDetail);
    } else {
      setSaleDetailFinal(saleDetail);
    }
  }, [saleDetail, adminSaleDetail]);

  const handleDetailEditedChange = () => {
    setDetailEdited(!detailEdited);
  };

  const refresh = () => {
    dispatch(saleAction.loadSaleDetailData({ filters: { sale_id: props.location.state.id } }));
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleCancel = () => {
    setDeleteClicked(!deleteClicked);
  }

  const handleDelete = () => {
    setDeleteClicked(!deleteClicked);
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.DELETE_SALE, data: {'sale_id': saleDetailFinal.sale_id} }))
      .then((res) => {
        if (res) {
          history.push("/sales")
        } else {
          toastId.current = toast.error(
            "There was an error deleting the sale " + saleDetailFinal.sale_id,
            { position: "top-center" }
          );
        }
      })
      .catch((err) => {
        toastId.current = toast.error("There was an error deleting the sale data", {
          position: "top-center"
        });
      });
  };

  return (
    <>
      <div className="sale-title">SALE DETAIL</div>
      <div className="sale-container">
        <div className="items">
          <div className="top-container">
            <div style={{ display: "flex" }}>
            {props.location.state.admin === true &&
              <>
              <Button className={clicked ? "cancel" : "edit"} onClick={handleClick}>
                {clicked ? "CANCEL" : "EDIT"}
              </Button>
                <DeleteButton
                  deleteClicked={deleteClicked}
                  handleCancel={handleCancel}
                  handleDelete={handleDelete}
                />
                </>
              }
            </div>
          </div>
          {clicked ? (saleDetailFinal.client_type == 'Mindset Monday Client' ? (
            <MindsetMondaySaleDetailForm
              saleDetail={saleDetailFinal}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
            ) : (
            <SaleDetailForm
              saleDetail={saleDetailFinal}
              clicked={clicked}
              setClicked={setClicked}
              refresh={refresh}
              handleDetailEditedChange={handleDetailEditedChange}
            />
            )
          ) : (
            <>            
              {saleDetailFinal.client_type == 'Mindset Monday Client' ? (
                  <div>
                    <div className="labels mt-3">Sale Notes</div>
                    <div className="mt-2">{saleDetailFinal.event_name}</div>
                    <div className="labels mt-3">Payment Date</div>
                    <div className="mt-2">{saleDetailFinal.payment_date}</div>
                    <div className="labels mt-3">Origin of Sale</div>
                    <div className="mt-2">{saleDetailFinal.origin_of_sale}</div>
                    <div className="labels mt-3">Payment Source</div>
                    <div className="mt-2">{saleDetailFinal.payment_source}</div>
                    <div className="labels mt-3">Program Name</div>
                    <div className="mt-2">{saleDetailFinal.program_name}</div>
                    <div className="labels mt-3">Sales Person</div>
                    <div className="mt-2">{saleDetailFinal.coach_name}</div>
                    <div className="labels mt-3">Commission</div>
                    <div className="mt-2">{saleDetailFinal.commission}</div>
                    <div className="labels mt-3">Commission Percentage</div>
                    <div className="mt-2">{saleDetailFinal.commission_percentage}</div>
                  </div>
                ) : (
                  <div>
                    <Link
                    to={{ pathname: `client_detail`, state: {id: saleDetailFinal.client_id }}}
                    style={{ textDecoration: "underline", color: "grey", cursor: "pointer" }}>
                    <div className="mt-3">{saleDetailFinal.client_name}</div>
                    </Link>
                    <div className="labels mt-3">Payment Date</div>
                    <div className="mt-2">{saleDetailFinal.payment_date}</div>
                    <div className="labels mt-3">Client Type</div>
                    <div className="mt-2">{saleDetailFinal.client_type}</div>
                    <div className="labels mt-3">Sport</div>
                    <div className="mt-2">{saleDetailFinal.sport}</div>
                    <div className="labels mt-3">Initial or Renewal</div>
                    <div className="mt-2">{saleDetailFinal.initial_sale_or_renewal}</div>
                    <div className="labels mt-3">Origin of Sale</div>
                    <div className="mt-2">{saleDetailFinal.origin_of_sale}</div>
                    <div className="labels mt-3">Payment Source</div>
                    <div className="mt-2">{saleDetailFinal.payment_source}</div>
                    <div className="labels mt-3">Sale Notes</div>
                    <div className="mt-2">{saleDetailFinal.event_name}</div>
                    <div className="labels mt-3">Program Name</div>
                    <div className="mt-2">{saleDetailFinal.program_name}</div>
                    <div className="labels mt-3">Sales Person</div>
                    <div className="mt-2">{saleDetailFinal.coach_name}</div>
                    <div className="labels mt-3">Take or Pass</div>
                    <div className="mt-2">{saleDetailFinal.take_or_pass}</div>
                    <div className="labels mt-3">Registration Fee</div>
                    <div className="mt-2">{"$" + saleDetailFinal.registration_fee}</div>
                    {saleDetailFinal.program_name && saleDetailFinal.program_name.includes("Custom") ? (
                        <div>
                          <div className="labels mt-3">Custom Price</div>
                          <div className="mt-2">{"$" + saleDetailFinal.custom_price}</div>
                        </div>
                        ) : (
                        <div>
                          <div className="labels mt-3">Commission</div>
                          <div className="mt-2">{"$" + saleDetailFinal.commission}</div>
                        </div>
                      )
                    }

                    <div className="labels mt-3">Registration Fee Commission</div>
                    <div className="mt-2">{"$" + saleDetailFinal.registration_fee_commission}</div>
                    <div className="labels mt-3">Commission Percentage</div>
                    <div className="mt-2">{saleDetailFinal.commission_percentage + "%"}</div>
                    {saleDetailFinal.program_name && saleDetailFinal.program_name.includes("Custom") ? (
                        <div>
                          <div className="labels mt-3">Custom Commission</div>
                          <div className="mt-2">{"$" + saleDetailFinal.custom_commission}</div>
                        </div>
                      ) : (
                        <div>
                          <div className="labels mt-3">Total Commission</div>
                          <div className="mt-2">{"$" + saleDetailFinal.total_commission}</div>
                        </div>
                      )
                    }
                    <div className="labels mt-3">Program Expiration</div>
                    <div className="mt-2">{saleDetailFinal.expiration}</div>
                    <div className="labels mt-3">Active Commission Start</div>
                    <div className="mt-2">{saleDetailFinal.active_commission_start}</div>
                    <div className="labels mt-3">Active Commission End</div>
                    <div className="mt-2">{saleDetailFinal.active_commission_end}</div>
                  </div>
                )
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SaleDetail;
