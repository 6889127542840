export const adjustmentTypeOptions = [
  { value: "Bonus", label: "Bonus" },
  { value: "Referral", label: "Referral" },
  { value: "Non-app Sale", label: "Non-app Sale" },
  { value: "Other", label: "Other" },
  { value: "Trial Call/Non-Client Hub Session Completed", label: "Trial Call/Non-Client Hub Session Completed" },
  { value: "Overtime Sales Hourly Pay", label: "Overtime Sales Hourly Pay" },
  { value: "30 Transformation Day Client Bonus", label: "30 Transformation Day Client Bonus" },
  { value: "Sales Bonus", label: "Sales Bonus" }
];

export const clientStatuses = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" }
];

export const clientTypeList = [
  { value: "", label: "" },
  { value: "Athlete", label: "Athlete" },
  { value: "Team", label: "Team" },
  { value: "Corporate", label: "Corporate" },
  { value: "Mindset Monday", label: "Mindset Monday" }
];

export const commissionOptions = [
  { value: 0, label: "0%" },
  { value: 0.05, label: "5%" },
  { value: 0.1, label: "10%" },
  { value: 0.12, label: "12%" },
  { value: 0.14, label: "14%" },
  { value: 0.1456446774, label: "14.5%" },
  { value: 0.15, label: "15%" },
  { value: 0.242491120383461, label: "24.2%" },
  { value: 0.2, label: "20%" },
  { value: 0.3, label: "30%" },
  { value: 0.4, label: "40%" }
];

export const condensedProgramList = [
  {value: "Gold", label: "Gold"},
  {value: "", label: ""},
];

export const desiredCoachExperienceLevelOptions = [
  { value: "20-45", label: "20-45" },
  { value: "45+", label: "45+" },
  { value: "No preference", label: "No preference" }
];

export const desiredNumberOfWeeklySessionsOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" }
];

export const energyLevelPreferenceOptions = [
  { value: "Relaxed", label: "Relaxed" },
  { value: "High energy", label: "High energy" },
  { value: "No preference", label: "No preference" }
];

export const eventTypeList = [
  { value: "Club Run", label: "Club Run" },
  { value: "Tournament", label: "Tournament" },
  { value: "Convention", label: "Convention" },
  { value: "Other", label: "Other" }
];

export const experienceList = [
  { value: "Less than a year", label: "Less than a year" },
  { value: "1-5 year(s)", label: "1-5 year(s)" },
  { value: "5-10 years(s)", label: "5-10 year(s)" },
  { value: "10+ years", label: "10+ years" }
];

export const faithBasedOptions = [
  { value: 1, label: "Yes" },
  { value: 2, label: "Yes but not a high priority" },
  { value: 0, label: "No" }
];

export const genderOptionsList = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
];

export const highestLevelCompetedAtOptions = [
  { value: "High School", label: "High School" },
  { value: "College", label: "College" },
  { value: "Post-College", label: "Post-College" },
  { value: "Semi-Pro", label: "Semi-Pro" },
  { value: "Professional", label: "Professional" }
];

export const insightsGroupbyOptions = [
  { value: "Coach", label: "Coach" },
  { value: "Salesperson", label: "Salesperson" },
  { value: "State", label: "State" },
  { value: "Program", label: "Program" }
];

export const insightTypeOptions = [{ value: "Average Months", label: "Average Months" }];

export const intitialOrRenewalOptions = [
  { value: "", label: "" },
  { value: "Initial", label: "Initial" },
  { value: "Renewal", label: "Renewal" }
];

export const lengthOnProgramOptions = [
  { value: "All", label: "All" },
  { value: "Less than 13 months", label: "Less than 13 months" },
  { value: "Greater than or equal to 13 months", label: "Greater than or equal to 13 months" },
  { value: "Greater than or equal to 26 months", label: "Greater than or equal to 26 months" },
  { value: "Greater than or equal to 39 months", label: "Greater than or equal to 39 months" },
  { value: "Greater than or equal to 52 months", label: "Greater than or equal to 52 months" }
];

export const lessonsImportanceOptions = [
  { value: "Very important", label: "Very important" },
  { value: "Somewhat important", label: "Somewhat important" },
  { value: "Not very important", label: "Not important" }
];

export const metricOptions = [
  { value: "Average Time on Program", label: "Average Time on Program" },
  { value: "Number of Clients", label: "Number of Clients" }
];

export const monthlyOrPIFOptions = [
  { value: "Monthly", label: "Monthly"},
  { value: "PIF", label: "PIF"}
];

export const originsOfSale = [
  { value: "", label: "" },
  { value: "800#", label: "800#" },
  { value: "Brickwork", label: "Brickwork" },
  { value: "Club Running", label: "Club Running" },
  { value: "Cold Call", label: "Cold Call" },
  { value: "Convention", label: "Convention" },
  { value: "Email", label: "Email" },
  { value: "Other", label: "Other" },
  { value: "Personal Connection", label: "Personal Connection" },
  { value: "Referral", label: "Referral" },
  { value: "Renewal", label: "Renewal" },
  { value: "Returning", label: "Returning" },
  { value: "Skip Paid", label: "Skip Paid" },
  { value: "Social Media", label: "Social Media" },
  { value: "Tournament", label: "Tournament" },
  { value: "Wrestling Mindset Coach", label: "Wrestling Mindset Coach" },
  { value: "wrestlingmindset.com", label: "wrestlingmindset.com" },
  { value: "Wufoo", label: "Wufoo" },
  { value: "Marketing", label: "Marketing" }
];

export const paymentSourceList = [
  { value: "Check", label: "Check" },
  { value: "Direct Deposit", label: "Direct Deposit" },
  { value: "PayPal", label: "PayPal" },
  { value: "Stripe", label: "Stripe" },
  { value: "Invoice", label: "Invoice" }
];

export const personalityTypeList = [
  { value: "Introverted", label: "Introverted" },
  { value: "Extroverted", label: "Extroverted" }
];

export const preferredClientTypeOptions = [
  { value: "Athletes", label: "Athletes" },
  { value: "Teams", label: "Teams" },
  { value: "Both", label: "Both" }
];

export const reimbursementTypeList = [
  { value: "Lodging", label: "Lodging" },
  { value: "Travel Mileage", label: "Travel Mileage" },
  { value: "Other", label: "Other" },
  { value: "Other Travel", label: "Other Travel (Flight/Rideshare/etc.)" },
  { value: "Shipping of company equipment", label: "Shipping of company equipment" }
];

export const salesPersonStatusOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const salesSourceList = [
  { value: "Team/Club Presentation", label: "Team/Club Presentation" },
  { value: "Convention", label: "Convention" },
  { value: "Other", label: "Other" },
  { value: "Referral", label: "Referral" },
  { value: "Renewal", label: "Renewal" },
  { value: "Social Media", label: "Social Media" },
  { value: "Tournament", label: "Tournament" },
  { value: "Mindset Coach", label: "Mindset Coach" },
  { value: "Mindset website", label: "Mindset website" }
];

export const sessionTypeList = [
  { value: "", label: "" },
  { value: "Athlete 1:1", label: "Athlete 1:1" },
  { value: "Team Video Conference", label: "Team Video Conference" },
  { value: "Team In Person", label: "Team In Person" },
  { value: "Corporate 1:1", label: "Corporate 1:1" },
  { value: "Coaches Consultation", label: "Coaches Consultation" }
];

export const signupTypeList = [
  { value: "", label: "" },
  { value: "Athlete", label: "Athlete" },
  { value: "Team", label: "Team" },
  { value: "Corporate", label: "Corporate" },
  { value: "Mindset Coach", label: "Mindset Coach" }
];

export const sportOptions = [
  { value: "Academic Mindset", label: "Academic Mindset" },
  { value: "Baseball", label: "Baseball" },
  { value: "Baseball/Softball", label: "Baseball/Softball" },
  { value: "Basketball", label: "Basketball" },
  { value: "Bowling", label: "Bowling" },
  { value: "Corporate Sales", label: "Corporate Sales" },
  { value: "Field Hockey", label: "Field Hockey" },
  { value: "Football", label: "Football" },
  { value: "Golf", label: "Golf" },
  { value: "Gymnastics", label: "Gymnastics" },
  { value: "Hockey", label: "Hockey" },
  { value: "Lacrosse", label: "Lacrosse" },
  { value: "Martial Arts", label: "Martial Arts" },
  { value: "Other", label: "Other" },
  { value: "Soccer", label: "Soccer" },
  { value: "Swimming", label: "Swimming" },
  { value: "Tennis", label: "Tennis" },
  { value: "Track", label: "Track" },
  { value: "Volleyball", label: "Volleyball" },
  { value: "Water Skiing", label: "Water Skiing" },
  { value: "Wrestling", label: "Wrestling" }
];

export const stateList = [
  { value: "International", label: "International" },
  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },
  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "MT", label: "MT" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" }
];

export const takeOrPassOptions = [
  { value: "", label: "" },
  { value: "Take", label: "Take" },
  { value: "Pass", label: "Pass" }
];

export const timeofDayOptions = [
  { value: "Morning", label: "Morning" },
  { value: "Afternoon", label: "Afternoon" },
  { value: "Evening", label: "Evening" }
];

export const trainingLevelList = [
  { value: "Elementary School", label: "Elementary School" },
  { value: "Middle School", label: "Middle School" },
  { value: "High School", label: "High School" },
  { value: "College", label: "College" },
  { value: "Post-College", label: "Post-College" },
  { value: "Semi-Pro", label: "Semi-Pro" },
  { value: "Professional", label: "Professional" }
];

export const tzList = [
  { value: "EST", label: "EST" },
  { value: "CST", label: "CST" },
  { value: "MST", label: "MST" },
  { value: "PST", label: "PST" },
  { value: "HST", label: "HST" },
  { value: "AKST", label: "AKST" },
  { value: "Other", label: "Other" }
];

export const rpInvolvementOptions = [
  { value: "Very involved", label: "Very involved" },
  { value: "Somewhat involved", label: "Somewhat involved" },
  {
    value: "Not very involved - I want my athlete to take ownership",
    label: "Not very involved - I want my athlete to take ownership"
  }
];

export const weekdayOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" }
];

export const weeklySessionOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "More than 5", label: "More than 5" }
];

export const workshopNumberList = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" }
];
