import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { adminAction, sessionAction } from "../../Redux/actions";
import TableContainer from "../../Components/ListViews/TableContainer";

const AdminWorksheetList = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.persona);
  const worksheetList_db = useSelector((state) => state.sessionReducer.sessionWorksheets);
  const worksheetListLoaded = useSelector((state) => state.sessionReducer.sessionWorksheetsLoaded);

  useEffect(() => {
    dispatch(sessionAction.loadSessionWorksheetData({ filters: {} }));
  }, []);

  return (
    <TableContainer
      title="WORKSHEETS"
      values={worksheetList_db}
      buttonTitle="ADD A WORKSHEET"
      itemLeftLabel={"worksheet"}
      itemId={"worksheet_id"}
      pathname="/worksheet_detail"
      linkName="worksheet"
      filtersClicked={false}
      loaded={worksheetListLoaded}>
    </TableContainer>
  );
};

export default AdminWorksheetList;
